import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
import 'react-accessible-accordion/dist/fancy-example.css';

function Acordion(props){
  const id = props.accordionId;
  const idwithhash = id ? `#${id}` : "nope";
	return(
    <div className="accordion" id="accordionFAQ">
      <div className="card e-accordion">
        <div className="card-header e-accordion-head" id="headingOne">
          <h2 className="mb-0">
            <button className="btn btn-link btn-block text-left" id="btn-link" type="button" data-toggle="collapse" data-target={idwithhash} aria-expanded="false" aria-controls={props.accordionId}>
             {props.buttonName}
            </button>
          </h2>
        </div>
        <div id={props.accordionId} className="collapse" aria-labelledby="headingOne" data-parent="#accordionFAQ">
          <div className="card-body ">
            <p className="e-accordion-p ">
              {props.content}
            </p>
          </div>
        </div>
      </div>
    </div>
	)
}
export default Acordion;