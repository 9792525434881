import React from 'react';
import $ from 'jquery'; 

// import components
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import Form from '../../components/form/form';
// import assets
import closebtn from '../../assets/images/header/close.png';

export default class SignUp extends React.Component{
	constructor(props){
		super(props);
		this.state={
			reset: false
		};
	}
	 hideLogin() {
		$('.e-signup').removeClass('e-login-fly'); 
		$('#e-log-overlay').remove();

		this.setState({reset: true});
		setTimeout( function() { this.setState({ reset: false}); }.bind(this), 1000);
	}
	render(){
		return(
			<div className="container text-left p-4 e-signup" id="signup">
				<div className="col-lg-2">
					<button className="e-login-closebtn"><img src={closebtn} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/></button>
				</div>
				<div className="col-lg-10 e-signup-wrapper">
					{
						!this.state.reset ?
							<Form className="e-signup-form mt-5" type="3"/> 
						:
							null
					}
				</div>
			</div>
		)
	}
}