import React from 'react';

/*Import Services*/
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';

// import components
import Button from '../../../components/buttons/primaryBlueButton';
import ApiLoader from '../../../components/loader/apiLoader';
import NothingFound from '../../../components/globel/nothingFound';
import Pagination from '../../../components/pagination/Pagination';

// import assets
import tickertape from '../../../assets/images/home/tickertape.png';
import sensibull from '../../../assets/images/home/sensibull.png';
import fourstar from '../../../assets/images/rating/four.svg';
export default class PurchaseHist extends React.Component{

	constructor(props){
		super(props);
			this.state={
				pageNum: 1,
				data: null,
				dataLoading: true
		}
	}

	componentDidMount(){
		this.getPurchaseHist();
	}

	getPurchaseHist=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.v3+"/product/purchased-products";
    var data = JSON.stringify({
      "page_num": this.state.pageNum,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        window.scrollTo({top: 0, behavior: 'smooth'});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
 
 	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		this.getPurchaseHist();
	}
	redirect(temp){
		window.location = temp+'&tab=2';
	}

	render(){
	if(this.state.dataLoading){
		return(
			<div className="col-lg-12 text-center mt-5">
				<ApiLoader/>
			</div>
		);
	}
	else if(!this.state.data.purchased_data.length){
		return(
			<div className="col-lg-12 text-center mt-5">
				<NothingFound text="No product purchased yet!"/>
			</div>
		);
	}
	return(
			<React.Fragment>
				{
				this.state.data.purchased_data.map((item, index) => {
					let path = 'marketplaceDetails?id='+item.product_id+'&product='+(item.product_name).replace(/\s+/g, '-');
		      return(
		      		<div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
								<div className="card border-0 e-purchase-card ">
									<div className="row">
										<div className="col-lg-12 mt-2">
											<img src={item.logo} alt="logo" className="e-purchase-card-img"/>
										</div>
									</div>
									<div className="row mt-4 no-gutters">
										<div className="col-lg-5 col-md-5 col-sm-5 col-12">
											<h5>{item.product_name}</h5>
											<p>Purchased on: <span className="e-purchase-card-data">{item.purchased_on}</span></p>
										</div> 
										{
											!item.is_reviewed	?	
											<div className="col-lg-3 col-md-3 col-sm-3 col-6 mt-4">
										{
											item.free ?
											<p className="text-lg-right"> <span className="e-purchase-card-data">Free</span></p>
											:
											item.is_trail ?
											<p className="text-lg-right"> <span className="e-purchase-card-data">Trial</span></p>
											:
											<p>Expiry: <span className="e-purchase-card-data">{ item.expired_on}</span></p>
										}
										</div> 
										:
											<div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-4">
										{
											item.free ?
											<p className="text-lg-right"> <span className="e-purchase-card-data">Free</span></p>
											:
											item.is_trail ?
											<p className="text-lg-right"> <span className="e-purchase-card-data">Trial</span></p>
											:
											<p className="text-lg-right">Expiry: <span className="e-purchase-card-data">{ item.expired_on}</span></p>
										}
										</div> 
										}
										
										{
											!item.is_reviewed	?									
										<div className="col-lg-4 col-md-4 col-sm-4 col-6 text-right">
											<Button name="Rate & Review" handleClick={()=>{this.redirect(path)}}/>
										</div> 
										:
										null
										}
									</div>
								</div>
							</div>
						);
					})
				}

				<div className="mt-3 col-12">

					{
	        	this.state.data && this.state.data.no_of_pages > 1 ?
	        		<Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
	        	:
	        		null
	        }

        </div>

		</React.Fragment>
		
	)
} 
}