import React from 'react';
// import components
import Form from '../../components/form/form';
import Playstore from '../../components/banners/playstoreBanner';
// import assets
import contactimg from '../../assets/images/contact/contactimg.svg';

function contact (props){
	return(
		<React.Fragment>
		<div className="container">
		<section className="e-contact-form-sec pt-5">
			<div className="row">
				<div className="col-lg-6">
					<Form className="e-contact-form" type="2"/>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 e-contact-card">
					<div className="media e-contact-desk p-4">
  					<img className="ml-3 " src={contactimg} className="e-contactus-img" alt="contact img"/>
  					<div className="media-body ml-3 p-3">
  						<hr className="e-form-rule"/>
   						<h5 className="mt-3 e-contactdesk-head">Any Product Related Queries?</h5>
    					<p className="e-contactdesk-p-blue mt-3">Call: <a href="tel:080-45412050">080-45412050</a>, <a href="tel:080-45366890">080-45366890</a><br/>E-mail: <a href="mailto:tradestore@aliceblueindia.com">tradestore@aliceblueindia.com</a></p>
    					<span className="e-contactdesk-p">9 am-6pm, Monday to Friday</span>
 						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="e-contact-playbanner-sec ">
			<div className="col-lg-12">
				<Playstore/>
			</div>
		</section>
	</div>
	</React.Fragment>
	)
	
	
}

export default contact;