import React from 'react';
import $ from 'jquery'; 

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';
import GetURLVar from '../../services/getParams/get-url';

// import components
import Acordion from '../../components/globel/Acordion';
import ChatWidget from '../../components/widget/chatlist';
import ApiLoader from '../../components/loader/apiLoader';
import NothingFound from '../../components/globel/nothingFound';

// import asset
import acopen from '../../assets/images/marketplaceDetails/acopenhelp.svg';
import faqbg1 from '../../assets/images/marketplaceDetails/faqbg1.svg';
import faqbg2 from '../../assets/images/marketplaceDetails/faqbg2.svg';
import faqbg3 from '../../assets/images/marketplaceDetails/faqbg3.svg';
import contactimg from '../../assets/images/contact/contactimg.svg';
import cardarrow from '../../assets/images/home/cardarrow.svg';

export default class FaqPage extends React.Component{

	constructor(props){
		super(props);
		this.state={
			data: null,
			id: null,
			response: null,
			openProdChatWidget: false,
			openGeneralChatWidget: false
		}
	}
	

	componentWillMount() {
    GetURLVar().then(function(query){ this.getData(query.id) }.bind(this));
  }
	getData(tempId){
		this.setState({id: tempId});
		this.setState({data: null, dataLoading: true});
		var url = devconfig.v3+"/product/faqs";
    var data = JSON.stringify({
      "id": parseInt(this.state.id),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false, response: response.message});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}


	productSupportClick(){
	  this.setState({openProdChatWidget: !this.state.openProdChatWidget});
	}

	generalSupportClick(){
	  this.setState({openGeneralChatWidget: !this.state.openGeneralChatWidget});
	}

	showLogin() {
	  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
	  $("body").append(overlaylogin);
	  $('.e-login').addClass('e-login-fly'); 
	}

	render(){
		var data = 1
		return(
		<div className="container">
			<section className="e-fqa-accordion-sec">
				<React.Fragment>
					{
						this.state.dataLoading ?
						<ApiLoader/>
						:
						null

					}
				</React.Fragment>
				<div className="row mt-5">
					{

				this.state.data ?
				this.state.data.map((item,key) => {
					data++
					const id = "id"+data;
					return(
					<div className="col-lg-6 col-md-12 col-sm-12 e-col" key={item.key}>
						<div className="e-accordion-col">
							<Acordion
								buttonName={item.question}
								accordionId={id}
								content={item.answer}
							/>
						</div>
					</div>
					);
					})
				: 
					null	
				}
				</div>
			</section>

				<section className="e-report-query-sec">
					<div className="row">
							<div className="col-lg-6 offset-lg-3 text-center">
								{
									this.state.data && this.state.data.length ?
										<h1 className="e-report-query-head">Haven't found answers to your queries? Get help.</h1>
									:
										<h1 className="e-report-query-head">We are always happy to help you? Get help.</h1>
								}
							</div>
							<div className="col-lg-4 offset-lg-2 mt-4">
								<div className="card border-0 p-4 e-ac-open-card text-center">
									<img src={acopen} alt="card image"/>
									<p>Queries on Account Opening?</p>
									{
										localStorage.getItem("tradestore_token") ?
											<a className="e-card-explore e-link-txt" onClick={this.generalSupportClick.bind(this)}>Chat with Account Activation Team <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
										:
											<a className="e-card-explore e-link-txt" onClick={this.showLogin.bind(this)}>Chat with Account Activation Team <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
									}
									
								</div>
							</div>
							<div className="col-lg-4 mt-4">
								<div className="card border-0 p-4 e-ac-open-card text-center">
									<img src={contactimg} alt="card image"/>
									<p>Product related Issues?</p>
									{
										localStorage.getItem("tradestore_token") ?
											<a className="e-card-explore e-link-txt" onClick={this.productSupportClick.bind(this)}>Chat with Product Support <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
										:
											<a className="e-card-explore e-link-txt" onClick={this.showLogin.bind(this)}>Chat with Product Support <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
									}
									
								</div>
							</div>
							<img src={faqbg1} alt="bg image" className="e-faqbg-blue"/>
							<img src={faqbg2} alt="bg image" className="e-faqbg-orng"/>
							<img src={faqbg3} alt="bg image" className="e-faqbg-grn"/>
					</div>

					{
		        this.state.openProdChatWidget && this.props.details ?
		          <ChatWidget prodSupport={true} prodData={this.props.details} close={this.productSupportClick.bind(this)}/>
		        :
		          null
		      }

		      {
		        this.state.openGeneralChatWidget && this.props.details ?
		          <ChatWidget close={this.generalSupportClick.bind(this)}/>
		        :
		          null
		      }


				</section>
		</div>
	
	)
	}
	
}
