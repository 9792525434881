import React from 'react';
import Slider from "react-slick";

// import assets
import SliderImg from '../../assets/images/slider/sliderimg.png';
import SliderImg1 from '../../assets/images/slider/aliceblue-slider.png';
import SliderImg2 from '../../assets/images/slider/banner.png';

function slider() {
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {/* {<div>
        <img className=" e-img" src={SliderImg} alt="slider image"/>
      </div>} */}
      {/*<div>
        <img className=" e-img"  src={SliderImg1} alt="slider image"/>
      </div>*/}

      {/* <img className=" e-img" src={SliderImg2} alt="slider image" /> */}

      <div className='row'>
        <div className='col-lg-12'>
          <div className='e-text-wrap'>
            <p className='e-p-text mt-4' >Marketplace for  tools & apps to empower your every trade!</p>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default slider;