import React from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';

// import components
import PrimaryBlueButton from '../buttons/primaryBlueButton';
import PrimaryOutlineButton from '../buttons/primaryOutlineButton';

import Star from '../../assets/images/rating/four.svg';
import AvgReview from '../starRating/avgReview';

function ProductCard(props) {
  let history = useHistory();
  function routeChange() {
    let path = `marketplaceDetails?id=` + props.data.id + `&product=` + (props.data.product_name).replace(/\s+/g, '-');
    history.push(path);

  }

  function windowChange() {
    let path = `marketplaceDetails?id=` + props.data.id + `&product=` + (props.data.product_name).replace(/\s+/g, '-');
    window.location.href = path;

  }

  function windowChangeBuyOpen() {
    let path = `marketplaceDetails?id=` + props.data.id + '&buy=true' + `&product=` + (props.data.product_name).replace(/\s+/g, '-');
    window.location.href = path;

  }




  function showLogin() {
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-login').addClass('e-login-fly');
  }


  function showLeads() {
    var tempPath = `marketplaceDetails?id=` + props.data.id + `&product=` + (props.data.product_name).replace(/\s+/g, '-');
    sessionStorage.setItem("ts_url", tempPath);
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-lead').addClass('e-login-fly');
  }

  function removeToggle() {
    props.favRemove(props.data.id);
  }

  var tempBgColor = "linear-gradient( to top, " + props.data.gradient_1 + " , " + props.data.gradient_2 + ")";

  return (
    <div className={"card e-marketDetails-card border-0 " + props.className}>
      <div className="e-card-head e-sensi-hed p-3" style={{ backgroundImage: tempBgColor }}>
        <div className="d-flex justify-content-between">
          <img className="card-logo" src={props.data.logo} alt="Card image cap" />
          <img className="card-illustration" src={props.data.illustration} alt="Card image cap" />
        </div>

        <div className="e-card-rating">
          {/*<span className="e-fill-star"></span>
          <span className="e-half-fill-star"></span>
          <span className="e-no-fill-star"></span>*/}
          {
            props.data.review_count ?
              <React.Fragment>
                <AvgReview rating={props.data.rating} type={1} /> <span>({props.data.review_count} Reviews)</span>
              </React.Fragment>
              :
              null

          }

        </div>
        <span className="e-card-overlay"></span>
      </div>
      <div className="card-body">
        <h5 className="e-mrktplc-card-title">{props.data.title}</h5>
        <p className="e-mrktplc-card-text">{props.data.description}</p>
        {
          props.data.filters.map((item, index) => {
            return (
              <span className="e-card-type-tag" key={index}>{item.name}</span>
            );
          })
        }
        <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; {props.data.price}</span> <span className="e-mrktplc-product-plan">/ per {props.data.price_validity_text}</span></p>
        {
          props.data.offer ?
            <p>
              <span className="e-mrktplc-card-title">Offer Price: </span>
              <span className="e-mrktplc-card-title e-prdt-free-grn">
                {
                  props.data.offer_text && props.data.offer_text != "Free" && props.data.offer_text != "Free Trail" ?
                    <React.Fragment>&#8377; </React.Fragment>
                    :
                    null
                }
                {props.data.offer_text ? props.data.offer_text : props.data.offer_price}
              </span>
              <span className="e-mrktplc-product-plan">
                {props.data.offer_validity_text ? " / per " + props.data.offer_validity_text : null}
              </span>
            </p>
            :
            null
        }

        <div className="row e-card-btn-wrap">
          <div className="col-lg-6 pr-lg-2">
            {
              localStorage.getItem("access_token") ?
                <React.Fragment>
                  {
                    props.type == 2 ?
                      <PrimaryBlueButton name="View More Details" handleClick={windowChange} />
                      :
                      <PrimaryBlueButton name="View More Details" handleClick={routeChange} />
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  {/* {
                    localStorage.getItem("tradestore_lead_success") ?
                      <PrimaryBlueButton name="View More Details" handleClick={windowChange} />
                      :
                      <PrimaryBlueButton name="View More Details" handleClick={showLeads} />
                  } */}
                  <PrimaryBlueButton name="View More Details" handleClick={windowChange} />
                </React.Fragment>

            }

          </div>
          {
            props.fav ?
              <div className="col-lg-6 pl-lg-1">
                <PrimaryOutlineButton handleClick={removeToggle} name="Remove Favorite" />
              </div>
              :
              <div className="col-lg-6 pl-lg-1">
                {


                  localStorage.getItem("tradestore_token") ?
                    <React.Fragment>
                      {
                        props.data.already_purchased ?
                          <span className="e-already-purchased">&#10003; Purchased</span>
                          :
                          <React.Fragment>
                            {/* {
                            (props.data.free || props.data.is_trail_available) && !props.data.already_availed_trail && !localStorage.getItem("partner_token") ?
                              <PrimaryOutlineButton handleClick={windowChange} name={props.data.free ? "Get It Free" : "Trial Now"}/>
                              :
                             !localStorage.getItem("partner_token") ?
                              <PrimaryOutlineButton handleClick={windowChangeBuyOpen} name="Buy Now"/>
                              :
                              null
                          } */}
                            {/* {
                            (props.data.free || props.data.is_trail_available) && !props.data.already_availed_trail && !localStorage.getItem("partner_token") ?
                              <PrimaryOutlineButton className={props.data.free ? "d-none" : "d-block"} handleClick={windowChange} name={props.data.free ? "" : "Trial Now"}/>
                              :
                             !localStorage.getItem("partner_token") ? */}
                            <PrimaryOutlineButton handleClick={windowChangeBuyOpen} name="Buy Now" />
                            {/* :
                              null
                          } */}

                          </React.Fragment>
                      }

                    </React.Fragment>

                    :
                    <React.Fragment>
                      {/* {
                        props.data.free || props.data.is_trail_available && !localStorage.getItem("partner_token") ?
                          <PrimaryOutlineButton className={props.data.free ? "d-none" : "d-block"} handleClick={showLogin} name={props.data.free ? "" : "Trial Now"} />
                          :
                          !localStorage.getItem("partner_token") ? */}
                      <PrimaryOutlineButton name="Buy Now" handleClick={showLogin} />
                      {/* :
                            null
                      } */}
                    </React.Fragment>


                }
              </div>
          }

        </div>
      </div>
    </div>

  )

}
export default ProductCard;