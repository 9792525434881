import React from 'react';
import $ from 'jquery';
import ReactPlayer from 'react-player/lazy';
// import components
import VideoModal from '../../components/modal/VideoModal';
// import assets
import playbtn from '../../assets/images/GetStartBanner/grnplybtn.svg';
import closebtn from '../../assets/images/header/close.png';
export default class JustStartBanner extends React.Component{

	constructor(props){
		super(props);
		this.state={
			isModalOpen: false, 

		}
	}




	render(){
		return(
		
			<div className="row ">
				<div className="col-lg-12 col-md-12 col-sm-12">
					<div className="e-juststart-banner pt-3">
						<div className="text-center mt-5">
						 	<h2>Just Getting Started?</h2>
						 	<p className="e-start-banner-p mt-3">Watch this 4-minute video to learn how Aliceblue's <br/> ecosystem can help you trade better.</p>
						</div>
					 	<div className="text-center">
					 		<button className="e-getstart-button mt-3" data-toggle="modal" data-target="#homevideo" onClick={() => this.setState({isModalOpen: true})}>Watch video  <img className="ml-2" src={playbtn} alt="buttn"/></button>
					 	</div>
					 	<VideoModal modalId="homevideo">
	          	<button className="e-videomodal-close"data-dismiss="modal" aria-hidden="true" onClick={()=>{this.setState({isModalOpen: false})}}><img className="e-toggle" src={closebtn} alt="button"/></button>
      				<ReactPlayer id="player" url="https://d1ippcqariu474.cloudfront.net/static/tradestore-video.mp4" playing={this.state.isModalOpen } controls={true} className="e-react-player"  width="100%" />
	      		</VideoModal>			  
	       	</div>
				</div>
			</div>
		)
	}
}
