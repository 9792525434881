import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';
import $ from 'jquery';
import Links from '../../components/navigation/Links';
import Login from '../../components/loginSignup/Login';
import SignUp from '../../components/loginSignup/SignUp';
import Lead from '../../components/loginSignup/lead';
import HeaderImg from '../../assets/images/header/headerimage.svg';
import menubar from '../../assets/images/header/menubar.png';
import close from '../../assets/images/header/close.png';


import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryGreenButton from '../../components/buttons/primaryGreenButton';

function addOverlay() {
  var overlayDiv = '<div class="e-overlay" id="overlay" onClick=removeOverlay()></div>';
  $("body").append(overlayDiv);
  $('.e-nav-item').addClass('e-navbar-collapse');
  $('.navbar').addClass('e-fixed');
  $('#navbar-open').addClass('hidden');
}
function removeOverlay() {
  $('#overlay').remove();
  $('.navbar').removeClass('e-fixed');
  $('#navbar-open').removeClass('hidden');
  $('#navbar-close').addClass('hidden');
}

$(document).on('click', '.e-overlay', function () {
  $('#overlay').remove();
  $('#navbar-open').removeClass('hidden');
  $('#navbar-close').addClass('hidden');
  closeMenu();
});
function closeMenu() {
  $("button").attr("aria-expanded", "false");
  $('.e-nav-item').removeClass('show');
  $('.navbar').removeClass('e-fixed');
  removeOverlay();
}

function showLogin() {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-login').addClass('e-login-fly');

}
function showSignUp() {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-signup').addClass('e-login-fly');
}

function header(props) {

  return (

    <div className="text-left pb-2 e-header">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container pr-lg-3">
          <div className="row w-100 no-gutters">
            <div className="col-lg-1 d-flex justify-content-between">
              <Links className="e-head-logo-link" name={<img src={HeaderImg} alt="header log" className=" mt-4 e-header-img-link" />} url="home" />
              <button id="ChangeToggle" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <div className="mt-4" id="navbar-open">
                  <span className="e-menu-bar" onClick={addOverlay}><img src={menubar} alt="menu icon" className="e-toggle" /></span>
                </div>
                <div id="navbar-close" className="e-close hidden">
                  <span className="e-close-bar" onClick={removeOverlay}><img src={close} alt="close icon" className="e-toggle" /></span>
                </div>
              </button>
            </div>
            <div className="col-lg-11 ">
              <div className=" collapse navbar-collapse e-nav-item" id="navbarSupportedContent" onClick={closeMenu}>
                <div className={ localStorage.getItem("tradestore_token") ? "container-md text-left": "container-md text-right justify-content-end"}>
                  <ul className={ localStorage.getItem("tradestore_token") ? "navbar-nav ml-lg-auto mr-lg-auto": "navbar-nav text-right"}>
                    {

                      localStorage.getItem("tradestore_token") ?
                        <li className="nav-item mt-3">
                          <Links className="mr-lg-5 e-header-link" activeClassName="e-header-link-active" name="Marketplace" url="marketplace" />
                          <div className="e-border"></div>
                        </li>

                        :
                        null
                    }



                    {/* {<li className="nav-item mt-3">
								      	<Links className="mr-lg-5 e-header-link" activeClassName="e-header-link-active" name="Resources" url="resources"/>
								      </li>} */}
                    <li className="nav-item mt-3">
                      <Links className="mr-lg-5 e-header-link" name="Contact" activeClassName="e-header-link-active" url="contact" />
                    </li>
                    {/* <li className="nav-item mt-3">
								        <Links className="mr-lg-5 e-header-link" name="Blog" activeClassName="e-header-link-active" url="blogs?type=blog"/>
								      </li> */}
                    {/*<li className="nav-item active mt-4">
								        <Links className=" e-header-link" activeClassName="e-header-link-active" name="Partner with us" url="empty"/>
								      </li>*/}
                  </ul>
                  <button type="button" className="e-header-btn mr-2 mt-4" onClick={showLogin}>Login</button>
                  {/* <button type="button" className="e-header-blue-btn d-inline-block e-signup-btn mt-4" onClick={showSignUp}>Sign Up</button> */}
                </div>

                <div className="mt-4 row">


                </div>
              </div>
            </div>
          </div>
        </div>





      </nav>
      <Login />
      <SignUp />
      <Lead />
    </div>



  )
}

export default header;