import React from 'react';
// import { useScrollPercentage } from 'react-scroll-percentage';
import $ from 'jquery'; 
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import components
import Links from '../../components/navigation/Links';
import ApiLoader from '../../components/loader/apiLoader';

// import assets
import linkarrow from '../../assets/images/resource/breadcrum.svg';
import BlogCover from '../../assets/images/resource/blogcover.svg';
import Author from '../../assets/images/resource/author.svg';
import Arrow from '../../assets/images/resource/breadcrum.svg';
import facebook from '../../assets/images/footerIcons/facebook.svg';
import twitter from '../../assets/images/footerIcons/twitter.svg';
import linkedin from '../../assets/images/footerIcons/linkdin.svg';
import whatsapp from '../../assets/images/footerIcons/whatapp.svg';
import copy from '../../assets/images/footerIcons/icon.svg';

var scrollPercent = 0
export default class BlogDetails extends React.Component{
	constructor(props){
		super(props);
		this.state={
			data: null,
			url: "",
			linkcopy: false,
			scroll: 0,
			widgetFixed: false,
			sectionActive: ""
		}
	}

	componentDidMount(){
  	window.addEventListener('scroll', this.handleScroll.bind(this), true);
		window.scrollTo({top: 0, behavior: 'smooth'});

		var tempUrl = (window.location.href).replace(/\s+/g, '-');
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id, url: tempUrl});
		}.bind(this));
	}

	handleScroll(){

		


		var s = $(window).scrollTop(),
      d = ($(document).height() - 500),
      c = $(window).height();

  	scrollPercent = (s / (d - c)) * 100;
  	this.setState({ scroll: Math.floor(scrollPercent)});
  	console.log(s);
  	if (s > 680 && scrollPercent < 90 ){
      $('.e-blog-content').addClass("e-blog-content-fixed");
  		$('.e-blog-about').addClass("e-blog-content-right");

  		this.setState({ widgetFixed: true});
    }
    else{
      $('.e-blog-content').removeClass("e-blog-content-fixed");
  		$('.e-blog-about').removeClass("e-blog-content-right");

  		this.setState({ widgetFixed: false});
    }
	}

	getData(tempId){
		this.setState({dataLoading: true});
		var url = devconfig.v3+"/resources/blog-detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	// getScrollPercent() {
	//   var h = $(document);
	//       b = $(body)
	//       st = 'scrollTop';
	//       sh = 'scrollHeight';
	//   return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
	// }


	render(){
		// const [ref, percentage] = useScrollPercentage({
	 //    threshold: 0,
	 //  })


		if(this.state.dataLoading){
			return(
				<div className="e-min-height-loader pt-5">
					<ApiLoader/>
				</div>
			);
		}
		return(

			
			<React.Fragment>
			{
				!this.state.data ?
				<Helmet>
          <title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
          <meta name="description" content="Trade store is India's first web & app based trading products marketplace for traders, investors and strategists. Get access to multiple trading tools to help you trade more efficiently." />
        </Helmet>
        :
        <Helmet>
        	<title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
					<meta property="og:title" content={this.state.data.title}/>
					<meta property="og:keyword" content={this.state.data.meta_keyword}/>
					<meta name="og:description" content={this.state.data.meta_description}/>

				</Helmet>
			}
				
			{
				this.state.data ?
				<React.Fragment>
				
				<div className="container-fluid e-blog-details-header no-gutters">
					<div className="container">
						<a className="e-marketDetails-link"  href="home">Home</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<a className="e-marketDetails-link"  href="resources?type=videos">Resources</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<a className="e-marketDetails-link"  href="resources?type=blog">Blogs</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<span className=" e-resource-dtls-head">{this.state.data.title}</span>
					</div>
					<div className="container"> 
						<div className="row">
							<div className="col-lg-8 col-md-12 col-sm-12 col-12 offset-lg-2 no-gutters mt-5 ">
								<h1 className="e-blogcover-h1">{this.state.data.title}</h1>
								<div className="row ">
									<div className="col-lg-8 col-md-8 col-sm-8 col-12 text-left  ">
										{
											this.state.data.products && this.state.data.products.map((item, index) =>{
												return(
													<span className="e-blogdtls-span">{item.name}</span> 
												);
											})
										}
									</div>
									<div className="col-lg-4 col-md-4 col-sm-4 col-12 text-right ">
										<p className="e-blogdtls-time">{this.state.data.published_on}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-8  offset-lg-2 no-gutters ">
								<div className="position-relative">
									<img src={this.state.data.image} alt="cover image" className="e-blogcover-img"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container e-blog-body" id="e-scroll">
					<div className="row mt-5 pt-3">
						<div className="col-lg-2 e-blog-content" >
							<h6>Contents</h6>
							{
								this.state.data && this.state.data.sections ?
								this.state.data.sections.map((item, index) =>{
									return(
										<a className={this.state.sectionActive == "#e-page-section"+item.id ? "e-blogdtls-p e-active " : "e-blogdtls-p "} href={"#e-page-section"+item.id} onClick={(e) => {this.setState({sectionActive: "#e-page-section"+item.id}); }} >{item.content}</a>
									);
								})
								:
								null
							}
							
						
						</div>
						{
							this.state.widgetFixed ?
								<div className="col-lg-2"></div>
							:
								null
						}
						<div className="col-lg-8 e-blog-scroll pb-5">
							<p className="e-blogdtls-decs">{ReactHtmlParser(this.state.data.introduction)}
							</p>
							{
								this.state.data && this.state.data.sections ?
								this.state.data.sections.map((item, index) =>{
									return(
										<React.Fragment>
											<h5 className="e-blog-author pb-2" id={"e-page-section"+item.id}>{item.content}</h5>
											<p className="e-blogdtls-des" >{ReactHtmlParser(item.description)}</p>
										</React.Fragment>
									);
								})
								:
								null
							}
						</div>
						{
							this.state.widgetFixed ?
								<div className="col-lg-2"></div>
							:
								null
						}
						<div className="col-lg-2 e-blog-about ">
						<img src={this.state.data.author_image} alt="author img" className="e-blog-author-img"/>
						<h5 className="e-blog-author">{this.state.data.author_name}</h5>
						<p className="e-blogdtls">{this.state.data.author_bio}</p>
						

						<div className="">
							<h5 className="e-blog-author mt-5">Completed</h5>
							<progress className="e-blog-complete" id="rating"  value={this.state.scroll}  max="100"></progress>
							<p className="e-blogdtls-percentage">{this.state.scroll >= 100 ? "100" : this.state.scroll} %</p>
							<h5 className="e-blog-author mt-5">Share Via</h5>
							<p className="">
								<a className="mr-3 e-ftricon-link" href={"https://www.facebook.com/sharer/sharer.php?u="+this.state.url} target="blank"><img src={facebook} alt="icon" className="e-footer-icon"/></a>  
								<a className="mr-3 e-ftricon-link" href={"https://twitter.com/intent/tweet?url="+this.state.url+"&text="+this.state.data.title} target="blank"><img src={twitter} alt="icon" className="e-footer-icon"/></a>
								<a className="mr-3 e-ftricon-link" href={"http://www.linkedin.com/shareArticle?mini=true&url="+this.state.url+"&title="+this.state.data.title} target="blank"><img src={linkedin} alt="icon" className="e-footer-icon"/></a>
								{/*<a className="mr-3 e-ftricon-link" href="#" target="blank"><img src={whatsapp} alt="icon" className="e-footer-icon"/></a> */}
								<a className="mr-3 e-ftricon-link" onClick={() => {
										this.setState({ linkcopy: true});
										navigator.clipboard.writeText(this.state.url);
										setTimeout(
									    function() {
									      this.setState({ linkcopy: false });
									    }
									    .bind(this),
									    1500
										);
									}
								}><img src={copy} alt="icon" className="e-footer-icon"/></a> 

								{
									this.state.linkcopy ?
										<span className="e-link-copied">Link copied</span>
									:
										null
								}
							</p>
						</div>

					</div>
				</div>
			</div>
			</React.Fragment>
			:
			null
		}
			</React.Fragment>
		)
	}
}
