import React from 'react';
import SliderWithNav from '../../components/slider/SliderWithNav';
import Login from '../../components/loginSignup/Login';
import MarketPlaceDetails from '../../container/marketplaceDetails/MarketPlaceDetails';
function about (props){
	
		return(
			<MarketPlaceDetails/>
		)
	
}

export default about;