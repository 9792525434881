import React from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery'; 
import ReactHtmlParser from 'react-html-parser';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

// import assets
import blogimg from '../../assets/images/resource/blogimg.svg';
import blogimg1 from '../../assets/images/resource/blogimg1.svg';
import readcount from '../../assets/images/resource/blogread.svg';
import cardarrow from '../../assets/images/home/cardarrow.svg';

function Blog(props){

	let history = useHistory();
	function RouteChange() {
		let path = `blogDetails?id=`+ props.data.id +`&product=` +props.data.title.replace(/\s+/g, '-');
   	history.push(path);
	}

	function showLeads(){
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-lead').addClass('e-login-fly'); 
  }

		return(
			<div className="col-lg-6 mt-3">
				{ 
					props.data ?
				 <div class="card border-0 e-videolist-card e-video-card e-blog">

			      <div class="card-image e-blog-card-headersec">
		     		 	<img className="e-videolist-thumb"   id="video-cover" src={props.data.image} alt="Video title"/>
		     		 
		     		 			<span className="e-blog-views"><img className="e-eye" src={readcount} alt="eye img"/> {props.data.views}</span>
		     		 	
			     	</div>
			      <div class="card-content p-3">
			        <h5 class="e-video-card-head">{props.data.title}</h5> 
			        <p className="e-blog-p">{ReactHtmlParser(props.data.introduction)}</p>
			        {
								props.data.products && props.data.products.map((item, index) =>{
									return(
										<span className="e-vidoe-list-span">{item.name}</span> 
									);
								})
							}
			        
			        <div className="row pt-2 no-gutters">
			        	<div className="col-lg-5 mt-3">
				      		<span className="e-webinar-span">{props.data.published_on}</span> 
				        </div>
				        <div className="col-lg-7 mt-3 text-right">
				        	<a href="#" className="e-blog-read-link" onClick={RouteChange}>Read More <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
				        	
				        	{/*
				        		localStorage.getItem("tradestore_token") || localStorage.getItem("tradestore_lead_success") ?
				     	  			<a href="#" className="e-blog-read-link" onClick={RouteChange}>Read More <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
				     	  		:
				     	  			<a href="#" className="e-blog-read-link" onClick={showLeads}>Read More <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
				        	*/}
				      	</div>   
			        </div>                
			      </div>
			    </div>
			    :
			    null
			  }
			</div>
)
}


export default Blog;