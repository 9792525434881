import React from 'react';
import $ from 'jquery'; 

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';
import GetURLVar from '../../services/getParams/get-url'; 
// import components
import SelectBox from '../../components/formComponents/selectBox';
import Links from '../../components/navigation/Links';
import ReviewModal from '../../components/modal/ReviewModal';
import ReviewCard from '../../components/cards/ReviewCard';
import Pagination from '../../components/pagination/Pagination';
import PrimaryblueButton from '../../components/buttons/primaryBlueButton';

import NothingFound from '../../components/globel/nothingFound';
import ApiLoader from '../../components/loader/apiLoader';

// import assets
import star from '../../assets/images/rating/star.svg';
const options = [
			{ value: 1, label: 'Most helpful' },
      { value: 2, label: 'Newly Added' },
      { value: 3, label: 'Most positive' },
      { value: 4, label: 'Least positive' },

    ];

export default class Review extends React.Component{
	 constructor(props) {
    	super(props);
    	this.state = {
    		isOpen: false, 
				star: 0,
				end: 8,
				showPerPage: 8,
				sortIndex: 1,
				data: null,
				id: null,
				pageNum: 1,
				dataLoading: true
    	};
  	}

	componentDidMount(){
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id});
			if(query.tab ==2){
				
				 this.openModal(); 
			}
		}.bind(this));
	}

	getData(tempId){
		this.setState({dataLoading: true, data: null});
		var url = devconfig.v3+"/product/reviews";
    var data = JSON.stringify({
      "id": parseInt(tempId),
      "page_num": parseInt(this.state.pageNum),
      "sort_by" : parseInt(this.state.sortIndex),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}


	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		this.getData(this.state.id);
	}
	async handleSort(tempSort){
		await this.setState({ sortIndex: tempSort.value, pageNum: 1});
  	this.getData(this.state.id);
	}

	openModal(){
		$('#e-add-review').modal('toggle');
	}
  	
 // toggleModal = () => {
 // 	alert("hai")
 //    $('.e-review-modal').addClass('e-modal-show');
 //    var overlaylogin = '<div class="e-modal-overlay" id="e-modal-overlay"></div>';
 //    $("body").append(overlaylogin);
 //    document.getElementById('e-modal-overlay').addEventListener('click', this.removeOverlay);
	// }
	// removeOverlay = () =>{
 //    $('.e-review-modal').removeClass('e-modal-show');
 //    $('#e-modal-overlay').remove();
 //  }

  reviewSuccess(){
  	this.getData(this.state.id);
  }

	render(){

		return(
		<section className="e-marketDetailsReview-sec">
				{
					this.state.data && this.state.data.reviews.length ?
						<div className="container e-review-prdtdtls-review-sec px-5 py-4">
							<div className="row">
								<div className="col-lg-2 col-md-2 col-sm-4 col-12">
									<p className="e-trade-rating-p">Trade Store Rating</p>
									<h1>{this.state.data.total_rating}</h1>
									<span className="e-marketDetails-review" onClick={this.openReview}>{this.state.data?
										this.state.data.total_review_count :
										null} Reviews</span>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-8 col-12">
									{
										
										this.state.data && Object.keys(this.state.data.rating_data).length ?
										<React.Fragment>
										{
											Object.keys(this.state.data.rating_data).map(
		                    function(key, index) {
		                      return (
		                        <div>
															<p className="e-rview-pbar">
																<span>{key}</span> <img src={star} alt="rating star" className="mr-1"/>
															 	<progress className="e-ratingbar" id="rating" value={this.state.data.rating_data[key]} max="100"></progress> <span>{this.state.data.rating_data[key]}</span>
															</p>
														</div>
		                      );
		                    }.bind(this)
		                  )
										}
										</React.Fragment>
										:
										null
									}
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12 text-lg-right">
								{
									this.state.data.already_purchased ?
										<span className="e-write-review" data-toggle="modal" data-target="#e-add-review">+ Write a review</span>
									: null
								}
									
									<div className="row text-lg-right">
										<div className="col-lg-12 col-md-12 col-sm-12 mt-5">
										<span className="e-select-label mr-2">Showing:</span> 
										<SelectBox className="d-inline-block e-filter-select text-left" options={options} selectchange={(tempVal) => this.handleSort(tempVal)} defaultValue={{ label: "Most helpful", value: 1 }} />
									</div>
									</div>
								</div>
							</div>
						</div>
					:
						null
				}
				
				<div className="col-lg-12">
					<div className="row">
						{
							this.state.dataLoading?
							<div className="col-lg-12 text-center">
								<ApiLoader/>
							</div>
							:
							null
						}
						{
							this.state.data ?
			        this.state.data.reviews.map((item, index) => {
			          return(
			            <div className="col-lg-4 col-md-4 mb-3" key={index}>
										<ReviewCard data={item} reload={()=>{this.getData(this.state.id)}}/>
									</div>
			          );
			        })
			        :
			        null
			      }   
		      	{

		      	!this.state.dataLoading && this.state.data && !this.state.data.reviews.length  ?
		      		<div className="col-lg-12 text-center mt-5">
		      			
		      			<NothingFound text="No Reviews found! Please try later."/>
		      			{
									this.state.data.already_purchased && !this.state.data.already_rated ?
										<div className="mt-4 pt-3">
											<button type="button" className=" e-primary-blue-btn d-inline-block mr-3" data-toggle="modal" data-target="#e-add-review">+ Write a review</button>
										</div>
									: 
									this.state.data.already_purchased && this.state.data.already_rated ?
									<div className="mt-4 pt-3">
										<button type="button" className=" e-primary-blue-btn d-inline-block mr-3">Already Rated</button>
									</div>
									:
									null

								}
		      		</div>
		      		:
		      		null

		      	}
			      <div className="col-lg-12 text-center">
			      	{
              	this.state.data && this.state.data.no_of_pages > 1 ?
              		<Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
              	:
              		null
              }
			      </div>     
					</div>
				</div>
				<ReviewModal success={this.reviewSuccess.bind(this)}/>
		</section>
	)
	}
	
}
