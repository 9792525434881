import React from 'react';

// import asset from files
import nothing from '../../assets/images/others/nothing.png';

function NothingFound(props){
	return(
		<div className="pt-0 text-center mt-5 ">
      <img className="e-nothing-found" src={nothing} alt="No data found"/>
      <h6 className="e-nothing-found-txt">{props.text}</h6>
    </div>
	)
}
export default NothingFound;