

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: 245,
  },
});

function valuetext(value) {
	return `${value}`;
}
function numFormatter(value) {
	let amount =value * 10;
	let res = "₹"+ amount;
	return res;
}

export default function PriceRangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([10 , 100]);
   
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        valueLabelFormat={numFormatter}
        getAriaValueText={valuetext}
      />
    </div>
  );
}
