import React from 'react';

/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

// import components
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import ProductCard from '../../components/cards/productCard';
import ApiLoader from '../../components/loader/apiLoader';
import NothingFound from '../../components/globel/nothingFound';
import Pagination from '../../components/pagination/Pagination';

export default class Favorities extends React.Component{

	constructor(props){
		super(props);
		this.state={
			pageNum: 1,
			data: null,
			dataLoading: true,
		}
	}
	componentDidMount(){
		this.favList();
	}

	favList=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.v3+"/product/list-favourites";
    var data = JSON.stringify({
      "page_num": this.state.pageNum,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        window.scrollTo({top: 0, behavior: 'smooth'});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	removeFav(tempId){
		var url = devconfig.v3+"/product/manage-favourite";
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.favList();
			}
    }.bind(this))

	}


	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		this.favList();
	}

	render(){
		var tempBgColor = null;

	if(this.state.dataLoading){
		return(
			<div className="col-lg-12 text-center mt-5">
				<ApiLoader/>
			</div>
		);
	}
	else if(!this.state.data.favourite_data.length){
		return(
			<div className="col-lg-12 text-center mt-5">
				<NothingFound text="No favorites products found!"/>
			</div>
		);
	}
	else{
		return(
			<React.Fragment>
				{
					this.state.data ?
					  this.state.data.favourite_data.map((item, index) => {
							return(
								<div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
									<ProductCard data={item} fav={true} favRemove={this.removeFav.bind(this)}/>
								</div>
							);
						})
					:
						null
				}

				{
        	this.state.data && this.state.data.no_of_pages > 1 ?
        		<Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        	:
        		null
        }


			</React.Fragment>
			
		)}
	}
}
