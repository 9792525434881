import React from 'react';
import Slider from "react-slick";



// import components

import ProductCard from '../cards/productCard';

export default class CardSlider extends React.Component{

  constructor(props){
    super(props);
    this.state={
      id: null,
      product: null,
    }
  }


  render(){
    var settings = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
        responsive: [

        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },

  ]
    };

    return (
      <Slider {...settings}>
        {
          this.props.data ?
            this.props.data.map((item, index) => {
              return(
                <div className="col-lg-12 e-crd-sldr pt-3 px-0 mx-0 pb-5">
                  <ProductCard data={item} type={2} className="mr-3"/>
                </div>
              );
            })
          :
          null
        }
      </Slider>
    );
  }
 }
