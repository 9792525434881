import React from 'react';
import $ from 'jquery'; 

// import components
import Form from '../../components/form/form';
// import assets
import closebtn from '../../assets/images/header/close.png';

export default class BankDetailsForm extends React.Component{
	constructor(props){
		super(props);
		this.state={

		};
	}



	 hideLogin() {
	$('.e-bnk-dtls-popup').removeClass('e-login-fly'); 
	$('#e-log-overlay').remove();
}
	render(){
		return(
			<div className="container text-left p-4  e-bnk-dtls-popup" id="signup">
				<div className="col-lg-2">
					<button className="e-login-closebtn"><img src={closebtn} alt="close button" className="e-toggle" onClick={this.hideLogin}/></button>
				</div>
				<div className="col-lg-10 e-signup-wrapper">
					<Form className="e-bankdetails-form mt-5" type="4" data={this.props.data} refresh={()=>{this.props.reload()}}/>
				</div>
			</div>
		)
	}
}