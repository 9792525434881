import React from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
// import service
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
// import components
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryGreenButton from '../../components/buttons/primaryGreenButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import Signup from '../../components/loginSignup/SignUp';
import Links from '../../components/navigation/Links';
import UserProfile from '../../container/usrprofile/UserProfile';
import InputBox from '../../components/formComponents/input';
// import assets
import closebtn from '../../assets/images/header/close.png';
import cardarrow from '../../assets/images/home/cardarrow.svg';


export default class Login extends React.Component {
	// body...
	constructor(props) {
		super(props);
		this.state = {
			partner: false
		}
	}

	hideLogin = () => {
		$('.e-login').removeClass('e-login-fly');
		$('#e-log-overlay').remove();
		this.setState({ partner: false })
	}

	loginClick(e) {

		var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
		var encodedData = btoa(tempEncodeData);

		$.ajax({
			type: "GET",
			url: devconfig.partnerV1 + '/auth/login',
			dataType: 'json',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				"Authorization": "Basic " + encodedData,
			}
		}).then(function (response) {
			console.log(response)

			if (response.status == "ok") {
				localStorage.setItem("access_token", response.access_token);
				localStorage.setItem("partner_name", response.data.name);
				localStorage.setItem("partner_mail", response.data.email);
				localStorage.setItem("alice_blue_id", response.data.alice_blue_id);
				localStorage.setItem("partner_id", response.data.id);

				localStorage.setItem("partner_token", true);
				window.location.href = '/partnerProfile';
			}
			else {
				this.setState({ errorMessage: response.message });
			}
		}.bind(this))
			.catch(function (response) {
				this.setState({ errorMessage: response.responseJSON.message });
			}.bind(this))
	}


	openSignup() {
		$('.e-signup').addClass('e-login-fly');
		$('.e-login').removeClass('e-login-fly');
	}
	antLogin() {



		sessionStorage.setItem("ts_login_redirect", window.location.href);

		const getLoginUrl = devconfig.aliceBlueAuthURL +
			"?appcode=" + devconfig.appCode;
		window.location.href = getLoginUrl;

	}

	render() {
		return (
			<div className="container text-left p-4 e-login" >
				<div className="col-lg-2">
					<button className="e-login-closebtn"><img src={closebtn} alt="close button" className="e-toggle" onClick={this.hideLogin} /></button>
				</div>
				<div className="col-lg-12">
					{
						!this.state.partner ?
							<React.Fragment>
								<h5 className="e-login-head mt-5">Login</h5>
								<p>Login with your Aliceblue account or use your gmail id.</p>
								<PrimaryBlueButton name="Login with Aliceblue ANT" handleClick={this.antLogin} />
								<a href='#' onClick={this.openSignup} className="e-card-explore d-block mt-4">Create New Account <img src={cardarrow} alt="arrow" className="e-card-arrow" /></a>
								<p className="e-login-p">Looking for Aliceblue Partner Login?</p>
								<PrimaryOutlineButton name="Partner Login" handleClick={() => { this.setState({ partner: true }) }} />
							</React.Fragment>
							:
							<React.Fragment>
								<div className="e-prt-login">
									<p className="e-login-p">Login with your partner id.</p>
									<a href='#' onClick={() => { this.setState({ partner: false }) }} className="e-card-explore d-block mt-4">Back to Login <img src={cardarrow} alt="arrow" className="e-card-arrow" /></a>
									<InputBox placeholder="User name" className="e-partner-login-input mt-5" value={this.state.username} valueChange={(tempVal) => { this.setState({ username: tempVal }) }} />
									<InputBox placeholder="Password" className="e-partner-login-input mt-3" type="password" value={this.state.password} valueChange={(tempVal) => { this.setState({ password: tempVal, errorMessage: "" }) }} />
									<PrimaryBlueButton name="Login" handleClick={this.loginClick.bind(this)} />
								</div>
								{
									this.state.errorMessage ?
										<div className="col-lg-12  e-p-left-0">
											<p className="e-f-weight-500 pt-4 e-c-red">{this.state.errorMessage}</p>
										</div>
										:
										null
								}
							</React.Fragment>
					}
				</div>
				<Signup />
			</div>

		)
	}
}