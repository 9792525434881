import React from 'react';
// import assetes
import playbtn from '../../assets/images/GetStartBanner/carbon_play-outline.png';
function gettingStartBanner(props){
	return( 
		
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12">
					<div className="e-getstart-banner pt-4">
						<div className="text-center mt-5">
						 	<h2>Just Getting Started?</h2>
						 	<p className="e-start-banner-p mt-3">Watch this 2-minute vedio to learn how Aliceblue's <br/> ecosystem can help you trade better.</p>
						</div>
					 	<div className="text-center">
					 		<button className="e-getstart-button mt-3">Watch video  <img className="ml-2" src={playbtn} alt="buttn"/></button>
					 	</div>
				 	</div>
			 	</div>
			</div>
		
	)
}

export default gettingStartBanner;