import React from 'react';

function privacyPolicy (props){

	return(

		<div className="container">
			<div className="col-lg-12">
				<h4 className="e-terms-head">Privacy Policy</h4>
				<p>
					This Privacy Policy statement is applicable to Alice Blue Trade Store. Trade Store does not collect personal information about individuals except when such individuals 
					pecifically provide such information on a voluntary basis. The privacy and protection of your data and information provided to us are of vital importance, we are strongly
					committed to protecting the personal and financial information that you submit to us.
					Personal information of individual users will not be sold or otherwise transferred to unaffiliated third parties without your approval at the time of collection.</p>

					<p>
						To enable us to serve you better, it is necessary that your personal information available with us is updated and accurate.
						You can also send us an e-mail to tradestore@aliceblueindia.com and we shall ensure that your information is updated and protected from any misuse or unauthorized revelation.
					</p>

					<p>
						We share information as part of normal business operations, such as providing you with any services to which you subscribe, any activity related to these services, and informing you about these services.
					</p>

					<p>
						Our website contains links to other web sites, and Alice Blue Trade Store would not be responsible for the privacy practices or the content of such Web sites.
					</p>

			</div>
			<div className="col-lg-12">
				<h4 className="e-terms-head">Password policy</h4>
				<p>
					Please help us protect your privacy by maintaining the secrecy of the username and password you use for any of our services.
				</p>
			</div>
			<div className="col-lg-12">
				<h4 className="e-terms-head">Changes to this Privacy Policy</h4>
				<p>
					Alice Blue Trade Store reserves the right to change or update this Privacy Policy or any other of our Policies/Practices at any time. 
					The same shall be notified to you by posting such changes or updated Private Policy on the page. 
					Any changes or updates will be effective immediately upon posting to this website.
				</p>
			</div>
			<div className="col-lg-12">
				<h4 className="e-terms-head">No contractual obligation</h4>
				<p>
					Please note that this Privacy Policy does not create any contractual or other legal rights in or on behalf of any party, nor is it intended to do so.
				</p>
			</div>
			<div className="col-lg-12 pb-5">
				<h4 className="e-terms-head">Disclaimer</h4>
				<p>
					You agree and understand that the information and material contained in this website implies and constitutes your consent to the terms and conditions mentioned below. 
					You also agree that Alice Blue Trade Store can modify or alter the terms and conditions of the use of this service without any liability.
				</p>
				<p>
					The content of the site and the interpretation of data are solely the personal views of the contributors. 
					Alice Blue reserves the right to make modifications and alterations to the content of the website.
					Users are advised to use the data for the purpose of information only and rely on their own judgment while making investment/trading decisions.
					The investments/trading ideas discussed or recommended may not be suitable for all investors/traders.
					Alice Blue Trade Store does not warranty the timeliness, accuracy, or quality of the electronic content.
				</p>
				<p>
					The content of the website cannot be copied, reproduced, republished, uploaded, posted, transmitted, or distributed for any non-personal use without
					obtaining prior permission from Alice Blue Trade Store, We reserve the right to terminate the accounts of subscribers/customers, who violate the 
					proprietary rights, in addition to necessary legal action.
				</p>
				<p>
					Alice Blue Trade Store and its owners/affiliates are not liable for damages caused by any performance, failure of performance, error, omission, 
					interruption, deletion, defect, delay in transmission or operations, computer virus, communications line failure, and unauthorized access to the personal
					accounts.
					Alice Blue Trade Store is not responsible for any technical failure or malfunctioning of the software or delays of any kind. 
					We are also not responsible for non-receipt of registration details or e-mails. Users shall bear all responsibility for keeping the password secure. 
					Alice Blue Trade Store is not responsible for the loss or misuse of the password.
				</p>
				<p>
					Alice Blue Trade Store is not responsible for the content of any of the linked sites by providing access to other web-sites,
				</p>
				<p>
					Alice Blue Trade Store is neither recommending nor endorsing the content available in the linked websites.
				</p>
				<p>
					The stock exchange, Mumbai is not in any manner answerable, responsible, or liable to any person or persons for any acts or omissions or commission, 
					errors, mistakes and/or partners, agents associates, etc., of any of the Rules, regulations, bye-laws of the Stock Exchange Mumbai, SEBI Act or any other 
					aws in force from time to time.
				</p>
				<p>
					The Stock Exchange, Mumbai is not answerable, responsible, or liable for any information on this website or for any services rendered by us, our employees, and our servants.
				</p>
				<p>
					Services of the Company displayed or available herein are for information and do not constitute an offer to buy or sell or a solicitation in this regard 
					made to any person. It is only available to NRIs residing in jurisdictions permitting investment in and trading of Indian securities on Indian stock exchanges.
					The Services are not available to any person or entity who is a citizen or resident of or located in any locality, state, country or other jurisdiction,
					where availing of such services would be contrary to any applicable law or regulation of that jurisdiction or which would subject the Company, its
					Directors, employees, associates, subsidiaries and/or affiliates to any registration or licensing requirement within such jurisdiction.

				</p>
				<p>
					If you do not agree to any of the terms mentioned in this agreement, you should exit the site.
				</p>
				<p>
					For any additional information, please email tradestore@aliceblueindia.com. please check our Privacy Policy, too.
				</p>
			</div>

		</div>
	)
	
}

export default privacyPolicy;