import React from 'react';
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';

export default class marketPlaceCard extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			data : []
		}
	}


	render(){
		return(
					<div className="col-lg-6">
					<div className="card">
					  <img className="card-img-top" src="..." alt="Card image cap"/>
					  <div class="card-body">
					    <h5 className="card-title">Card title</h5>
					    <p className="card-text"></p>
					    <h5>Price :</h5><span></span>
					    <h5>Offer Price :</h5><span></span>
					    <PrimaryBlueButton name="view More Details"/> 
					    <PrimaryOutlineButton/>
					  </div>
					</div>
					</div>
			
		)
	}
}