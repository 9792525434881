import React from 'react';
import $ from 'jquery'; 

// import components
import Links from '../../components/navigation/Links';
// import asstes
import contactimg from '../../assets/images/contact/contactimg.svg';
import cardarrow from '../../assets/images/home/cardarrow.svg';
import ChatWidget from '../../components/widget/chatlist';



export default class Support extends React.Component{

constructor(props){
    super(props);
    this.state = {

    }
  }

productSupportClick(){
  this.setState({openProdChatWidget: !this.state.openProdChatWidget});
}

showLogin() {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-login').addClass('e-login-fly'); 
}

render(){
	return(

		<React.Fragment>
			{
        this.state.openProdChatWidget && this.props.data ?
          <ChatWidget prodSupport={true} prodData={this.props.data} close={this.productSupportClick.bind(this)}/>
        :
          null
      }

			<div className="media e-support mt-4">
	  		<img className="e-support-img mr-3" src={contactimg} alt="contact img"/>
	  		<div className="media-body ">
	    		<p className="mt-5">Product related issues?</p>
	    		{
	    			localStorage.getItem("tradestore_token") ?
	    				<a onClick={this.productSupportClick.bind(this)} className="e-support-link e-link-txt"> Chat with Product Support <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
	    			:
	    				<a onClick={this.showLogin.bind(this)} className="e-support-link e-link-txt"> Chat with Product Support <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
	    		}
	    		
	 			</div>
			</div>

		</React.Fragment>
	)
}} 