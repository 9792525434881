import React from 'react';

// import swrvice
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';

import GetURLVar from '../../services/getParams/get-url'; 
// import components
import RefferalHistory from './RefferalHistory';
import PartnerBankDetails from './PartnerBankDetails';
// import assets
import userBg from '../../assets/images/userProfile/userprobg.svg';
import userBg1 from '../../assets/images/userProfile/userprobg1.svg';


export default class UserProfile extends React.Component{
	constructor(props){
		super(props);
		this.state={
			tabIndex: 1,
		}
	}
	usrTab = (e) => {
		this.setState({ tabIndex: e });
	}
	componentDidMount(){
		GetURLVar().then(function(query){ 

			if (query.type == 1){
				this.setState({tabIndex: 1});
			}
			else{
				this.setState({tabIndex: 2});
			}
		}.bind(this));

		this.getProfile();
	}

	getProfile=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.partnerV1+"/auth/profile";
  
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	renderTab(){
		if (this.state.tabIndex == 1){
			return <RefferalHistory/>
		}
		else{

			return (
				// <div className="col-lg-6">
					<PartnerBankDetails />
				// </div>
			)
		}
	}
	render(){
		return(
			<React.Fragment>
			{
				this.state.data?
			<React.Fragment>
			<section className="e-partner-pro-sec">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-12 col-sm-12 col-12">
						<h1 className="e-user-name">{this.state.data.name}</h1>
						<p className="e-user-dtls"><span className="e-partnerdlts-label">Email:</span>{this.state.data.email}</p>
						<p className="e-user-dtls"><span className="e-partnerdlts-label">Partner ID:</span>{this.state.data.alice_blue_id}</p>
					</div>
					<div className="col-lg-9 col-md-12 col-sm-12 col-12 text-left">
						<div className="row">
							<div className=" e-partner-card col-lg-3 col-md-4 col-sm-4 col-12">
								<div className="e-user-card text-center"><p>Reffered products</p> <h1 className="e-user-card-h">{this.state.data.referred_products}</h1> </div>
							</div>
							<div className="e-partner-card col-lg-3 col-md-4 col-sm-4 col-12">
								<div className="e-user-card text-center"><p>Commision</p> <h1 className="e-user-card-h"><span>&#8377;</span>{this.state.data.commission}</h1> </div>
							</div>
							<div className="e-partner-card col-lg-3 col-md-4 col-sm-4 col-12">
								<div className="e-user-card text-center"><p>Clients Joined</p> <h1 className="e-user-card-h"><span></span>{this.state.data.clients_joined}</h1> </div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</section>
			<div className="container e-usr-tab-sec">
				<div className="col-lg-12 col-md-12 col-sm-12 col-12  mt-4">
					<div className="row">
						<button className={this.state.tabIndex === 1 ? "mr-3 e-tablinks e-tabbtn-active" : "mr-3  e-tablinks"} onClick={() => this.usrTab(1)}>Refferal History</button>
						<button className={this.state.tabIndex === 2 ? "mr-3 e-tablinks e-tabbtn-active" : "mr-3  e-tablinks"}onClick={() => this.usrTab(2)}>Bank Details</button>
					</div>
					<div className="row">
					{this.renderTab()}
					</div>
				</div>
			</div>
			</React.Fragment>
			:
			null
		}
			</React.Fragment>
		)
	}
}