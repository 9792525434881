import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'; 
import { BsStarFill } from 'react-icons/bs';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import components
import Textarea from '../../components/formComponents/TextArea';
import PrimaryblueButton from '../../components/buttons/primaryBlueButton';

// import assets
import closebtn from '../../assets/images/header/close.png';

class ReviewModal extends React.Component {
  constructor(props){
    super(props);
    this.state={
     message: "",
     rating: null,
     id: null,
     error: ""
    }
  }
 closeModal =() =>{
  $('#e-add-review').modal('toggle');
  this.setState({rating: null})
  this.setState({message: null})
 } 
 componentDidMount(){
    GetURLVar().then(function(query){ 
      this.setState({id: query.id});
    }.bind(this));
  }
  submit =() =>{
    console.log(this.state.message)
    this.setState({error: ""});
    var url = devconfig.v3+"/product/add-review";
    var data = JSON.stringify({
      "id": parseInt(this.state.id),
      "rating": this.state.rating,
      "review": this.state.message,
    })
     APIPost(url, data).then(function(response){
       if(response.status === "ok"){
        this.setState({
          message: "",
          rating: null,
          id: null
        });
        this.props.success();
        this.closeModal();

      }
      else{
        this.setState({ error: response.message });
      }
    }.bind(this))
       }

 
  render() {
   
    return (

      <div className="modal fade e-product-modal e-add-review-modal" id="e-add-review" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="eVideoModalTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="e-reviewmodal-head mb-0">Rate & Review</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pt-0">
              <p className="mt-3 e-reviewmodal-p">Describe your experience with the product</p>
              <div className="e-rating-star">
                {[...Array(5)].map((star , i,)=>{
                const ratingValue = i + 1;
                return(
                  <label>
                    <input type ="radio" name="rating" value={ratingValue} onClick={()=>this.setState({rating: ratingValue})} />
                    <BsStarFill className="e-star" color={ratingValue <=  this.state.rating ? "#ffc107" : "#e4e5e9" }/>
                  </label>
                ) 
                })}  
              </div>
              <Textarea className="e-text mt-4" value={this.state.message} valueChange={(tempVal) => this.setState({message: tempVal})} placeholder="Write your review..."/>
              {
                this.state.error.length ?
                  <div className="mt-3 e-error-msg">
                    { this.state.error }
                  </div>
                :
                  null
              }
            </div>

            <div className="modal-footer text-right position-relative">
              <span className="e-link-txt mr-3" data-dismiss="modal" aria-label="Close">Cancel</span>
              <PrimaryblueButton name="Submit your review" handleClick={this.submit.bind(this)}/>
            </div>

          </div>
        </div>
      </div>
      
    );
  }
}



export default ReviewModal