import React from 'react';
import Radio from '@material-ui/core/Radio';
import $ from 'jquery';

/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';


/*import components*/
import WelcomeTrade from '../../components/banners/WelcomeTradeBanner';
import PlaystoreBanner from '../../components/banners/playstoreBanner';
import JoinAliceblue from '../../components/banners/joinAliceblue';
import Links from '../../components/navigation/Links';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import MarketPlaceCard from '../../components/cards/marketPlaceCard';
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import RangeSlider from '../../components/rangeSlider/PriceRangeSlider';
import MiniInvestmentSlider from '../../components/rangeSlider/MiniInvestmentSlider';
import InputSlider from '../../components/rangeSlider/InputSlider';
import MarketPlaceAllCard from './MarketPlaceAllCard';
import MarketPlaceFilter from './MarketPlaceFilter';
import ProductCard from '../../components/cards/productCard';
import Pagination from '../../components/pagination/Pagination';

import ApiLoader from '../../components/loader/apiLoader';
import OverlayLoader from '../../components/loader/pageOverlayLoader';
import NothingFound from '../../components/globel/nothingFound';



/*import assets*/
import fourstar from '../../assets/images/rating/four.svg';
import threestar from '../../assets/images/rating/three.svg';
import twostar from '../../assets/images/rating/two.svg';
import onestar from '../../assets/images/rating/one.svg';
import sensigroup from '../../assets//images/marketplaceDetails/sensigrop.svg';
import alicebluegrp from '../../assets//images/marketplaceDetails/alicebluegrp.svg';
import optimumgrp from '../../assets//images/marketplaceDetails/optimumgrp.svg';
import smallcasegrp from '../../assets//images/marketplaceDetails/smallcasegrp.svg';
import filter from '../../assets/images/marketplaceDetails/filter.svg';

const options = [
  { value: 0, label: 'Recommended' },
  { value: 1, label: 'Newly Added' },
  { value: 2, label: 'Top Rated' },
  { value: 3, label: 'Top Performing' },
  { value: 4, label: 'Name' },
  { value: 5, label: 'Price - Low to High' },
  { value: 6, label: 'Price - High to Low' },

];

export default class marketplace extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pricetype: 0,
      rating: 0,
      type: 0,
      segment: 0,
      tabIndex: null,
      data: null,
      filters: null,
      sortIndex: 0,
      priceRange: { min: 0, max: 2500 },
      investmentRange: { min: 0, max: 12000 },
      pageNum: 1,
      dataLoading: true,
      filterLoading: false,
      min_price: 0,
      min_invest: 0,
    };
  }

  componentWillMount() {
    this.getFilter();
    // this.getData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async handleTab(tempId) {
    await this.setState({ tabIndex: tempId, pageNum: 1 });
    this.getData();
  }

  async handleSort(tempSort) {
    await this.setState({ sortIndex: tempSort.value, pageNum: 1 });
    this.getData();
  }

  showFilter = () => {
    $('.e-market-fil').addClass('e-filter-fly');
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
  }

  getFilter() {
    var url = devconfig.v3 + "/marketplace/filter-data";
    APIGet(url).then(async function (response) {
      if (response.status === "ok") {
        await this.setState({
          filters: response.data,
          max_price: response.data.max_price_limit,
          max_invest: response.data.max_investment_limit,
          // min_price: response.data.min_price_limit,
          // min_invest: response.data.min_investment_limit
        });
        await this.getData();
      }
    }.bind(this))
  }

  getData() {
    this.setState({ filterLoading: true });
    var url = devconfig.v3 + "/marketplace/filter";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "filter_id": parseInt(this.state.tabIndex),
      "segment_id": parseInt(this.state.segment),
      "price_type": parseInt(this.state.pricetype),
      "rating_by": parseInt(this.state.rating),
      "application_type": parseInt(this.state.type),
      "min_price_limit": parseInt(this.state.min_price),
      "max_price_limit": parseInt(this.state.max_price),
      "min_investment_limit": parseInt(this.state.min_invest),
      "max_investment_limit": parseInt(this.state.max_invest),
      "sort_by": parseInt(this.state.sortIndex)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ data: response.data, dataLoading: false, filterLoading: false });
      }
      else {
        this.setState({ dataLoading: false, filterLoading: false });
      }
    }.bind(this))
  }

  async filterChange(tempType, tempValue) {
    switch (tempType) {
      case 1:
        await this.setState({ segment: tempValue });
        break;
      case 2:
        await this.setState({ rating: tempValue });
        break;
      case 3:
        await this.setState({ type: tempValue });
        break;
      case 4:
        await this.setState({ pricetype: tempValue });
        break;
      case 5:
        await this.setState({ max_price: tempValue.max, min_price: tempValue.min });
        break;
      case 6:
        await this.setState({ max_invest: tempValue.max, min_invest: tempValue.min });
        break;
    }
    await this.setState({ pageNum: 1 });
    this.getData();
  }

  async searchChange(tempValue) {
    await this.setState({ data: null, pageNum: 1, filterLoading: true });
    var url = devconfig.v3 + "/marketplace/search";
    var data = JSON.stringify({
      "page_num": this.state.pageNum,
      "text": tempValue
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ data: response.data, filterLoading: false });
      }
      else {
        this.setState({ filterLoading: false });
      }
    }.bind(this))
  }

  async pageNumChange(tempVal) {
    await this.setState({ pageNum: tempVal });
    this.getData();
  }

  render() {

    return (
      <React.Fragment>
        <div className="container">
          <section className="pb-5">
            <div className="col-lg-12">
              <WelcomeTrade />
            </div>
          </section>
          <section className="e-marketplace-card-sec">
            <div className="row ">
              <div className="col-lg-4 text-left ">
                <MarketPlaceFilter filters={this.state.filters} change={this.filterChange.bind(this)} search={this.searchChange.bind(this)} />
              </div>
              <div className="col-lg-8 pl-4">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {
                      this.state.filters ?
                        <div className="e-tab">
                          <button className={!this.state.tabIndex ? "mr-3 e-tablinks e-tabbtn-active" : "mr-3 e-tablinks"} onClick={() => this.handleTab(null)}>All</button>
                          {
                            this.state.filters.filter_data.map((item, index) => {
                              return (
                                <button key={index} className={this.state.tabIndex == item.id ? "mr-3 e-tablinks e-tabbtn-active" : "mr-3 e-tablinks"} onClick={() => this.handleTab(item.id)}>{item.name}</button>
                              );
                            })
                          }
                        </div>
                        :
                        null
                    }

                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-10 col-10  text-lg-right  mt-2">
                    <span className="e-select-label mr-2">Sort by:</span>
                    <SelectBox className="d-inline-block e-filter-select text-left" options={options} selectchange={(tempVal) => this.handleSort(tempVal)} defaultValue={{ label: "Recommended", value: 0 }} />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2   text-right">
                    <button className="e-filter-btn" onClick={this.showFilter}> <img src={filter} alt="filter img" className="e-filter-img" /></button>
                  </div>
                </div>
                {
                  this.state.data && this.state.data.products && this.state.data.products.length ?
                    <div className="row mt-4 e-marketplace-allcard-sec">
                      {
                        this.state.data.products.map((item, index) => {
                          return (
                            <div className="col-lg-6 col-md-6 mb-4 pb-1" key={index}>
                              <ProductCard data={item} />
                            </div>
                          );
                        })
                      }
                    </div>
                    :
                    <React.Fragment>
                      {
                        !this.state.dataLoading && !this.state.filterLoading ?
                          <NothingFound text="No product found! Clear the filters and try again." />
                          :
                          null
                      }
                    </React.Fragment>
                }

                {
                  this.state.filterLoading && !this.state.dataLoading ?
                    <OverlayLoader />
                    :
                    null
                }


                {
                  this.state.dataLoading ?
                    <ApiLoader />
                    :
                    null
                }

                {
                  this.state.data && this.state.data.no_of_pages > 1 ?
                    <Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum} />
                    :
                    null
                }

              </div>
            </div>
          </section>
          <section className="e-marketplace-playbanner-sec">
            <PlaystoreBanner />
            <div className="col-lg-12 mt-5 pt-5">
              <p className='e-note-text-p'><b>Note: </b>As per the <a href="https://www.sebi.gov.in/legal/circulars/sep-2022/performance-return-claimed-by-unregulated-platforms-offering-algorithmic-strategies-for-trading_62628.html" target='_blank'>SEBI circular dated 02 September 2022</a> & <a href="https://www1.nseindia.com/content/circulars/COMP53555.pdf" target='_blank'>NSE Circular dated 06 September 2022</a>, Alice Blue is not directly or indirectly associated with any Algo trading platforms that claim past or expected future returns/performance on strategies.</p>
            </div>
          </section>
        </div>
        <div className="container-fluid">
          <JoinAliceblue />
        </div>
      </React.Fragment>
    )
  }
}
