import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';


// import assets
import closebtn from '../../assets/images/header/close.png';


export default class VideoModal extends React.Component {
  constructor(props){
    super(props);
    this.state={
      show: true,
    };
  }

  render() {
    return (
      <div class="modal fade" id={this.props.modalId} data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="eVideoModalTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

