import React from 'react';
// import assets
 import welcomebgimg from '../../assets/images/welcomeBanner/bgimg.svg';

function WelcomeTradeBanner(){
	return(
		<div className="row e-welcomebanner">
			<div className="col-lg-12 text-center mt-5 e-welcome-baner-col">

				<h2 className="mt-5">Welcome to Tradestore marketplace!</h2>
				<p className="mt-3">A Marketplace for advanced tools & apps to empower your every trade!</p>

			</div>
		</div>
	)
}

export default WelcomeTradeBanner;