import React from 'react';
import Radio from '@material-ui/core/Radio';
import $ from 'jquery';
import  { Redirect } from 'react-router-dom';


/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import components
import GetStart from '../../components/banners/JustStartBanner';
import PlaystoreBanner from '../../components/banners/playstoreBanner';
import JoinAliceblue from '../../components/banners/joinAliceblue';
import Links from '../../components/navigation/Links';
import Pagination from '../../components/pagination/Pagination';

import OverlayLoader from '../../components/loader/pageOverlayLoader';
import NothingFound from '../../components/globel/nothingFound';
import ApiLoader from '../../components/loader/apiLoader';

import ResourceFilter from './ResourceFilter';
import VideoList from './VideoListing';
import Webinar from './Webinar';
import Blog from './Blog';
import PerfomanceReport from './ResourceReport';

// import assets
import filter from '../../assets/images/marketplaceDetails/filter.svg';

const options = [
      { value: 'new', label: 'Newly Added' },
      { value: 'low-high', label: 'Duration - Low to High' },
      { value: 'high-low', label: 'Duration - High to Low' },

    ];

export default class resources extends React.Component{
	constructor(props){
		super(props)
		this.state ={
			tabIndex: "videos",
			product: 0,
			filetrs: null,
			pageNum: 1,
			productId: null,
			sortIndex: 1,
			videos: null,
			type: 1,
			webinar: null,
			blogs: null,
			report: null,
			filterLoading: false,
			videoLoading: true,
			webinarLoading: true,
			blogLoading: true,
			reportsLoading: true,
			apiLoading: true,
			animate: 1,
			tabChange: false
		}
	}
	componentDidMount() {
		this.getFilter();
		this.getVideo();
		this.getWebinar();
		this.getBlogs();
		this.getReport();
		window.scrollTo({top: 0, behavior: 'smooth'});

		var tempUrl = (window.location.href).replace(/\s+/g, '-');
		GetURLVar().then(function(query){ 
			switch(query.type){
				case "videos" :
				this.setState({tabIndex: "videos", animate: 1});
				break;
				case "webinar" :
				this.setState({tabIndex: "webinar", animate: 2});
				break;
				case "blog" :
				this.setState({tabIndex: "blog", animate: 3});
				break;
				case "report" :
				this.setState({tabIndex: "report", animate: 4});
				break;
			}

		}.bind(this));

  }

  RouteChange= ()=>{
    return <Redirect to='/resourceVideo'  />
  }


  getFilter(){
  	this.setState({FilterLoading: true});
		var url = devconfig.v3+"/resources/product-names";
		APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({ filters: response.data , FilterLoading: false});
      }
      else{
      	this.setState({FilterLoading: false})
      }
    }.bind(this))
	}
	
	async filterChange(tempValue){
		await this.setState({productId: tempValue, pageNum: 1});
		switch(this.state.tabIndex){
			case "videos" :
			this.getVideo();
			break;
			case "webinar" :
			this.getWebinar();
			break;
			case "blog" :
			this.getBlogs();
			break;
			case "report" :
			this.getReport();
			break;
		}
	}

	getVideo(){
		this.setState({videos: null, filterLoading: true});
		var url = devconfig.v3+"/resources/list-video";
    var data = JSON.stringify({
      "product_id": parseInt(this.state.productId),
      "page_num": parseInt(this.state.pageNum),
      "sort_by" : parseInt(this.state.sortIndex),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
  			this.setState({videos:response.data, apiLoading: false, filterLoading: false});
      }
      else{
        this.setState({ apiLoading: false, filterLoading: false});
      }
    }.bind(this))
	}


	getWebinar(){
		this.setState({webinar: null, filterLoading: true});
		var url = devconfig.v3+"/resources/list-webinar";
    var data = JSON.stringify({
      "product_id": parseInt(this.state.productId),
      "page_num": parseInt(this.state.pageNum),
      "type" : parseInt(this.state.type),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
  			this.setState({webinar:response.data, apiLoading: false, filterLoading: false});
      }
      else{
        this.setState({ apiLoading: false, filterLoading: false});
      }
    }.bind(this))
	}


	getBlogs(){
		this.setState({blogs: null, filterLoading: true});
		var url = devconfig.v3+"/resources/list-blog";
    var data = JSON.stringify({
      "product_id": parseInt(this.state.productId),
      "page_num": parseInt(this.state.pageNum),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
  			this.setState({blogs:response.data, apiLoading: false, filterLoading: false});
      }
      else{
        this.setState({ apiLoading: false, filterLoading: false});
      }
    }.bind(this))
	}

	getReport(){
		this.setState({report: null, filterLoading: true});
		var url = devconfig.v3+"/resources/list-report";
    var data = JSON.stringify({
      "product_id": parseInt(this.state.productId),
      "page_num": parseInt(this.state.pageNum),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({report:response.data, apiLoading: false, filterLoading: false});
      }
      else{
        this.setState({ apiLoading: false, filterLoading: false});
      }
    }.bind(this))
	}

	async handleRadio(e){
		await this.setState({type: e.target.value});
		this.getWebinar();
	}

	async handleTab (e){
		await this.setState({ tabIndex: e, productId: null, tabChange: true});
		switch(e){
			case 'videos': 
			this.setState({animate: 1, tabChange: false});
			break;
			case 'webinar':
			this.setState({animate: 2, tabChange: false});
			break;
			case 'blog':
			this.setState({animate: 3, tabChange: false});
			break;
			case 'report':
			this.setState({animate: 4, tabChange: false});
			break;
		}
		
	}

	renderPagiantion(){
		switch(this.state.tabIndex){
			case 'videos':
			return(
				this.state.videos && this.state.videos.no_of_pages > 1 ?
        <Pagination pageNum={this.state.videos.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
			)
			break;
			case 'webinar':
			return(
				this.state.webinar && this.state.webinar.no_of_pages > 1 ?
        <Pagination pageNum={this.state.webinar.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
			)
			break;
			case 'blog':
			return(
				this.state.blogs && this.state.blogs.no_of_pages > 1 ?
        <Pagination pageNum={this.state.blogs.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
			)
			break;
			case 'report':
			return(
				this.state.report && this.state.report.no_of_pages > 1 ?
        <Pagination pageNum={this.state.report.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
			)
			break;
		}
	}

	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		window.scrollTo({top: 0, behavior: 'smooth'});
		switch(this.state.tabIndex){
			case 'videos' :
			this.getVideo();
			break;
			case 'webinar' :
			this.getWebinar();
			break;
			case 'blog' :
			this.getBlogs();
			break;
			case 'report' :
			this.getReport();
			break;
		}
		
	}
	renderTab(){
		if(this.state.apiLoading){
			return(
				<div className="col-lg-12 text-center e-min-height-loader">
					<ApiLoader/>
				</div>
			)
		}

		switch(this.state.tabIndex){
			case 'videos' :{
			
					if(this.state.filterLoading == true){
						return(<OverlayLoader/>)
					}
					else if(this.state.videos && this.state.videos.videos.length){
						return(
								 this.state.videos.videos.map((item, index) => {
						  		const id = item.videoID;
			  					const idwithhash = id ? `#${id}` : "nope";
					    		return(<VideoList data={item} dataId={idwithhash} />);
						  })
						)
					}
					else{
						return(
								<div className="col-lg-12 text-center"><NothingFound text="No videos found! Clear the filters and try again."/></div>
							)
					}
			}
			break;

			case 'webinar' :{
							
							if(this.state.filterLoading == true){
							return(<OverlayLoader/>)
							}
							else if(this.state.webinar &&this.state.webinar.webinars.length )
							{
								return(
							  this.state.webinar.webinars.map((item, index) => {
							  	return( <Webinar data={item}/>);
								})
								)
							}
							else 
							 {
							return(<div className="col-lg-12 text-center"><NothingFound text="No webinars found! Clear the filters and try again."/></div>)
							}
						
				
			}
			break;

			case 'blog' :{
				if(this.state.filterLoading == true){
					return(
						<OverlayLoader/>
					)
				}
				else if(this.state.blogs && this.state.blogs.blogs.length){
					return(
						  this.state.blogs.blogs.map((item, index) => {
						  	return(
						  		<Blog data={item}/>
						  	);
						  })
					 	)
					}
					else{
						return(<div className="col-lg-12 text-center"><NothingFound text="No blogs found! Clear the filters and try again."/></div>)
					}	
			}
			break;

			case 'report' :{
				if(this.state.filterLoading == true){
					return(
						<OverlayLoader/>
					)
				}
				else if(this.state.reports && this.state.report.reports.length){
					return(
							this.state.report.reports.map((item, index) =>{
								return(
									<PerfomanceReport data={item}/>
								); 
							})
						)
				}
				else{
						return(<div className="col-lg-12 text-center"><NothingFound text="No reports found! Clear the filters and try again."/></div>)
				}
									
					
			}
			break;
		}
	}

	showFilter =()=>{
		$('.e-market-fil').addClass('e-filter-fly'); 
		var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
	 	$("body").append(overlaylogin);
	}

	
	showLeads(){
    sessionStorage.setItem("ts_url", "/resources?type=report");


    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-lead').addClass('e-login-fly'); 
  }

		render(){

				return(
					<React.Fragment>
						<div className="container">
							<section className="pb-5">
								<GetStart/>	
							</section>
							<section className="e-marketplace-card-sec">
								<div className="row">
									<div className="col-lg-4 text-left">
										{
											!this.state.tabChange ?
												<ResourceFilter filters={this.state.filters} change={this.filterChange.bind(this)} tabIndex={this.state.tabIndex}/>
											:
												null
										}
			      			</div>
									<div className="col-lg-8 pl-4">
										<div className="row">
											<div className="col-lg-10 col-md-10 col-sm-10 col-10 mt-2 e-tab">
												<button className={this.state.animate ==1 ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="video-tab" onClick={this.handleTab.bind(this, "videos")}>Videos</button>
					  						<button className={this.state.animate ==2 ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} onClick={this.handleTab.bind(this, "webinar")}>Webinars </button>
					  						{/* <button className={this.state.animate ==3 ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} onClick={this.handleTab.bind(this, "blog")}>Blogs</button> */}
					  						{/* {
					  							localStorage.getItem("tradestore_token") || localStorage.getItem("tradestore_lead_success") || localStorage.getItem("access_token") ?
					  								<button className={this.state.animate ==4 ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="report-tab" onClick={this.handleTab.bind(this, "report")}>Perfomance Report</button>
					  							:
					  								<button className={"mr-4 e-tablinks"} id="report-tab" onClick={this.showLeads}>Perfomance Report</button>
					  						} */}
					  						
					  					</div>
					  					<div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
					  						<button className="e-filter-btn" onClick={this.showFilter}> <img src={filter} alt="filter img" className="e-filter-img"/></button>
					  					</div>
											
										</div>
										<div className ="row mt-1 e-render-tab-wrap">
										{
											this.state.tabIndex === 'webinar'?
											<React.Fragment>
												<div className="col-lg-12 mt-3">
													<label className="e-radio-label mr-5">
														<Radio value= {1}  checked={this.state.type == 1} onChange={this.handleRadio.bind(this)}/> Upcoming Live Webinars
													</label>
													<label className="e-radio-label">
														<Radio value= {2}  checked={this.state.type == 2} onChange={this.handleRadio.bind(this)}/> Webinar Replays
													</label>
												</div>
											</React.Fragment>
											:
											null
										}

										{this.renderTab()}
									
										</div>
										<div className="row">
										{this.renderPagiantion()}
												
										</div>
									</div>
								</div>
							</section>
							<section className="e-marketplace-playbanner-sec">
								<PlaystoreBanner/>	
							</section>
						</div>
						<div className="container-fluid">
							<JoinAliceblue/>
						</div>
					</React.Fragment>
				)
			}
		}
