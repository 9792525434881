import React from 'react';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import components
import ApiLoader from '../../components/loader/apiLoader';
import NothingFound from '../../components/globel/nothingFound';

// import assest
import downloadpdf from '../../assets/images/marketplaceDetails/pdfdownload.svg';
import acopen from '../../assets/images/marketplaceDetails/acopenhelp.svg';
import faqbg1 from '../../assets/images/marketplaceDetails/faqbg1.svg';
import faqbg2 from '../../assets/images/marketplaceDetails/faqbg2.svg';
import faqbg3 from '../../assets/images/marketplaceDetails/faqbg3.svg';
import contactimg from '../../assets/images/contact/contactimg.svg';
import cardarrow from '../../assets/images/home/cardarrow.svg';

export default class PerfomanceReport extends React.Component{

	constructor(props){
		super(props);
		this.state={
			pageNum: 1,
			data: null,
		}
	}
	componentWillMount() {
    GetURLVar().then(function(query){ this.getData(query.id) }.bind(this));
  }
  getData(tempId){
  	this.setState({id: tempId});
		this.setState({data: null, dataLoading: true});
		var url = devconfig.v3+"/resources/list-report";
    var data = JSON.stringify({
      "product_id": parseInt(this.state.id),
      "page_num": parseInt(this.state.pageNum),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
	render(){
		return(
		<React.Fragment>
		<section className="e-prod-report-wrap">
			<React.Fragment>
				{
					this.state.dataLoading ?
					<ApiLoader />
					:
					null

				}
			</React.Fragment>
			<React.Fragment>
				{
					!this.state.dataLoading  && this.state.data && !this.state.data.reports.length ?
					<NothingFound text="No Report Found!" />
					:
					null

				}
			</React.Fragment>
			<div className="row">
			{
				this.state.data ?
			    this.state.data.reports.map((item, index) => {
	          return(
							<div className="col-lg-4 mt-4" key={index}>
								<div className="card  e-report-card  border-0 p-3">
								<div className="row  d-flex justify-content-between mt-2">
									<h5 className="text-left ml-3 e-report-head">{item.file_name}</h5>
									<a href={item.file_url} target="_blank" className=" mr-3 e-pdf-download">PDF <img src={downloadpdf} alt="image"/></a>
								</div>
								</div>
							</div>
						);
			  	})
		    :
			    null
			}

			</div>
		</section>
		</React.Fragment>
	)
	}
}
