import React from 'react';

// import components
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
// import assets
import sensigroup from '../../assets/images/marketplaceDetails/sensigrop.svg';
import alicebluegrp from '../../assets/images/marketplaceDetails/alicebluegrp.svg';
import optimumgrp from '../../assets/images/marketplaceDetails/optimumgrp.svg';
import smallcasegrp from '../../assets/images/marketplaceDetails/smallcasegrp.svg';

function MarketPlaceAllCard(props){
	return(

		<div className="row mt-4 e-marketplace-allcard-sec">
			<div className="col-lg-6 col-md-6">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-sensi-hed p-3">
				  	<img className="card-img-top" src={smallcasegrp} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">Invest in idead with Small Case</h5>
				    <p className="e-mrktplc-card-text">Smallcases are investment products that help build a diversified, low-cost & long term porfolio.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; 100</span> <span className="e-mrktplc-product-plan">per smallcase (buy)</span></p>
				    <p><span className="e-mrktplc-card-title">Trade Store Price:</span> <span className="e-mrktplc-card-title e-prdt-free-grn">Free</span> <span className="e-mrktplc-product-plan">for aliceblue customers</span></p>
				    <PrimaryBlueButton name="View More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
			<div className="col-lg-6 col-md-6">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-abcard-hed p-3">
				  	<img className="card-img-top" src={alicebluegrp} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">Buy Direct Funds at Zero Commission</h5>
				    <p className="e-mrktplc-card-text">Never depend on a single income, make an investment to create a second one with us.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype mr-2">Investors</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price: &#8377; 1000</span> <span className="e-mrktplc-product-plan">for lifetime</span></p>
				    <p><span className="e-mrktplc-card-title">Trade Store Price :</span> <span className="e-mrktplc-card-title e-prdt-free-grn">&#8377; 500</span> <span className="e-mrktplc-product-plan">for aliceblue customers</span></p>
				    <PrimaryBlueButton name="View More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
			<div className="col-lg-6 col-md-6 mt-4">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-sensi-hed p-3">
				  	<img className="card-img-top" src={sensigroup} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">India's First Option trading Platform</h5>
				    <p className="e-mrktplc-card-text">You can trade options just by knowing the stock and its direction.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; 399</span> <span className="e-mrktplc-product-plan">per month</span></p>
				    <p><span className="e-mrktplc-card-title">Trade Store Price :</span> <span className="e-mrktplc-card-title e-prdt-free-grn">&#8377; 199</span> <span className="e-mrktplc-product-plan">for aliceblue customers</span></p>
				    <PrimaryBlueButton name="View More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
			<div className="col-lg-6 col-md-6  mt-4">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-optimum-hed p-3">
				  	<img className="card-img-top" src={optimumgrp} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">Intraday based Semi Algo Trading Platform</h5>
				    <p className="e-mrktplc-card-text">Optimum gives Buy above and Sell below levels for Stock Cash, Futures and MCX Markets.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype mr-2">Investors</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; 399</span> <span className="e-mrktplc-product-plan">per month</span></p>
				    <p><span className="e-mrktplc-card-title">Offer Price:</span> <span className="e-mrktplc-card-title e-prdt-free-grn">Free</span> <span className="e-mrktplc-product-plan">6 days trail pack</span></p>
				    <PrimaryBlueButton name="view More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
			<div className="col-lg-6 col-md-6 mt-4">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-sensi-hed p-3">
				  	<img className="card-img-top" src={sensigroup} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">India's First Option trading Platform</h5>
				    <p className="e-mrktplc-card-text">You can trade options just by knowing the stock and its direction.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; 399</span> <span className="e-mrktplc-product-plan">per month</span></p>
				    <p><span className="e-mrktplc-card-title">Trade Store Price :</span> <span className="e-mrktplc-card-title e-prdt-free-grn">&#8377; 199</span> <span className="e-mrktplc-product-plan">for aliceblue customers</span></p>
				    <PrimaryBlueButton name="View More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
			<div className="col-lg-6 col-md-6 mt-4">
				<div className="card e-marketDetails-card border-0">
					<div className="e-card-head e-optimum-hed p-3">
				  	<img className="card-img-top" src={optimumgrp} alt="Card image cap"/>
				  </div>
				  <div className="card-body">
				    <h5 className="e-mrktplc-card-title">Intraday based Semi Algo Trading Platform</h5>
				    <p className="e-mrktplc-card-text">Optimum gives Buy above and Sell below levels for Stock Cash, Futures and MCX Markets.</p>
				    <div className="row pl-3">
				    	<span className="e-mrktplce-usrtype mr-2">Trades</span>
				    	<span className="e-mrktplce-usrtype mr-2">Investors</span>
				    	<span className="e-mrktplce-usrtype">Strategists</span>
				    </div>
				    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; 399</span> <span className="e-mrktplc-product-plan">per month</span></p>
				    <p><span className="e-mrktplc-card-title">Offer Price:</span> <span className="e-mrktplc-card-title e-prdt-free-grn">Free</span> <span className="e-mrktplc-product-plan">6 days trail pack</span></p>
				    <PrimaryBlueButton name="view More Details"/>
				    <PrimaryOutlineButton/>
				  </div>
				</div>
			</div>
		</div>
	)
}

export default MarketPlaceAllCard;