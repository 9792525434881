import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
 
export default class InputSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        min: this.props.minRange,
        max: this.props.maxRange,
      },
    };
  }
 
  render() {
    return (
      <form className="e-slider-form">
        <InputRange
          draggableTrack
          maxValue={this.props.maxValue}
          minValue={this.props.minValue}
          minValue={0}
          onChange={value => this.setState({ values: value })}
          onChangeComplete={value => {
            this.props.change(value)
            // console.log(value)
          }}
          value={this.state.values} />
      </form>
    );
  }
}

