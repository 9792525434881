import React from 'react';
import Select from 'react-select';


export default class selectBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	selectedValue: null,
    }
  }

  // componentDidMount(){
  //   this.setState({ selectedValue: this.props.defaultValue});
  // }

  handleChange = selectedValue => {
    this.setState({ selectedValue });
    this.props.selectchange(selectedValue)
  };

  render() {
    const customStyles = {
      control: (base, state) => ({
          ...base,
        padding: '2px',
        border: '1px solid #EAEAEA',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid #EAEAEA',
        }
     })
    }
    return (
      <Select 
        isSearchable={false} 
        className={this.props.className} 
        defaultValue={this.props.defaultValue}  
        styles={customStyles} 
        options={this.props.options} 
        value={this.props.selectedValue} 
        onChange={this.handleChange} 
        controlShouldRenderValue = { this.props.displayOpt}  
        isMulti={this.props.selectType} 
        placeholder={this.props.placeholder}
        menuIsOpen={this.props.menuIsOpen}
      />
    );
  }
}
