import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 


// import assets
import closebtn from '../../assets/images/header/close.png';
import PaymentSuccess from '../../assets/images/marketplaceDetails/payment-success.svg';
import PaymentFail from '../../assets/images/marketplaceDetails/payment-fail.svg';
import PrimaryblueButton from '../buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import loader from '../../assets/images/loader/loader.gif';

export default class PurchaseConfirmation extends React.Component {
  constructor(props){
    super(props);
    this.state={
      show: true,
      paymentUpdated: null,
      total: 0,
      loading: false,
      priceLoading: false,
    };
  }

  componentDidMount(){
    if(this.props.planId && this.props.productId && localStorage.getItem("tradestore_token")){
     
      $('#e-product-purchase').on('shown.bs.modal', (e) => {
         this.getAmount();
         // alert("I want this to appear after the modal has opened!");
  // do something...
})

    }
     console.log(this.props.pmethod);
    console.log("this.props.ref");
    console.log(this.props.ref);


    GetURLVar().then(function(query){ 
      if(query.pstatus && query.pstatus){
        $('#e-product-purchase').modal('show');
        this.setState({ paymentUpdated: 1});
        this.paymentStatus(true, query.transaction_id);
      }
      else if(query.pstatus && !query.pstatus){
        this.setState({ paymentUpdated: 2});
      }
    }.bind(this));


   

  }


  getAmount(){
    this.setState({priceLoading: true});
    var url = devconfig.v3+"/product/get-amount";
    var data = JSON.stringify({
      "product_id": Number(this.props.productId),
      "plan_id" : Number(this.props.planId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({ total: response.data.total, priceLoading: false});
        console.log("getAmount");
        console.log(response);
      }
      else{
        this.setState({priceLoading: false});
      }
    }.bind(this))
  }

  success(){
    this.purchase();
  }

  purchase(){
    this.setState({loading: true});
    var tempref = ""
    if(localStorage.getItem("ts_ref") && this.props.details.id == localStorage.getItem("ts_id")){
      tempref = localStorage.getItem("ts_ref")
    }

    var url = devconfig.v3+"/product/purchase";
    var data = JSON.stringify({
      "product_id": Number(this.props.details.id),
      "plan_id" : Number(this.props.planData.id),
      "referral_code" : tempref,
      "buy_using_wallet" : this.props.pmethod
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({loading: false});
        sessionStorage.setItem("purchased_id", response.data.purchased_id);
        if(response.data.payment_status){
          this.setState({paymentUpdated: 1});
        }
        if(this.props.type == 3|| this.props.type == 4 && !this.props.pmethod){
          var tempData = JSON.parse(localStorage.getItem('userData'));
          var temptotal = this.state.total;
          var tempPrice = Number(temptotal) * 100;
    
          let options = {
            // live key rzp_live_ybjIzKToKDFtXK 
            "key": "rzp_live_ybjIzKToKDFtXK",  //rzp_live_ybjIzKToKDFtXK  rzp_test_T56yrs8IVNrcbK
            "amount": tempPrice, // 2000 paise = INR 20, amount in paisa
            "currency": "INR",
            "name": "Aliceblue",
            "description": "Trade store",
            "order_id": response.data.razorpay_order_id, // comment this dev
            "image": "https://evoque-mw-dev.netlify.com/assets/images/AB-Trade-Store-Logo.png",
            "callback_url": 'https://your-server/callback_url',
            "handler": function (response){
              if(response.razorpay_payment_id){
                var tempUrl = window.location.href;
                var tempUpdatedUrl = `${tempUrl}&pstatus=true&transaction_id=${response.razorpay_payment_id}`;
                window.location.replace(tempUpdatedUrl);
              }
            },
            "prefill": {
              "name": tempData.name,
              "email": tempData.email,
              "contact": tempData.mobile
            },
            "notes": {
              "app_name" : "Trade store",
              "customer_name" : tempData.name,
              "product_name" : this.props.details.product_name,
              "vendor" : this.props.details.vendor_name,
              "product_id": this.props.details.id
            },
            "theme": {
              "color": "#065CAB"
            }
          };
    
          let rzp = new window.Razorpay(options);
          rzp.open();
        }
      }
      else{
        this.setState({loading: false})
      }
    }.bind(this))
  }

  paymentStatus(tempStatus, tempTranID){
    var temptotal = Number(this.state.total);
    var url = devconfig.v3+"/product/complete-payment";
    var data = JSON.stringify({
      "purchased_id": sessionStorage.getItem("purchased_id"),
      "transaction_id" : tempTranID,
      "amount": temptotal
    })
    APIPost(url, data).then( function(response){
      if(response.status === "ok"){
        // var tempRedirect = this.props.details.redirection_url ? this.props.details.redirection_url : null;
        //   if(tempRedirect != null){
        //     window.open(tempRedirect , '_blank') 
        //   }
        // setTimeout(
        //  async function() {
        //     var tempUrl = window.location.href.split('pstatus')[0];
        //     window.location.replace(tempUrl);
        //   }.bind(this), 3000
        // );

         
      }
    }.bind(this))
  }

  close(){
    
   
    var tempRedirect = this.props.details.redirection_url ? this.props.details.redirection_url : null;
          if(tempRedirect != null){
            window.open(tempRedirect , '_blank') 
          }
           var tempUrl = window.location.href.split('pstatus')[0];
    window.location.replace(tempUrl);
  }

  render() {
    const tempTrialType = {1: "Days" , 2: "Month", 3: "Year"}
   
    const RenderBody = ()=>{
      switch(this.props.type) {
        case 1:{
          return  (
              <div className="modal-body">
                <p>Do you want to try ‘{this.props.details.product_name}’ for free?</p>
                <p className="pt-3">Regular Price:  ₹{this.props.planData.price} / {this.props.planData.price_validity_text} for normal purchase.</p>
                <p className="e-offer-price">Trade Store Price:  <span>Free</span> for aliceblue customers</p>
              </div>
            )
        };
        case 2:{
          return  (
              <div className="modal-body">
                <p>Do you want to try ‘{this.props.details.product_name}? The trial period will be {this.props.details.trail_validity}/{tempTrialType[this.props.details.trial_type]}.</p>
              </div>
            )
        };
        case 3:{
          return  (
              <div className="modal-body">
                <p>Do you really want to buy 33 ‘{this.props.details.product_name} - {this.props.planData.name}’?</p>
                <p className="pt-3">Regular Price:  ₹{this.props.planData.price} / {this.props.planData.price_validity_text} for normal purchase.</p>
                <p className="e-offer-price">Trade Store Price:  <span>₹{this.props.planData.offer_price} </span> / {this.props.planData.offer_validity_text} for aliceblue customers</p>
              </div>
            )
        };
        case 4:{
          return  (
              <div className="modal-body">
                <p>Do you really want to renew ‘{this.props.details.product_name} - {this.props.planData.name}’?</p>
                <p className="pt-3">Regular Price:  ₹{this.props.planData.price} / {this.props.planData.price_validity_text} for normal purchase.</p>
                <p className="e-offer-price">Trade Store Price:  <span>₹{this.props.planData.offer_price} </span> / {this.props.planData.offer_validity_text} for aliceblue customers</p>
                
              </div>
            )
        }
      }
    }
    return (

      <div className="modal fade e-product-modal" id="e-product-purchase" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="eVideoModalTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">

          <div className="modal-content">
            {
              !this.state.paymentUpdated ?
                <React.Fragment>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Confirm Purchase</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  { RenderBody() }

                  <div className="modal-footer text-right position-relative">
                    {
                      this.props.type == 1 || this.props.type == 2 ?
                        null
                      :
                      <React.Fragment>
                      {
                        this.state.priceLoading ?
                        <div className="text-right e-modal-total-price">
                        
                         

                          Total Price: <img src={loader} className="ml-2 e-modal-loader"/> 
                        
                        
                          
                          {/**Total Price: ₹{this.props.planData.offer_price.toFixed(2)} (Inc. GST)*/}
                        </div>
                        :
                        <div className="text-right e-modal-total-price">
                        
                         

                          Total Price: ₹{this.state.total.toFixed(2)} (Inc. GST)
                        
                        
                          
                          {/**Total Price: ₹{this.props.planData.offer_price.toFixed(2)} (Inc. GST)*/}
                        </div>
                      }

                      </React.Fragment>
                       
                    }
                    
                    <span className="e-link-txt mr-3" data-dismiss="modal" aria-label="Close">Cancel</span>
                    {
                      this.state.priceLoading ?
                      <PrimaryblueButton name= "Yes, Proceed" disabled={true}/>
                      :
                      <PrimaryblueButton name={this.state.loading ? "Loading..." : "Yes, Proceed"} handleClick={this.success.bind(this)}/>
                    }
                    
                  </div>
                </React.Fragment>
              :
                <React.Fragment>
                  {
                    this.state.paymentUpdated == 1 ?
                      <div className="modal-body text-center p-4">
                        <img src={PaymentSuccess} />

                        <h5 className="pt-3">{this.props.type == 1 || this.props.type == 2 ? "Purchased successful" : "Payment Successful"}</h5>
                        <p className="pt-3">The product will be available within 24 hours in your Aliceblue account. You will get an email notification when it gets activated.</p>

                        <PrimaryblueButton name="View Product" handleClick={this.close.bind(this)}/>
                        <PrimaryOutlineButton className="e-purchase-revw-btn" handleClick={()=>{window.location.href='/marketplaceDetails?id=27&tab=2'}} name="Add Review"/>
                     { /*  {
                                               this.props.type == 1 || this.props.type == 2 || this.props.pmethod ?
                                                 <PrimaryblueButton name="OK" handleClick={this.close.bind(this)}/>
                                               :
                                                 null
                                             }*/}
                        
                      </div>
                    :
                      null
                  }

                  {
                    this.state.paymentUpdated == 2 ?
                      <div className="modal-body text-center p-4">
                        <img src={PaymentFail} />
                        <h5 className="pt-3">Payment Failed</h5>
                        <p className="pt-3">You can now access and provide feeback of the product from the purchase history in your profile.</p>
                        <PrimaryblueButton name="OK" handleClick={this.close.bind(this)}/>
                      </div>
                    :
                      null
                  }
                </React.Fragment>
            }
            
          </div>
        </div>
      </div>
    );
  }
}

