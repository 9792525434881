import React from 'react';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import components
import Input from '../../components/formComponents/input';
import PrimaryblueButton from '../../components/buttons/primaryBlueButton';

// import assets
import facebook from '../../assets/images/footerIcons/facebook.svg';
import twitter from '../../assets/images/footerIcons/twitter.svg';
import linkedin from '../../assets/images/footerIcons/linkdin.svg';
import whatsapp from '../../assets/images/footerIcons/whatapp.svg';
import copy from '../../assets/images/footerIcons/icon.svg';

export default class ReferEarn extends React.Component{
	constructor(props){
		super(props);
		this.state= {
			ABid: this.props.partner_id ? this.props.partner_id : null ,
			generated: false,
			refcode: null,
			prname: null,
			clear: false,
			linkcopy: false,
			link: ""
		}
	}
	componentDidMount(){
		GetURLVar().then(function(query){ 
			this.setState({id: query.id});
			this.setState({prname: query.product})
		}.bind(this));
	}

	refferal = () =>{
		this.setState({generated: false});
		this.setState({clear: true});
		if(localStorage.getItem("partner_token")){
			var url = devconfig.partnerV1+"/product/referralcode";	
		
			var data = JSON.stringify({
			"id": Number(this.props.productId),
			"alice_blue_id" : this.state.ABid
		})
		}else{
			var url = devconfig.v3+"/product/referralcode";
		var data = JSON.stringify({
			"id": Number(this.props.productId),
			"alice_blue_id" : this.state.ABid
		})
		}
		 APIPost(url, data).then(function(response){
		 	 if(response.status === "ok"){
		}
		else{
        this.setState({ dataLoading: false});
      }
        this.setState({refcode: response.referral_code});
        localStorage.setItem("ts_ref", response.referral_code);
        localStorage.setItem("ts_id", Number(this.props.productId));
        this.generate(this.state.refcode);
			}.bind(this))
		 
	}
	
	generate= (tempId) =>{
		 // encodeURIComponent(siteURL+queryString);
		 var copylink = 'https://tradestore.aliceblueonline.com/marketplaceDetails?ts_refcode='+tempId+'&id='+this.props.productId
		var temoLink= encodeURIComponent('https://tradestore.aliceblueonline.com/marketplaceDetails?ts_refcode='+tempId+'&id='+this.props.productId)
		this.setState({ generated: true, link: temoLink , clink: copylink});
	}

	clear =()=>{
		this.setState({ generated: false, ABid: "",  link: ""});
	}

	renderLink =() =>{
		if (this.state.generated )
		return<h5 className="mt-3 e-generated-head">Link Generated</h5>
	}
	renderButton = () =>{
		if (this.state.generated){
			return(
			<div className="row">
			<div className="col-lg-12">
			 <button  className="mt-1 mr-3 e-copy-btn" onClick={() => {
							this.setState({ linkcopy: true});
							navigator.clipboard.writeText(this.state.clink);
							setTimeout(
						    function() {
						      this.setState({ linkcopy: false });
						    }
						    .bind(this),
						    1500
							);
						}
					}><img src={copy} alt="icon" className="e-footer-icon"/></button>
	
				<a className="mr-3 e-ftricon-link mt-1" href={"https://www.facebook.com/sharer/sharer.php?u="+this.state.link} target="blank"><img src={facebook} alt="icon" className="e-footer-icon"/></a>  
				<a className="mr-3 mt-1 e-ftricon-link" href={"https://twitter.com/intent/tweet?url="+this.state.link} target="blank"><img src={twitter} alt="icon" className="e-footer-icon"/></a>
				<a className="mr-3 mt-1 e-ftricon-link" href={"http://www.linkedin.com/shareArticle?mini=true&url="+this.state.link} target="blank"><img src={linkedin} alt="icon" className="e-footer-icon"/></a>	
				<a className="mr-3 e-ftricon-link" href={"https://api.whatsapp.com/send?text="+this.state.link} target="blank"><img src={whatsapp} alt="icon" className="e-footer-icon"/></a>
			</div>	
			</div>	
								)
	}	}
	render(){
		return(
			<div className="card border-0 e-refer-card mt-4">
				<hr className="e-form-rule"/>
				<h5 className="e-prdtdtls-smallcase-h5">Refer & Earn</h5>
				<p className="e-earn-p">
					Refer this product with your friends and family; earn 10% of product purchase amount as referral bonus when your friends or family purchase it..
				</p>
				<div className="position-relative e-refer-input-wrap">
					<input type="text" className="e-text" placeholder="Enter Aliceblue Client ID*" readOnly = {true} value={this.state.ABid} onChange={(e) => this.setState({ABid: e.target.value})}/>
					<div className="text-left" style={{color: "red"}}>{this.state.nameError}</div>
					{
						!this.state.generated ?
							<React.Fragment>
								{
									this.state.ABid ?
										<PrimaryblueButton name="Generate Link" handleClick={this.refferal}/>
									:
										<PrimaryblueButton name="Generate Link" disabled={true} handleClick={this.refferal}/>
								
								}
							</React.Fragment>
						:
							<PrimaryblueButton name="Clear" handleClick={this.clear}/>
					}
					
				</div>
				{this.renderLink()}
				{
					this.state.link.length ?
						<p className="e-earn-link" id="earn-link">{this.state.clink}</p>
					:
						null
				}
				
				{this.renderButton()}

				{
					this.state.linkcopy ?
						<span className="e-link-copied e-link-copied-s">Link copied</span>
					:
						null
				}
			</div>
		)
	}
	
}