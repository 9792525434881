import React from 'react';
import { Link } from 'react-router-dom';

/*Import Services*/
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';
import Pagination from '../../../components/pagination/Pagination';


// import components
import ApiLoader from '../../../components/loader/apiLoader';
import NothingFound from '../../../components/globel/nothingFound';

export default class PaymentHist extends React.Component{
	constructor(props){
		super(props);
		this.state={
			pageNum: 1,
			data: null,
			dataLoading: true
		}
	}
	componentDidMount(){
		this.getPaymetHist();
	}

	getPaymetHist=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.v3+"/product/payment-history";
    var data = JSON.stringify({
      "page_num": this.state.pageNum,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        window.scrollTo({top: 0, behavior: 'smooth'});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		this.getPaymetHist();
	}



	render(){
	if(this.state.dataLoading){
		return(
			<div className="col-lg-12 text-center mt-5">
				<ApiLoader/>
			</div>
		);
	}
	else if(!this.state.data.purchased_data.length){
		return(
			<div className="col-lg-12 text-center mt-5">
				<NothingFound text="No product purchased yet!"/>
			</div>
		);
	}
	else{
		return(
			<React.Fragment>
			{
				this.state.data.purchased_data.map((item, index) => {
				let path = 'marketplaceDetails?id='+item.product_id+'&product='+(item.product_name).replace(/\s+/g, '-');
	      return(
	      		<div className="col-lg-12 mb-4">
							<div className=" card border-0 e-payment-card">
								<div className="row">
									<div className="col-lg-12">
										<h5>&#8377; {item.total}</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-10 text-left">
										<p className="e-payment-paid">Paid for <Link className="e-underline" to={path}>{item.product_name}</Link><span className="e-expire pl-4">Expire on: <b>{item.expired_on}</b></span></p>
									</div>
									<div className="col-lg-2 text-right">
										<p className="e-payment-p">Paid on: <b>{item.purchased_on}</b></p>
									</div>
								</div>
							</div>
						</div>
					);
				})
			}

			<div className="mt-3 col-12">

					{
	        	this.state.data && this.state.data.no_of_pages > 1 ?
	        		<Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
	        	:
	        		null
	        }

        </div>

			</React.Fragment>
		)
	}

}
}

