import React from 'react';


/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';
import GetURLVar from '../../services/getParams/get-url';
import UsrHeader from '../../components/navigation/userHeader';

// import assets
import userBg from '../../assets/images/userProfile/userprobg.svg';
import userBg1 from '../../assets/images/userProfile/userprobg1.svg';
import Union from '../../assets/images/userProfile/Union.svg'
// import components
import PurchaseHist from '../../container/usrprofile/purchaseHist/PurchaseHist';
import PaymentHist from '../../container/usrprofile/paymentHist/PaymentHist';
import Favorities from '../../container/usrprofile/Favorities';
import WalletHistory from '../../container/usrprofile/walletHist';

export default class UserProfile extends React.Component{
	constructor(props){
		super(props);
		this.state={
			tabIndex: 1,
			data: null,
			dataLoading: true,
			walletClick: false,
		}
	}
	componentDidMount(){
		GetURLVar().then(function(query){

			if (query.type == 1){
				this.setState({tabIndex: 1});
			}
			if (query.type == 2){
				this.setState({tabIndex: 2});
			}
			if (query.type == 3){
				this.setState({tabIndex: 3});
			}
			if (query.type == 4){
				this.setState({walletClick: true})
			}
		}.bind(this));

		this.getProfile();
	}

	getProfile=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.v3+"/auth/profile";

    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});

			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
	usrTab = (e) => {
		this.setState({ tabIndex: e });
	}
	renderTab(){
		if (this.state.tabIndex == 1){
			return <PurchaseHist/>
		}
		if (this.state.tabIndex == 2){
			return <PaymentHist/>
		}
			if (this.state.tabIndex == 3){
			return <Favorities/>
		}
	}
	render(){
		return(
			<React.Fragment>
			{
				  !this.state.walletClick?
				<React.Fragment>
				{
				this.state.data ?
				<React.Fragment>
					<section className="e-user-pro-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<h1 className="e-user-name">{this.state.data.name}</h1>
									<p className="e-user-dtls"><span className="e-userdlts-label">Email:</span> {this.state.data.email}</p>
									<p className="e-user-dtls"><span className="e-userdlts-label">ID:</span> {this.state.data.alice_blue_id}</p>
								</div>
								<div className="col-lg-4 text-left">
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6 col-6">
											<div className="e-user-card text-center"><p>Owned products</p> <h1 className="e-user-card-h">{this.state.data.owned_products}</h1> </div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-6">
											<div className="e-user-card  e-wallet-click" onClick={()=>{this.setState({walletClick: true})}}><p className="ml-5">Wallet</p><h1 className="e-user-card-h text-center"><span>&#8377;</span>{this.state.data.wallet_data.balance}</h1> </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="container e-usr-tab-sec pt-4 mt-2">
						<div className="col-lg-12 col-md-12  mt-2">
							<div className="row" usrTab={() => this.usrTab()}>
								<button className={this.state.tabIndex == 1 ?"mr-3 e-tablinks e-tabbtn-active" : "mr-3 e-tablinks" } id="purchase-tab" onClick={() => this.usrTab(1)}>Purchased Products</button>
								<button className={this.state.tabIndex == 2 ?"mr-3 e-tablinks e-tabbtn-active" : "mr-3 e-tablinks" } id="payment-tab" onClick={() => this.usrTab(2)}>Payment History </button>
								<button className={this.state.tabIndex == 3 ?"mr-3 e-tablinks e-tabbtn-active" : "mr-3 e-tablinks" } id="fav-tab" onClick={() => this.usrTab(3)}>Favorites</button>
							</div>
						</div>
						<div className="row mt-5">
							{this.renderTab()}
						</div>
					</div>
				</React.Fragment>
			:
			null
		}

			</React.Fragment>
			:
			<WalletHistory/>
		}
		</React.Fragment>
		)
	}
}