/*Package importing*/
import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';

/*Assets and Components*/
// import InputBox from '../../components/common/inputBox';
// import SelectBox from '../../components/common/selectBox';
import firebase from 'firebase';
import database from 'firebase';
import chatUserAvatar from '../../assets/images/chat-user-avatar.svg';

class Chatbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      moreClick: false,
      convoId: null,
      chats: [],
      reply: "",
      initalConvoId: 0,
      userData: {},
      receiver_id: "",
      sender_id: "",
      sender_image: "",
      receiver_image: "",
      sender_name: "",
      receiver_name: "",
      textAreaReset: true,
      messageLoading: true,
      showMore: false
    }
  }

  componentDidMount() {

    var tempUserData = JSON.parse(localStorage.getItem("userData"));

    var supportType= "General Support"
    if(this.props.prodChat){

      this.getMessage(this.props.prodData.owner_firebase_uid ? this.props.prodData.owner_firebase_uid : "admin"+"_"+tempUserData.firebase_uid);

      supportType = this.props.prodData.productName + " Support";

      this.setState({
        receiver_id: this.props.prodData.owner_firebase_uid ? this.props.prodData.owner_firebase_uid : "admin",
        sender_id: tempUserData.firebase_uid,
        sender_image: tempUserData.image,
        receiver_image: this.props.prodData.logo,
        sender_name: tempUserData.name,
        receiver_name: this.props.prodData.title,

        convoId: this.props.prodData.owner_firebase_uid ? this.props.prodData.owner_firebase_uid : "admin"+"_"+tempUserData.firebase_uid,
        vendor_id: this.props.prodData.owner_firebase_uid ? this.props.prodData.owner_firebase_uid : "admin",
        productName: this.props.prodData.owner_firebase_uid ? this.props.prodData.product_name : "General Support",
        last_messageBy: tempUserData.name,
        userName: tempUserData.name
      });
    }
    else{
      this.getMessage(this.props.item.convoId);
      this.setState({
        receiver_id: this.props.item.sender_id,
        sender_id: tempUserData.firebase_uid,
        sender_image: this.props.item.sender_image ? this.props.item.sender_image : "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
        receiver_image: "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
        sender_name: tempUserData.name,
        receiver_name: this.props.item.sender_name,

        convoId: this.props.item.convoId,
        vendor_id: this.props.item.vendor_id,
        productName: this.props.item.productName,
        last_messageBy: this.props.item.last_messageBy,
        userName: this.props.item.userName
      });
    } 
  
    this.setState({initalConvoId: this.props.item.convoId, userData: tempUserData});
  }

  getMessage(tempId){
    firebase.database().ref("messages").child(tempId).orderByChild("Time").on('value', function(snapshot) {
      if(snapshot.val()){
        var data = [];

        snapshot.forEach(snap => {
          var chat = snap.val();
          data.push(chat);
        });

        this.setState({chats: data, messageLoading: false});

        setTimeout(
          function() {
            var temp = $('.e-chat-body-inner').prop("scrollHeight") + 300 ;

            $('.e-chat-body').animate({scrollTop: temp}, 500);
          }
          .bind(this), 200
        );

      }
      else{
        this.setState({messageLoading: false});
        $('.e-chat-body').animate({scrollTop: $('.e-chat-body-inner').prop("scrollHeight") + 100}, 100);
      }
    }.bind(this));
  }
  

  moreClick(){
    this.setState({moreClick: !this.state.moreClick});
  }

  dateToFromNowDaily( myDate ) {
    // get from-now for this date
    var fromNow = moment( myDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  }

  clearTicket(){
    this.closeChat();
  }

  async closeChat(){
    this.setState({messageLoading: true});

    var timestamp = new Date().getTime();
    var newConvoId = this.state.convoId + "_closed_" + timestamp;

    var chatRef = firebase.database().ref("chats").child(this.state.sender_id).child(this.state.convoId);
    var chatRefAdmin = firebase.database().ref("chats").child("admin").child(this.state.convoId);

    var newChatRefUser = firebase.database().ref("chatsClosed").child(this.state.sender_id).child(newConvoId);
    var newChatRefAdmin = firebase.database().ref("chatsClosed").child("admin").child(newConvoId);

    var convoRef = firebase.database().ref("messages").child(this.state.convoId);
    var newMessageRef = firebase.database().ref("messages").child(newConvoId);
    
    await chatRef.update({
      closed: true,
      closedOn: moment().format(),
      closedTimestamp: timestamp
    })

    await chatRefAdmin.update({
      closed: true,
      closedOn: moment().format(),
      closedTimestamp: timestamp
    })

    await chatRef.once("value", (snapshot)=>{
      newChatRefUser.update(snapshot.val())
      newChatRefAdmin.update(snapshot.val())
    })

    await chatRef.remove()
    await chatRefAdmin.remove()

    var convoRef = firebase
            .database()
            .ref("messages")
            .child(this.state.convoId);

    var newMessageRef = firebase
            .database()
            .ref("messages")
            .child(newConvoId);

    await convoRef.once("value", (snapshot)=>{
        newMessageRef.update(snapshot.val())
    })

    await convoRef.remove()  

    this.setState({messageLoading: false});
    this.props.close();            
  }


  replyChange(e){
    this.setState({reply: e.target.value});
  }

  keyDownEvent(e){
    if(e.key == "Enter" && e.shiftKey ){
      
    }
    else{
      if(e.key == "Enter"){
        if(this.state.reply.length){
          this.sendMessage(this.state.reply);
          this.setState({textAreaReset: !this.state.textAreaReset});
        }
      }
    }
  }

  

  sendMessage(tempText){
    var tempLocal = JSON.parse(localStorage.getItem("userData"));
    var tempClientId = "Google login"
    if(tempLocal.alice_blue_id){
      var tempClientId = tempLocal.alice_blue_id;
    }

    this.setState({reply: ""});

    var messageRef = firebase.database().ref("messages").child(this.state.convoId);
    var chatsRefUser = firebase.database().ref("chats").child(this.state.sender_id).child(this.state.convoId);
    var chatsRefAdmin = firebase.database().ref("chats").child("admin").child(this.state.convoId);

    let time = firebase.database.ServerValue.TIMESTAMP;
    let reverseTime = 0 - Number(new Date().getTime());

    chatsRefUser.update({
      last_message: tempText,
      last_messageBy: this.state.last_messageBy,
      userName: this.state.userName,

      receiver_id: this.state.receiver_id,
      sender_id: this.state.sender_id,
      sender_image: this.state.sender_image,
      receiver_image: this.state.receiver_image,
      sender_name: this.state.sender_name,
      receiver_name: this.state.receiver_name,

      vendor_id: this.state.vendor_id,
      productName: this.state.productName,

      updated: time,
      reverseTime: reverseTime,
      seen: true,
      convoId: this.state.convoId,
      deleted: false,
      createdAt: String(new Date()),
      alice_blue_id: tempClientId,
    });

    chatsRefAdmin.update({
      last_message: tempText,
      last_messageBy: this.state.last_messageBy,
      userName: this.state.userName,

      receiver_id: this.state.receiver_id,
      sender_id: this.state.sender_id,
      sender_image: this.state.sender_image,
      receiver_image: this.state.receiver_image,
      sender_name: this.state.sender_name,
      receiver_name: this.state.receiver_name,

      vendor_id: this.state.vendor_id,
      productName: this.state.productName,
      
      updated: time,
      reverseTime: reverseTime,
      seen: false,
      convoId: this.state.convoId,
      deleted: false,
      createdAt: String(new Date()),
      alice_blue_id: tempClientId,
    });

    messageRef.push({
      last_messageBy: this.state.last_messageBy,
      userName: this.state.userName,

      created: time,
      reverseTime: reverseTime,
      message: tempText,
      sender_id: this.state.sender_id,
      convoId: this.state.convoId,
      vendor_id: this.state.vendor_id,
      productName: this.state.productName,
      alice_blue_id: tempClientId,
      _id: 1,
      text: tempText,
      createdAt: String(new Date()),
      user: {
        _id: 1,
        name:  this.state.userName,
        avatar: "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
        alice_blue_id: tempClientId
      }
    });
  }

  moreClick(){
    this.setState({showMore: !this.state.showMore});
  }

  render(){
    if(this.state.messageLoading){
      return (
        <div className="e-chat-wrap e-chat-loading">
          
        </div>
      );
    }
    else{
      return (
        <div className="e-chat-wrap">
          
          {
            this.props.prodChat ? 
              null
            :
              <span className="e-chat-more-icon" onClick={this.moreClick.bind(this)}></span>
          }
          
          {
            this.state.showMore ?
              <span className="e-chat-more-wrap" onClick={this.clearTicket.bind(this)}> 
                Clear Ticket
              </span>
            :
              null
          }
          
          {
            this.state.chats.length ?
              <div className="e-chat-body">
                <div className="e-chat-body-inner">
                {
                  this.state.chats.map(function(item, index){
                    
                    var nameSplit = item.user.name.split(" ");
                    var placeholder = "";
                    if(nameSplit.length == 1){
                      placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
                    }
                    else{
                      placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
                    }
                    if(item._id === 1){
                      return(
                        <div className="e-chat-item e-chat-user d-flex flex-row-reverse" key={index}>
                          {
                            this.state.sender_image ?
                              <img className="e-chat-pic" src={this.state.sender_image}/>
                            :
                              <span className="e-chat-pic">{placeholder}</span>
                          }
                          
                          <span className="e-chat-message">{item.text}</span>
                          {
                            index === this.state.chats.length-1 ?
                              <div className="e-chat-time">{this.dateToFromNowDaily(item.createdAt) + " " + moment(item.createdAt).format("HH:mm")}</div>
                            :
                              null
                          }
                        </div>
                      )
                    }
                    else{
                      return(
                        <div className="e-chat-item e-chat-admin" key={index}>
                          <span className="e-chat-pic"></span>
                          <span className="e-chat-message">{item.text}</span>
                          {
                            index === this.state.chats.length-1 ?
                              <div className="e-chat-time">{this.dateToFromNowDaily(item.createdAt) + " " + moment(item.createdAt).format("HH:mm")}</div>
                            :
                              null
                          }
                        </div>
                      );
                    }
                    
                  }.bind(this))
                }
                </div>
              </div>
          :
            <div className="e-chat-body-inner">
              <div className="e-chat-item e-chat-admin" >
                <span className="e-chat-pic"></span>
                <span className="e-chat-message">Got any questions? I'm happy to help.</span>
                
              </div>
            </div>
          }
          
          {
            this.props.item.closed ?
              <div className="e-chat-footer">
                <p className="e-closed-txt">This ticket closed on {this.dateToFromNowDaily(this.props.item.closedOn) + " " + moment(this.props.item.closedOn).format("HH:mm")}</p>
              </div>
            :
              <div className="e-chat-input-wrap">
                {
                  this.state.sender_image ?
                    <img className="e-user-avatar" src={this.state.sender_image} alt="User Profile" />
                  :
                    <img className="e-user-avatar" src={chatUserAvatar} alt="User Profile" />
                }
                
                <input className="e-chat-input" placeholder="Type a message" type="text" value={this.state.reply} onChange={this.replyChange.bind(this)} onKeyDown={this.keyDownEvent.bind(this)} />
                
                {/*
                  this.state.textAreaReset ?
                    <textarea row="1" className="e-chat-input" placeholder="Type a message" type="text" value={this.state.reply} onChange={this.replyChange.bind(this)} onKeyDown={this.keyDownEvent.bind(this)} />
                  :
                    <textarea row="1" className="e-chat-input" placeholder="Type a message" type="text" value={this.state.reply} onChange={this.replyChange.bind(this)} onKeyDown={this.keyDownEvent.bind(this)} />
                */}
              </div>
            } 
          </div>
      );
    }
  }
}

export default Chatbox;
