import React from 'react';
import $ from 'jquery';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 
// import components
import Links from '../../components/navigation/Links';
import Pagination from '../../components/pagination/Pagination';
import AvgReview from '../starRating/avgReview';
// import asstes
import reviewAvtar from '../../assets/images/marketplaceDetails/reviewAvtar.svg';
import onestar from '../../assets/images/rating/one.svg';
import twostar from '../../assets/images/rating/two.svg';
import threestar from '../../assets/images/rating/three.svg';
import fourstar from '../../assets/images/rating/four.svg';
import prevarrow from '../../assets/images/pagination/prev.png';
import nextarrow from '../../assets/images/pagination/next.svg';

export default class  ReviewCard extends React.Component{

	constructor(props){
		super(props);
		this.state={
			id: null,
			mark: null,
		}
	}
	componentDidMount(){
		this.setState({mark: this.props.data.marked_helpful})
	}
	markHelpful(e){
		var url = devconfig.v3+"/review/mark-helpful";
		var data = JSON.stringify({
			"id": parseInt(e)
		})
		 APIPost(url, data).then(function(response){
		 	 if(response.status === "ok"){
        		this.setState({message: response.message});
        		this.props.reload();

			}
      else{
        this.setState({ dataLoading: false});
      }
      
    }.bind(this))
	}
		renderRating(e){
			switch(e){
			case 1:
				return onestar;
				break;
			case 2:
				return twostar;
				break;
			case 3:
				return threestar;
				break;
			case 4:
				return fourstar;
				break;
		}
		}
		 readMore(){
		$('.e-review-content').toggleClass('e-readmore-p');
		var replace = $('.e-review-content').hasClass("e-readmore-p") ? "Read Less" : "Read More";
		$('.e-read-more').text(replace);
	}
	render(){
		return(
			<React.Fragment>
				<div className="card border-0 p-3 mt-4 e-review-card" >	
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2 col-2">
							<img className=" e-reviewer" src={this.props.data.image} alt="user image"/>
  					</div>
	  				<div className="col-lg-10 col-md-10 col-sm-10 col-10">
  						<h5 className="e-reviewer-name">{this.props.data.name} </h5>
  						<div className="d-flex justify-content-between">
  							<div>
  								<p className="e-review-date m-0">{this.props.data.date}</p>
  							</div>
  							<div className="e-review-s">
  								<AvgReview rating={this.props.data.rating}/>
  							</div>
   						 </div>
   					</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
    					<p className=" e-review-content">{this.props.data.review}</p>
    					{
        				(this.props.data.review.length > 150)?
        					<a href="javascript:void();" className="e-read-more" onClick={()=>this.readMore()}>Read More</a>
        				: null
        			}
        					<button className="text-left e-review-mark" onClick={()=>this.markHelpful(this.props.data.id)}>
        					{!this.state.mark ?
        						"Mark helpful"
        						:
        						"Undo Mark helpful"
        					}</button>
        			
							
						</div>
					</div>
				</div>
			</React.Fragment>
	
		);
	}
}

