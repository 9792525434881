import React from 'react';

// import components
import PrimaryBlueButton from '../buttons/primaryBlueButton';
import PrimaryOutlineButton from '../buttons/primaryOutlineButton';

function SimilarProduct(props){

	var tempBgColor = "linear-gradient( to top, "+props.data.gradient_1+" , "+props.data.gradient_2+")";
	return(
			<div className="card e-marketDetails-card border-0">
				<div className="e-card-head e-sensi-hed p-3" style={{backgroundImage: tempBgColor}}>
				<div className="d-flex justify-content-between">
			        <img className="card-logo" src={props.data.logo} alt="Card image cap"/>
			        <img className="card-illustration" src={props.data.illustration} alt="Card image cap"/>
       			</div>
			  	{/*<img className="card-img-top" src={sensigroup} alt="Card image cap"/>*/}
			  </div>
			  <div className="card-body">
			    <h5 className="e-mrktplc-card-title">{props.data.title}</h5>
			    <p className="e-mrktplc-card-text">{props.data.description}</p>
			    <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; {props.data.price}</span> <span className="e-mrktplc-product-plan">per month</span></p>
			    {
          props.data.offer ?
            <p>
              <span className="e-mrktplc-card-title">Offer Price: </span> 
              <span className="e-mrktplc-card-title e-prdt-free-grn">
                {
                  props.data.offer_text.length && props.data.offer_text != "Free" && props.data.offer_text != "Free Trail" ?
                    <React.Fragment>&#8377; </React.Fragment>
                  :
                    null
                }
                {props.data.offer_text.length ? props.data.offer_text : props.data.offer_price}
              </span> 
              <span className="e-mrktplc-product-plan">
                {props.data.offer_validity_text.length ? " / per "+props.data.offer_validity_text : null}
              </span>
            </p>
          :
            null
        }
			    
			    <PrimaryBlueButton name="View More Details"/> 
			    <PrimaryOutlineButton name="Open an Account"/>
			  </div>
			</div>
	)
}

export default SimilarProduct;