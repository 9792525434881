import React from 'react';
// import { FaRegBookmark } from 'react-icons/fa';
import $ from 'jquery';
import moment from 'moment';
/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js';

// import components
import SliderWithNav from '../../components/slider/SliderWithNav';
import PrimaryblueButton from '../../components/buttons/primaryBlueButton';
import PrimarygrnButton from '../../components/buttons/primaryGreenButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import ReferEarn from '../../components/cards/ReferEarn';
import Support from '../../components/cards/Support';
import Banner from '../../components/banners/gettingStartBanner';
import Acordion from '../../components/globel/Acordion'
import Links from '../../components/navigation/Links';
import SelectBox from '../../components/formComponents/selectBox';
import TextArea from '../../components/formComponents/TextArea';
import Login from '../../components/loginSignup/Login';
import SignUp from '../../components/loginSignup/SignUp';
import PurchaseConfirmation from '../../components/modal/purchaseConfirmation';
import ApiLoader from '../../components/loader/apiLoader';
import VideoModal from '../../components/modal/VideoModal';
// import asseets
import cardarrow from '../../assets/images/home/cardarrow.svg';
import FreeSticker from '../../assets/images/marketplaceDetails/freesticker.svg';
import ConfirmAgreement from '../../components/modal/confirmAgreement';
import { Modal } from 'bootstrap';

export default class ProductDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			selectPlan: this.props.plan,
			productId: null,
			isFav: false,
			useWallet: false,
			wallet_balance: null
		}
	}

	async handleSelect(tempSort) {
		// alert(tempSort.value)
		var tempTrial = this.props.details.subscription_plans.filter(function (el) {
			return el.id == tempSort.value
		});
		await this.setState({ selectPlan: tempSort.value, selectPlanDetails: tempTrial[0] });
		// await this.props.reload();
	}

	componentDidMount = async () => {

		if (localStorage.getItem("tradestore_token")) {

			// var date =  moment().format('DD MMM yyyy');

			this.getBalance();
			this.setState({ localData: JSON.parse(localStorage.getItem("userData")) })
		}



		if (this.props.details) {
			var data = this.props.details;
			var date = new Date();
			// get next week for checking product expired or not
			var week_ahead = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7)

			var next = new Date(data.expired_on)
			await this.setState({ current: date, ex_date: next, week_ahead: week_ahead });
			await console.log(this.state.week_ahead)


			console.log(this.state.ex_date, this.state.week_ahead)

			var tempArry = [];
			data.subscription_plans.map((item, index) => {
				tempArry.push({ value: item.id, label: item.name });
			})

			// Type of purchase
			var tempType = 0;

			if (data.free && !data.via_external_link) {
				tempType = 1; // Free for AB Customers
			}
			else if (data.is_trail_available && !data.already_availed_trail && !data.via_external_link) {
				tempType = 2; // Product have trail & user didnt take yet
			}
			else if ((data.is_trail_available && data.already_availed_trail && !data.already_purchased && !data.via_external_link) || (!data.is_trail_available && !data.already_purchased && !data.via_external_link)) {
				tempType = 3; // Already purchased trail or need to purchase product
			}
			else if (data.already_purchased && !data.via_external_link) {
				tempType = 4; // Already purchased & looking for renew
			}
			else if (!data.free && data.via_external_link) {
				tempType = 5;
			}
			else if (data.free && data.via_external_link) {
				tempType = 6;
			}

			this.setState({
				productId: data.id,
				selectPlan: data.subscription_plans[0].id,
				selectPlanDetails: data.subscription_plans[0],
				isFav: data.is_favourite,
				subscriptions: tempArry,
				defaultSubscriptions: tempArry[0],
				purchaseType: tempType
			});

		}
	}

	getBalance = async () => {
		var url = devconfig.v3 + "/auth/wallet-balance";
		var temp = null;
		APIGet(url).then(async function (response) {
			if (response.status === "ok") {
				await this.setState({ wallet_balance: response.data.balance })

			}

		}.bind(this))

		// await alert(this.state.wallet_balance)
	}

	addFav = () => {
		var url = devconfig.v3 + "/product/manage-favourite";
		var data = JSON.stringify({
			"id": parseInt(this.state.productId),
		})
		APIPost(url, data).then(function (response) {
			if (response.status === "ok") {
				this.setState({ isFav: response.is_favourite });
			}

		}.bind(this))
	}

	openModal = () => {
		this.setState({ useWallet: true });
		$('#e-product-purchase').modal('toggle');
	}
	login() {
		var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
		$("body").append(overlaylogin);
		$('.e-login').addClass('e-login-fly');
	}

	showSignUp() {
		// var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
		// $("body").append(overlaylogin);
		// $('.e-signup').addClass('e-login-fly');
		// window.location.href(this.props.details.redirection_url)
	}


	closePurchaseModal() {
		this.props.reload();
	}

	render() {
		var url = "";
		var content = "";
		var Reg = "";
		const tempTrialType = { 1: "Day", 2: "Month", 3: "Year" }

		const RenderPurchaseTypeButton = () => {
			// alert(this.state.today)
			// alert(this.state.wallet_balance)
			switch (this.state.purchaseType) {
				case 1: {
					return (
						localStorage.getItem("tradestore_token") ?
							<span className="e-prdtld-ext e-width-full" data-toggle="modal" data-target="#e-confirm-purchase">Get It Free</span>
							:
							<a onClick={this.login} target="_blank" className="e-prdtld-ext"> Get It Free</a>
					);
				};
				case 2: {
					return (
						localStorage.getItem("tradestore_token") && this.props.details.price < this.state.wallet_balance ?
							<span className="e-prdtld-ext e-trial" data-toggle="modal" data-target="#e-confirm-purchase">Try Free Trial</span>
							:
							localStorage.getItem("tradestore_token") && this.props.details.price > this.state.wallet_balance ?
								<span className="e-prdtld-ext e-trial e-width-full" data-toggle="modal" data-target="#e-confirm-purchase">Try Free Trial</span>
								:
								<a onClick={this.login} target="_blank" className="e-prdtld-ext"> Try Free Trial</a>
					);
				};
				case 3: {
					return (
						localStorage.getItem("tradestore_token") && this.props.details.price < this.state.wallet_balance ?
							<span className="e-prdtld-ext" data-toggle="modal" data-target="#e-confirm-purchase">Buy Now</span>
							:
							localStorage.getItem("tradestore_token") && this.props.details.price > this.state.wallet_balance ?
								<span className="e-prdtld-ext e-width-full" data-toggle="modal" data-target="#e-confirm-purchase">Buy Now</span>
								:
								<a onClick={this.login} target="_blank" className="e-prdtld-ext"> Buy Now </a>
					);
				};
				case 4: {
					return (
						localStorage.getItem("tradestore_token") ?
							<button className="e-prdtld-ext e-trial e-width-full" data-toggle="modal" data-target="#e-confirm-purchase">Open {this.props.details.product_name}</button>
							:
							<a onClick={this.login} target="_blank" className="e-prdtld-ext"> Buy Now  </a>
					);
				};
				case 5: {
					return (
						localStorage.getItem("tradestore_token") && !this.props.details.free && this.props.details.via_external_link ?

							<a data-toggle="modal" data-target="#e-confirm-purchase" className="e-prdtld-ext e-width-full mt-1 e-extrnl-link">Buy Now</a>
							:
							<a onClick={this.login} target="_blank" className="e-prdtld-ext e-extrnl-link"> Buy Now </a>
					);
				};
				case 6: {
					return (
						localStorage.getItem("tradestore_token") ?
							<a data-toggle="modal" data-target="#e-confirm-purchase" className="e-prdtld-ext e-width-full e-extrnl-link">Get It Free</a>
							:
							<a onClick={this.login} target="_blank" className="e-prdtld-ext e-extrnl-link d-none">Get It Free</a>
					);
				};
			}
		}



		return (
			<React.Fragment>
				{
					this.props.details ?
						<section className="e-product-details">
							<div className="row">
								<div className="col-lg-7">
									{
										this.props.details.media_data ?
											<SliderWithNav media={this.props.details.media_data} video={this.props.details.video} />
											:
											null
									}

									<div className="e-prod-about-section">
										<hr className="e-form-rule" />
										<h5 className="e-prdtdtls-smallcase-h5">About {this.props.details.product_name}</h5>
										<p className="mt-4 e-prdtdtls-p">
											{this.props.details.about}
										</p>
									</div>
									<div className="e-prdtdtls-usp">
										<h6 className="mt-4">USP</h6>
										<p>{this.props.details.usp}</p>
										{/* <p className="mt-4">Want to know more?</p>
										<a href="/resources?type=blog" target="_blank" className="e-card-explore">Read Product Related Blogs <img src={cardarrow} className="e-card-arrow" /></a> */}
									</div>
									<div className="e-smallcase-bnft">
										<hr className="e-form-rule" />
										<h5 className="e-prdtdtls-smallcase-h5">{this.props.details.product_name} Benefits</h5>
										<ul className="pt-2">
											{
												this.props.details ?
													this.props.details.benefits.map((item, index) => {
														return (
															<li className="e-features-list mt-3" key={index}>{item}</li>
														);
													})
													:
													null
											}
										</ul>

									</div>


									{/*<div className="e-smallcase-bnft">
								<hr className="e-form-rule"/>
								<h5 className="e-prdtdtls-smallcase-h5">{this.props.details.product_name} Benefits</h5>
								<p className="mt-4">Want to know more?</p>
							</div>*/}
								</div>

								<div className="col-lg-5 col-md-8 col-sm-10">

									{/* {
										this.state.productId && localStorage.getItem("partner_id") ?
											<div className="mb-3">
												<ReferEarn productId={this.state.productId} partner_id={localStorage.getItem("alice_blue_id")} />
											</div>
											:
											null
									} */}
									<div className="card border-0 e-prdtdtls-card ">
										<hr className="e-form-rule" />
										<div className="row pl-3 d-flex justify-content-between">
											<h5 className="e-prdtdtls-smallcase-h5">Features</h5>
											{
												this.props.details.free ?
													<img src={FreeSticker} alt="image" />
													:
													null
											}

										</div>

										<ul>
											{
												this.props.details && this.props.details.subscription_plans ?
													this.props.details.subscription_plans.map((item, index) => {
														if (item.id === this.state.selectPlan) {
															return (
																item.features.map((item, key) => {
																	return (
																		item != "" ?
																			<li className="e-features-list mt-3" key={index}>{item} </li>
																			:
																			null
																	);

																})
															)

														}

													})
													:
													<p>data</p>
											}
										</ul>
										{
											this.state.defaultSubscriptions ?
												<SelectBox className="d-inline-block e-plan-select mt-3 text-left"
													options={this.state.subscriptions}
													selectchange={(tempVal) => this.handleSelect(tempVal)}
													defaultValue={this.state.defaultSubscriptions}
												/>
												:
												null
										}


										{
											this.props.details && this.props.details.subscription_plans ?
												this.props.details.subscription_plans.map((item, index) => {
													return (
														this.state.selectPlan == item.id ?
															<div className="e-prdtls-subplan mt-3 p-3">
																<div className="pb-2">
																	<span className="e-prdtdtls-bold">Regular Price:₹ {item.price_text} </span>
																	<span className="e-card-p"> / {item.price_validity_text}</span>
																</div>
																<div>
																	<span className="e-prdtdtls-bold">Trade Store Price: </span>
																	<span className="e-grn-span"> {this.props.details.free ? " Free for Aliceblue customers" : " ₹ " + item.offer_price}</span>
																	{
																		this.state.purchaseType == 1 ?
																			null
																			:
																			<span className="e-card-p"> / {item.offer_validity_text}</span>
																	}
																	{
																		!this?.props?.details?.already_purchased && this.state.purchaseType == 2 ?
																			<div>
																				<span className="e-card-p e-blue"> Free Trial Available / {this?.props?.details?.trail_validity} {tempTrialType.this?.props?.details?.trial_type}</span>
																			</div>
																			:
																			null
																	}

																	{
																		this.props.details.already_purchased ?
																			<div>
																				<span className="e-already-purchased mt-2">&#10003; Already Purchased</span>
																			</div>
																			:
																			null
																	}
																</div>
															</div>

															: null
													);
												})
												:
												null
										}
										{
											!localStorage.getItem('partner_token') ?
												<div className="row mt-2">
													<div className={localStorage.getItem("tradestore_token") ? "col-lg-12 mt-2" : "col-lg-12"}>
														{
															localStorage.getItem("tradestore_token") && !this.props.details.free && !this.props.details.via_external_link && !this.props.details.already_purchased && this.props.details.price < this.state.wallet_balance ?
																<PrimaryOutlineButton className="mr-3" name="Buy With Wallet" handleClick={this.openModal} data-toggle="modal" data-target="#e-product-purchase" />
																:
																!localStorage.getItem("tradestore_token") ?
																	<a href={this.props.details.redirection_url} target="_blank">
																		<PrimaryblueButton name="Click to know more" className="e-p-n-btn" />
																	</a>

																	:
																	null
														}


														{/*
													!this.props.details.already_purchased &&  !this.props.details.via_external_link  ?
													<PrimarygrnButton name="Buy Now"/>
													:
													null
												*/}
														{

															this.state.week_ahead >= this.state.ex_date && this.props.details.already_purchased && !this.props.details.free && this.props.details.purchased_once ?
																<React.Fragment>
																	<span className="e-prdtld-ext mr-2" data-toggle="modal" data-target="#e-product-purchase">Renew Now</span>
																	<PrimaryOutlineButton className="e-trail-puchase" handleClick={() => { window.open(this.props.details.redirection_url) }} name={"Open " + this.props.details.product_name} />
																</React.Fragment>
																:
																this.props.details.already_purchased && !this.props.details.free && this.props.details.already_availed_trail && !this.props.details.purchased_once ?
																	<React.Fragment>
																		<span className="e-prdtld-ext mr-2" data-toggle="modal" data-target="#e-product-purchase">Buy Now</span>
																		<PrimaryOutlineButton className="e-trail-puchase" handleClick={() => { window.open(this.props.details.redirection_url) }} name={"Open " + this.props.details.product_name} />
																	</React.Fragment>
																	:
																	this.props.details.already_purchased && this.props.details.free ?
																		<button className="e-prdtld-ext e-trial e-full-button" onClick={() => { window.open(this.props.details.redirection_url) }}>Open {this.props.details.product_name}</button>
																		:
																		<React.Fragment>
																			{RenderPurchaseTypeButton()}


																		</React.Fragment>

														}



														{/*{
													localStorage.getItem("tradestore_token") && !this.props.details.already_purchased && this.props.details.price < this.state.wallet_balance && !this.props.details.via_external_link && !this.props.details.free ?
														<span className={this.state.isFav ? "e-bookmark-fill e-bookmark-icn ml-4" : "e-bookmark-icn ml-4"}  onClick={this.addFav}></span>
													:
													localStorage.getItem("tradestore_token") && this.props.details.already_purchased ?
														<span className={this.state.isFav ? "e-bookmark-fill e-bookmark-icn" : "e-bookmark-icn "}  onClick={this.addFav}></span>
														:
													!localStorage.getItem("tradestore_token") ?
														<span className="e-bookmark-icn" onClick={this.login}></span>
														:
														null
												}*/}

														{
															localStorage.getItem("tradestore_token") ?
																<span className={this.state.isFav ? "e-bookmark-fill e-bookmark-icn" : "e-bookmark-icn "} onClick={this.addFav}></span>
																:
																<span className="e-bookmark-icn float-right mt-3" onClick={this.login}></span>
														}

													</div>
													{
														this.props.details.plan_expired && this.props.details.purchased_once ?
															<div className="col-lg-12 mt-3 e-dull-text">Previous plan expired on : {this.props.details.expired_on}</div>
															:
															null
													}
												</div>
												:
												null
										}
									</div>
									<div className="card mt-5 border-0 e-platform-card ">
										<hr className="e-form-rule" />
										<h5 className="e-prdtdtls-smallcase-h5">Platforms</h5>
										<p><span className="e-prdtdtls-bold">Developed by: </span><span className="e-blue-p text-capitalize"> {this.props.details.developer_name}</span></p>
										<p><span className="e-prdtdtls-bold">Type:</span> {this.props.details.application_types_data}</p>
										<p><span className="e-prdtdtls-bold">Released:</span> {this.props.details.release_date}</p>
										<p><span className="e-prdtdtls-bold">Last update:</span>{this.props.details.last_update}</p>
										<p><span className="e-prdtdtls-bold">Compaitable on:</span> {
											this.props.details && this.props.details.compatible_on ?
												this.props.details.compatible_on.map((item, index) => {
													return (
														item.name + ','
													);
												})
												:
												null
										}</p>
										{
											this.props.details.ios_install_count || this.props.details.ios_rating ?
												<p className="mt-4"><span className="e-prdtdtls-bold">App Store:</span> {this.props.details.ios_info}</p>
												:
												null
										}
										{
											this.props.details.android_install_count || this.props.details.android_rating ?
												<p><span className="e-prdtdtls-bold">Play Store:</span> {this.props.details.android_info}</p>
												:
												null
										}


									</div>
									{/* {
										this.state.productId && localStorage.getItem("tradestore_token") ?
											<ReferEarn productId={this.state.productId} partner_id={this.state.localData.alice_blue_id} />
											:
											null
									} */}

									<div className="mb-5">
										{
											this.props.details ?
												<Support data={this.props.details} />
												:
												null
										}
									</div>
								</div>
							</div>


							<div className="row e-smallcase-bnft">
								{
									this.props.details.instructions_1 && this.props.details.instructions_1.length ?
										<div className="col-lg-6 e-ac-direction ">
											<hr className="e-form-rule" />
											<h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are an Aliceblue Client</h5>
											<ul>
												{
													this.props.details ?
														this.props.details.instructions_1.map((item, index) => {
															return (
																<li className="e-features-list mt-3" key={index}>{item}</li>
															);
														})
														:
														null
												}
											</ul>
										</div>
										:
										null
								}

								{
									this.props.details.instructions_2 && this.props.details.instructions_2.length ?
										<div className="col-lg-6 e-ac-direction ">
											<hr className="e-form-rule" />
											<h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are not an Aliceblue Client</h5>
											<ul>

												{
													this.props.details ?
														this.props.details.instructions_2.map((item, index) => {
															return (
																Reg = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi,
																url = item.match(/(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi),
																content = item.replace(url, ''),
																Reg.test(item) ?

																	<li className="e-features-list mt-3" key={index}>{" "}
																		<a href={url} target="_blank">Click on this link{" "} </a> {content}
																	</li>
																	:
																	<li className="e-features-list mt-3" key={index}>{item}</li>


															);
														})
														:
														null
												}
											</ul>
										</div>
										:
										null
								}
							</div>



							<SignUp />
							{
								this.state.selectPlanDetails ?
									<PurchaseConfirmation
										type={this.state.purchaseType}
										planId={this.state.selectPlan}
										planData={this.state.selectPlanDetails}
										productId={this.state.productId}
										details={this.props.details}
										close={this.closePurchaseModal.bind(this)}
										ref={this.props.ref}
										pmethod={this.state.useWallet}
									/>
									:
									null
							}
							<ConfirmAgreement
								type={this.state.purchaseType}
								planId={this.state.selectPlan}
								planData={this.state.selectPlanDetails}
								productId={this.state.productId}
								details={this.props.details}
								close={this.closePurchaseModal.bind(this)}
								ref={this.props.ref}
								pmethod={this.state.useWallet}
								redirect={() => {
									if (this.state.purchaseType === 1 || this.state.purchaseType === 2 || this.state.purchaseType === 3) {
										var modal = new Modal(document.getElementById("e-product-purchase"))
										modal.show()

									} else if (this.state.purchaseType === 4) {
										window.open(this.props.details.redirection_url, "_blank");
									} else {
										if (this.props.details.external_link !== null) {
											window.open(this.props.details.external_link, "_blank");
										}
									}

								}} />

						</section>
						:
						null
				}

			</React.Fragment>
		)
	}
}
