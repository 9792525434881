import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import OneSignal from 'react-onesignal';

import firebase from 'firebase';
// import firebase from 'firebase';

import devconfig from './config/config.js';
import FirebaseConfig from './config/firebaseConfig.js';
import GetURLVar from './services/getParams/get-url.js';
import ChatWidget from './components/widget/chatlist.js';


import Header from './components/navigation/header';
import UsrHeader from './components/navigation/userHeader';
import PrtnrHeader from './components/navigation/partnerHeader'
import Footer from './components/navigation/footer';
import Signup from './components/loginSignup/SignUp';
import About from './container/about/about';
import Marketplace from './container/marketplace/marketplace';
import Resources from './container/resources/resources';
import Contact from './container/contact/contact';
import UserProfile from './container/usrprofile/UserProfile';
import MarketPlaceDetails from './container/marketplaceDetails/MarketPlaceDetails';
import Home from './container/home';
import WelcomeTrade from './components/banners/WelcomeTradeBanner';
import ResourceVideo from './container/resources/ResourceVideoDetails';
import VideoList from './container/resources/VideoListing';
import WebinarDetails from './container/resources/WebinarDetails';
import WebinarList from './container/resources/Webinar';
import BlogDetails from './container/resources/BlogDetails';
import BlogList from './container/resources/Blog';
import Callback from './container/other/callback.js';
import PartnerProfile from './container/partnerProfile/PartnerProfile';
import PaymentHist from './container/usrprofile/paymentHist/PaymentHist';
import Privacy from './container/terms/privacyPolicy';
import Empty from './container/empty';


/*Firebase init*/
firebase.initializeApp(FirebaseConfig);

class App extends React.Component {
  constructor(props) {
    console.log = function () { };
    console.error = function () { };
    console.warn = function () { };
    super(props);
    this.state = {
      openChatWidget: false,
      hideChatWidget: false,
      code: false
    }
  }

  componentDidMount() {

    // var tempToken = localStorage.getItem("firebase_token");
    // var tempValidate = sessionStorage.getItem("firebaseValidate");
    // var query = this.getUrlVars();
    // localStorage.setItem("queryData", JSON.stringify(query));

    // if(tempToken && !tempValidate){
    //   this.getFireBaseUID(tempToken);
    // }
    // GetURLVar().then(function(query){ 
    //   if(query.id){
    //     this.setState({hideChatWidget: true});
    //   }
    // });



    var tempToken = localStorage.getItem("firebase_token");
    var tempValidate = sessionStorage.getItem("firebaseValidate");
    // var query = this.getUrlVars();
    // localStorage.setItem("queryData", JSON.stringify(query));

    if (tempToken) {
      console.log("Inside firebase if")
      this.getFireBaseUID(tempToken);
    }

    //   GetURLVar().then(function(query){ 
    //   if(query.id){
    //     this.setState({hideChatWidget: true});
    //   }
    // });


  }

  getFireBaseUID(tempToken) {

    sessionStorage.setItem("firebaseValidate", true);

    firebase.auth().signInWithCustomToken(tempToken).catch(function (error) {
      if (error.code == "auth/invalid-custom-token") {
        this.logout();
      }
      var errorCode = error.code;
      var errorMessage = error.message;
    }.bind(this));
  }


  openChatWidget() {
    this.setState({ openChatWidget: !this.state.openChatWidget });
  }




  logout() {
    localStorage.clear();
    window.location.href = '/home';
  }


  renderHeader = () => {
    if (localStorage.getItem("tradestore_token")) {
      return (
        <React.Fragment>
          <UsrHeader name={localStorage.getItem("name")} />

          {
            !this.state.hideChatWidget && this.state.openChatWidget ?
              <ChatWidget close={this.openChatWidget.bind(this)} />
              :
              null
          }
        </React.Fragment>
      )
    }
    else if (localStorage.getItem("partner_id")) {
      return <PrtnrHeader />
    }
    else {
      return <Header />
    }

  }
  redirect = () => {

  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>

          {this.renderHeader()}
          {/*{this.redirect()}*/}
          {
            localStorage.getItem('tradestore_token') ?
              <React.Fragment>
                <Route path="/" component={Marketplace} exact />
                <Route path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/marketplace" component={Marketplace} />
                <Route path="/resources" component={Resources} />
                <Route path="/blogs" component={Resources} />
                <Route path="/contact" component={Contact} />
                <Route path="/signup" component={Signup} />
                <Route path="/profile" component={UserProfile} />
                <Route path="/empty" component={UserProfile} />
                <Route path="/partnerProfile" component={PartnerProfile} />
                <Route path="/marketplaceDetails" component={MarketPlaceDetails} />
                <Route path="/resourceVideo" component={ResourceVideo} />
                <Route path="/videoList" component={VideoList} />
                <Route path="/webinarDetails" component={WebinarDetails} />
                <Route path="/webinarList" component={WebinarList} />
                <Route path="/blogDetails" component={BlogDetails} />
                <Route path="/blogList" component={BlogList} />
                <Route path="/callback" component={Callback} />
                <Route path="/paymentHist" component={PaymentHist} />
                <Route path="/privacy-policy" component={Privacy} />
                <Footer />
              </React.Fragment>
              :
              <React.Fragment>
                <Route path="/" component={Home} exact />
                <Route path="/home" component={Home}/>
                <Route path="/marketplace" component={Home} exact/>
                <Route path="/about" component={About} />
                {/* <Route path="/marketplace" component={Home} /> */}
                <Route path="/resources" component={Resources} />
                <Route path="/blogs" component={Resources} />
                <Route path="/contact" component={Contact} />
                <Route path="/signup" component={Signup} />
                <Route path="/profile" component={UserProfile} />
                <Route path="/empty" component={UserProfile} />
                <Route path="/partnerProfile" component={PartnerProfile} />
                <Route path="/marketplaceDetails" component={MarketPlaceDetails} />
                <Route path="/resourceVideo" component={ResourceVideo} />
                <Route path="/videoList" component={VideoList} />
                <Route path="/webinarDetails" component={WebinarDetails} />
                <Route path="/webinarList" component={WebinarList} />
                <Route path="/blogDetails" component={BlogDetails} />
                <Route path="/blogList" component={BlogList} />
                <Route path="/callback" component={Callback} />
                <Route path="/paymentHist" component={PaymentHist} />
                <Route path="/privacy-policy" component={Privacy} />
                <Footer />
              </React.Fragment>
          }

         
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
