/*Package importing*/
import React, { Component } from 'react';
import moment from 'moment';
import firebase from 'firebase';
import database from 'firebase';

/*Import custom components and assets*/
import chatUserAvatar from '../../assets/images/chat-user-avatar.svg';
import chatAdminAvatar from '../../assets/images/support-avatar.svg';
import Chatbox from './chatbox';
import SelectBox from '../../components/formComponents/selectBox';
import ChatPlaceholder from '../../components/placeholder/chatPlaceholder';
import noDataFound from '../../components/placeholder/noDataFound';

class ChatWidget extends Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      allChats: true,
      activeMessage: "",
      prodChat: false,
      typeFilter: "chats",
      typeFilterDefault: { value: "chats", label: 'Open Chats' },
      prodSupport: false,
      messageLoading: true
    }
  }

  componentDidMount() {
    console.log("--- this.props.prodData ---");
    console.log(this.props.prodData);
    
    this.getFirebaseDB(this.state.typeFilter);

    if(this.props.prodSupport){
      this.setState({allChats: false, prodChat: true, prodSupport: true, prodName: this.props.prodData.product_name});

      if(this.props.prodData.product_name){
        this.setState({prodName: this.props.prodData.product_name});
      }
      else{
        this.setState({prodName: this.props.prodData.title});
      }
    }
    var tempUserData = JSON.parse(localStorage.getItem("userData"));
  }

  getFirebaseDB(tempTyp){
    this.setState({loading: true, messages: []});
    var tempUID = localStorage.getItem("uid");


    firebase.database().ref(tempTyp).child(tempUID).orderByChild("reverseTime").on('value', function(snapshot) {
      if(snapshot.val()){

        var data = [];
        this.setState({messages: []})
        snapshot.forEach(snap => {
          var chat = snap.val();
          chat["convoId"] = snap.key;
          data.push(chat);
        });
        this.setState({loading: false, messages: data, activeMessage: data[0], noChats: false, activeIndex:0, messageLoading: false});
      }
      else{
        this.setState({noChats: true, messageLoading: false});
      }
    }.bind(this));
  }

  dateToFromNowDaily( myDate ) {
    // get from-now for this date
    var fromNow = moment( myDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  }

  newChatClick(){
    var tempUserData = JSON.parse(localStorage.getItem("userData"));
    var tempAdmin = {
      convoId: "admin_"+tempUserData.firebase_uid,
      last_messageBy: tempUserData.name,
      productName: "General Support",
      receiver_id: "admin",
      receiver_image: "https://d1ippcqariu474.cloudfront.net/card/images/logo_2019-12-04_07_24_53.884802_2019-12-05_04:27:23.370668.png",
      receiver_name: "General Support",

      sender_id: tempUserData.firebase_uid,
      sender_image: tempUserData.image,
      sender_name: tempUserData.name,
      userName: tempUserData.name,
      vendor_id: "admin"
    }

    this.setState({activeMessage: tempAdmin, allChats: false});

  }

  selectTypeChange(tempData){
    this.setState({ typeFilterDefault: {value: tempData.value, label: tempData.label}, activeMessage: null});
    this.getFirebaseDB(tempData.value);
  }

  messageItemClick(tempItem, tempIndex){
    this.setState({activeMessage: tempItem, allChats: false});

    if(tempItem.vendor_id != "admin"){
      this.setState({prodSupport: true, prodName: tempItem.productName});
    }
    else{
      this.setState({prodSupport: false});
    }
  }

  viewAllClick(){
    this.setState({allChats: true, prodSupport: false});
  }
  closeChat(){
    this.props.close();
  }

  clearTicketClick(){
    this.setState({allChats: true, prodSupport: false, typeFilterDefault: { value: "chats", label: 'Open Chats' }});
    this.getFirebaseDB(this.state.typeFilter);
  }

  

  render() {
    const options = [
      { value: "chats", label: 'Open Chats' },
      { value: "chatsClosed", label: 'Closed Chats' }
    ]

    return(
      <div className="e-chat-widget-wrap">
        {
          this.state.prodSupport ?
            <div className="e-chat-head-wrap">
              <h6 className="e-p-ellipsis-1">Product Support: {this.state.prodName}</h6>
              <span className="e-chat-close"  onClick={this.closeChat.bind(this)}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
          :
            <div className="e-chat-head-wrap e-general-head">
              <h6>General Support</h6>
              <span className="e-chat-close"  onClick={this.closeChat.bind(this)}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
        }

        {
          this.state.allChats ?
            <div>
              
              {
                !this.state.messageLoading ?
                  <div>
                    {
                      this.state.messages.length ?
                        <div className="e-conversation-wrap">
                          <div className="e-conversation-head d-flex justify-content-between">
                            <span className="e-conversation-select">
                              <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                            </span>
                            <span className="e-new-chat-txt" onClick={this.newChatClick.bind(this)}>New Chat</span>
                          </div>
                          {
                            this.state.messages.map(function(item, index){
                              

                              var tempClass = "e-conversation-item";
                              if(!item.seen){
                                tempClass = tempClass + " e-unseen"
                              }

                              return(
                                <div className={tempClass} key={index} onClick={this.messageItemClick.bind(this, item, index)}>
                                  <div className="media">
                                    
                                    <div className="media-body">
                                      {
                                        item.vendor_id === "admin" ?
                                          <h5 className="mb-2">General Support</h5>
                                        :
                                          <h5 className="mb-2">Product Support: {item.productName}</h5>
                                      }
                                      
                                      <p className="mb-2">{this.dateToFromNowDaily(item.updated) + " " + moment(item.updated).format("HH:mm")}</p>
                                    </div>
                                    <span className="e-unread-dot"></span>
                                  </div>
                                </div>
                              )
                            }.bind(this))
                          }
                        </div>
                      :
                        <div className="e-conversation-wrap"> 
                          <div className="e-conversation-head d-flex justify-content-between">
                            <span className="e-conversation-select">
                              <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                            </span>
                            <span className="e-new-chat-txt" onClick={this.newChatClick.bind(this)}>New Chat</span>
                          </div>

                          <div className="e-conversation-found text-center">

                            <noDataFound data="No conversations found. If you have any queries, start a new chat!"/>
                          </div>
                        </div>
                    }
                  </div>
                :
                  <div className="e-conversation-wrap p-3">
                    <div className="mt-1">
                      <ChatPlaceholder/>
                    </div>
                      <ChatPlaceholder/>
                    <div>
                      <ChatPlaceholder/>
                    </div>
                  </div>
              }
              {/*
                this.state.messages.length ?
                  <div className="e-conversation-wrap">
                    <div className="e-conversation-head d-flex justify-content-between">
                      <span className="e-conversation-select">
                        <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                      </span>
                      <span className="e-new-chat-txt" onClick={this.newChatClick.bind(this)}>New Chat</span>
                    </div>
                    {
                      this.state.messages.map(function(item, index){
                        var nameSplit = item.sender_name.split(" ");
                        var placeholder = "";
                        if(nameSplit.length == 1){
                          placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
                        }
                        else{
                          placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
                        }

                        var tempClass = "e-conversation-item";
                        if(!item.seen){
                          tempClass = tempClass + " e-unseen"
                        }
                        return(
                          <div className={tempClass} key={index} onClick={this.messageItemClick.bind(this, item, index)}>
                            <div className="media">
                              
                              <div className="media-body">
                                {
                                  item.receiver_id === "admin" ?
                                    <h5 className="mb-2">General Support</h5>
                                  :
                                    <h5 className="mb-2">Product Support: {item.productName}</h5>
                                }
                                
                                <p className="mb-2">{this.dateToFromNowDaily(item.updated) + " " + moment(item.updated).format("HH:mm")}</p>
                              </div>
                              <span className="e-unread-dot"></span>
                            </div>
                          </div>
                        )
                      }.bind(this))
                    }
                  </div>
                :
                  <div className="e-conversation-wrap p-3">
                    
                    <div className="mt-1">
                      <ChatPlaceholder/>
                    </div>
                      <ChatPlaceholder/>
                    <div>
                      <ChatPlaceholder/>
                    </div>
                  </div>
              */}
            </div>
          :
            <div>
              {
                this.props.prodSupport ?
                  null
                :
                  <div className="e-chat-back mb-3">
                    <span onClick={this.viewAllClick.bind(this)}>View All Chats</span>
                  </div>
              }

              {
                this.state.prodSupport ?
                  <div className="e-chat-container">
                    <Chatbox item={this.state.activeMessage} prodChat={this.state.prodChat} prodData={this.props.prodData} close={this.clearTicketClick.bind(this)}/>
                  </div>
                :
                  <div className="e-chat-container e-general-chat-body">
                    <Chatbox item={this.state.activeMessage} prodChat={this.state.prodChat} prodData={this.props.prodData} close={this.clearTicketClick.bind(this)}/>
                  </div>
              }
              
            </div>
        }
      </div>
    )
  }
}

export default ChatWidget;
