import React from 'react';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js';


// import components
import Links from '../../components/navigation/Links';
import Banner from '../../components/banners/gettingStartBanner';
import PlayBanner from '../../components/banners/playstoreBanner';
import ProductDetails from '../../container/marketplaceDetails/ProductDetails';
import Review from '../../container/marketplaceDetails/Review';
import FaqPage from '../../container/marketplaceDetails/FaqPage';
import Report from '../../container/marketplaceDetails/PerfomanceReport';
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import SimilarProduct from '../../components/cards/SimilarProduct';
import NothingFound from '../../components/globel/nothingFound';
import CardSlider from '../../components/slider/CardSlider';
import ApiLoader from '../../components/loader/apiLoader';
import AvgReview from '../../components/starRating/avgReview';


// imoprt assets
import IdeasWithSmallcase from '../../assets/images/marketplaceDetails/ideaswithsmallcase.svg';
import fourstar from '../../assets/images/rating/four.svg';
import linkarrow from '../../assets/images/selectArrow/linkarrow.svg';
import sensigroup from '../../assets//images/marketplaceDetails/sensigrop.svg';
import alicebluegrp from '../../assets//images/marketplaceDetails/alicebluegrp.svg';
import optimumgrp from '../../assets//images/marketplaceDetails/optimumgrp.svg';


export default class MarketPlaceDetails extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			tabIndex: 'product',
			isOpen: false,
			data: null,
			id: null,
			product: null,
			idLoad: true,
			select: null,
			dataLoading: true,
			similarProducts: null,
			ref: null
		};
	}

	handleTab = (e) => {
		this.setState({ tabIndex: e });
	}
	componentDidMount(){
		window.scrollTo({top: 0, behavior: 'smooth'});
		GetURLVar().then(function(query){
			this.getData(query.id);
			this.similarProduct(query.id);
			this.setState({id: query.id});
			if(query.tab==2){
				this.openReview()
			}


				if(query.ts_refcode){
					this.setState({ref: query.ts_refcode});
					localStorage.setItem("ts_id", query.id);
        	localStorage.setItem("ts_ref", query.ts_refcode);
				}
				else{
					if(query.id == localStorage.getItem("ts_id")){
						this.setState({ref: localStorage.getItem("ts_ref")});
					}
				}

				 if(query.fbclid){
				 var curl=	window.location.href;

     		 	 var newurl =curl.replace(/\&fbclid.+/, "");
     		window.location.href=newurl
     	}
			}.bind(this));


		}


	getData(tempId){
		this.setState({data:null, dataLoading: true});

		var url = devconfig.v3+"/product/detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}


	similarProduct(tempId){
    var url = devconfig.v3+"/product/similar";
    var data = JSON.stringify({
      "id": parseInt(tempId),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({similarProducts:response.data});
      }
    }.bind(this))
  }

  reload(){
  	this.getData(this.state.id);
  }


	renderTab(){
		if (this.state.tabIndex == 'product'){
			return <ProductDetails details={this.state.data} ref={this.state.ref} reload={this.reload.bind(this)}/>
		}
		if (this.state.tabIndex == 'review'){
			return <Review details={this.state.data}/>
		}
			if (this.state.tabIndex == 'faq'){
			return <FaqPage details={this.state.data}/>
		}
		if(this.state.tabIndex == 'report'){
			return <Report/>
		}
	}
	openReview = () =>{
		this.setState({ tabIndex: 'review' });
    $( ".e-tabbtn-active" ).removeClass('e-tabbtn-active');
    $("#review-tab").addClass('e-tabbtn-active');
	}

	render(){

		if(this.state.dataLoading){
			return(
				<div className="e-min-height-loader pt-5">
					<ApiLoader/>
				</div>
			);
		}
		return(
			<React.Fragment>
			{
				!this.state.data ?
				<Helmet>
          <title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
          <meta name="description" content="Trade store is India's first web & app based trading products marketplace for traders, investors and strategists. Get access to multiple trading tools to help you trade more efficiently." />
        </Helmet>
        :
        <Helmet>
					<title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
					<meta property="og:title" content={this.state.data.product_name}/>
					<meta property="og:description" content={this.state.data.title}/>
					<meta property="og:image" content={this.state.data.illustration}/>

				</Helmet>
			}
			{
				this.state.data?
			<React.Fragment>
				<div className="container-fluid px-0">
					<section className="e-marketDetails-header-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-8">

									<a className="e-marketDetails-link" href="home">Home</a>
									<img src={linkarrow} alt="arrow" className="mx-1"/>
									<a className="e-marketDetails-link" href="marketplace">Marketplace</a>
									<img src={linkarrow} alt="arrow" className="mx-1"/>
									<Links className="e-marketDetails-link-color"  activeClassName="" name={this.state.data.product_name} url="smallcase"/>
								</div>
							</div>
							<div className="row pt-2">
								<div className="col-lg-8 col-md-8 col-sm-12 col-12">
									<h6 className="e-marketDetails-h5">{this.state.data.product_name}</h6>
									<h4 className="e-marketDetails-h4 pb-2 pt-2">{this.state.data.title}</h4>

										{
											this.state.data.review_count ?
												<React.Fragment>
													<AvgReview rating={this.state.data.rating}/>
													<span className="e-mrktdtls-review-span ml-3 mr-2" onClick={this.openReview}>{this.state.data.review_count} Reviews</span>
													<span className="e-brdr-right-span mr-4"></span>
												</React.Fragment>
											:
												null
										}

										{
											this.state.data.filters.map((item, index) => {
							          return(
													<span className="e-box-span mr-2">{item.name}</span>
													);
											})
										}
										<p className="mt-4 e-marketDetails-p">{this.state.data.description}</p>

								</div>
								<div className="col-lg-4 col-md-4 text-right mt-5">
									<img src={this.state.data.illustration} alt="bg image" className="mt-4 e-prod-detail-illustration"/>
								</div>
							</div>
						</div>
					</section>

				</div>
				<div className="container">
					<section>
						<div className="col-lg-12 col-md-12 col-sm-12 col-12 e-tab">

  						{/* <button className={this.state.tabIndex =='product' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="pro-tab" onClick={() => this.handleTab('product')}>Product Details</button> */}
              <button className={this.state.tabIndex =='product' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="pro-tab" onClick={() => this.handleTab('product')}></button>
  						{/* {<button className={this.state.tabIndex =='review' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} onClick={() => this.handleTab('review')}>Reviews </button>} */}
  						{/* <button className={this.state.tabIndex =='faq' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="faq-tab" onClick={() => this.handleTab('faq')}>FAQs & Support</button> */}
              <button className={this.state.tabIndex =='faq' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="faq-tab" onClick={() => this.handleTab('faq')}></button>
  						{/* <button className={this.state.tabIndex =='report' ? "mr-4 e-tablinks e-tabbtn-active": "mr-4 e-tablinks"} id="report-tab" onClick={() => this.handleTab('report')}>Perfomance Report</button> */}
  						</div>
							{this.renderTab()}


					</section>
					<section className="e-marketDetails-banner-sec">
						<Banner/>
					</section>
					{
						this.state.similarProducts ?
							<section className="e-marketDetails-card-sec">
								<hr className="e-mrktplc-rule"/>
								<h5 className="e-prdtdtls-smallcase-h5">Similar Products</h5>
								<CardSlider data={this.state.similarProducts}/>
							</section>
						:
							null
					}

					<section className="e-mrktplc-playbanner-sec">
							<div className="col-lg-12">
								<PlayBanner/>
							</div>
					</section>
				</div>
				</React.Fragment>
				:
				null
				}
			</React.Fragment>
		)
	}


}
