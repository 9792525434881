import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js';


import Input from '../formComponents/input';
// import assets
import closebtn from '../../assets/images/header/close.png';
import PaymentSuccess from '../../assets/images/marketplaceDetails/payment-success.svg';
import PaymentFail from '../../assets/images/marketplaceDetails/payment-fail.svg';
import PrimaryblueButton from '../buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';
import loader from '../../assets/images/loader/loader.gif';

export default class ConfirmAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            paymentUpdated: null,
            total: 0,
            loading: false,
            priceLoading: false,
            agreed: false
        };
    }

    componentDidMount() {
        if (this.props.planId && this.props.productId && localStorage.getItem("tradestore_token")) {

            $('#e-product-purchase').on('shown.bs.modal', (e) => {
                this.getAmount();
                // alert("I want this to appear after the modal has opened!");
                // do something...
            })

        }
        console.log(this.props.pmethod);
        console.log("this.props.ref");
        console.log(this.props.ref);


        GetURLVar().then(function (query) {
            if (query.pstatus && query.pstatus) {
                $('#e-product-purchase').modal('show');
                this.setState({ paymentUpdated: 1 });
                this.paymentStatus(true, query.transaction_id);
            }
            else if (query.pstatus && !query.pstatus) {
                this.setState({ paymentUpdated: 2 });
            }
        }.bind(this));




    }


    getAmount() {
        this.setState({ priceLoading: true });
        var url = devconfig.v3 + "/product/get-amount";
        var data = JSON.stringify({
            "product_id": Number(this.props.productId),
            "plan_id": Number(this.props.planId)
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                this.setState({ total: response.data.total, priceLoading: false });
                console.log("getAmount");
                console.log(response);
            }
            else {
                this.setState({ priceLoading: false });
            }
        }.bind(this))
    }

    success() {
        this.purchase();
    }

    purchase() {
        this.setState({ loading: true });
        var tempref = ""
        if (localStorage.getItem("ts_ref") && this.props.details.id == localStorage.getItem("ts_id")) {
            tempref = localStorage.getItem("ts_ref")
        }

        var url = devconfig.v3 + "/product/purchase";
        var data = JSON.stringify({
            "product_id": Number(this.props.details.id),
            "plan_id": Number(this.props.planData.id),
            "referral_code": tempref,
            "buy_using_wallet": this.props.pmethod
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                this.setState({ loading: false });
                sessionStorage.setItem("purchased_id", response.data.purchased_id);
                if (response.data.payment_status) {
                    this.setState({ paymentUpdated: 1 });
                }
                if (this.props.type == 3 || this.props.type == 4 && !this.props.pmethod) {
                    var tempData = JSON.parse(localStorage.getItem('userData'));
                    var temptotal = this.state.total;
                    var tempPrice = Number(temptotal) * 100;

                    let options = {
                        // live key rzp_live_ybjIzKToKDFtXK 
                        "key": "rzp_live_ybjIzKToKDFtXK",  //rzp_live_ybjIzKToKDFtXK  rzp_test_T56yrs8IVNrcbK
                        "amount": tempPrice, // 2000 paise = INR 20, amount in paisa
                        "currency": "INR",
                        "name": "Aliceblue",
                        "description": "Trade store",
                        "order_id": response.data.razorpay_order_id, // comment this dev
                        "image": "https://evoque-mw-dev.netlify.com/assets/images/AB-Trade-Store-Logo.png",
                        "callback_url": 'https://your-server/callback_url',
                        "handler": function (response) {
                            if (response.razorpay_payment_id) {
                                var tempUrl = window.location.href;
                                var tempUpdatedUrl = `${tempUrl}&pstatus=true&transaction_id=${response.razorpay_payment_id}`;
                                window.location.replace(tempUpdatedUrl);
                            }
                        },
                        "prefill": {
                            "name": tempData.name,
                            "email": tempData.email,
                            "contact": tempData.mobile
                        },
                        "notes": {
                            "app_name": "Trade store",
                            "customer_name": tempData.name,
                            "product_name": this.props.details.product_name,
                            "vendor": this.props.details.vendor_name,
                            "product_id": this.props.details.id
                        },
                        "theme": {
                            "color": "#065CAB"
                        }
                    };

                    let rzp = new window.Razorpay(options);
                    rzp.open();
                }
            }
            else {
                this.setState({ loading: false })
            }
        }.bind(this))
    }

    paymentStatus(tempStatus, tempTranID) {
        var temptotal = Number(this.state.total);
        var url = devconfig.v3 + "/product/complete-payment";
        var data = JSON.stringify({
            "purchased_id": sessionStorage.getItem("purchased_id"),
            "transaction_id": tempTranID,
            "amount": temptotal
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                // var tempRedirect = this.props.details.redirection_url ? this.props.details.redirection_url : null;
                //   if(tempRedirect != null){
                //     window.open(tempRedirect , '_blank') 
                //   }
                // setTimeout(
                //  async function() {
                //     var tempUrl = window.location.href.split('pstatus')[0];
                //     window.location.replace(tempUrl);
                //   }.bind(this), 3000
                // );


            }
        }.bind(this))
    }

    close() {


        var tempRedirect = this.props.details.redirection_url ? this.props.details.redirection_url : null;
        if (tempRedirect != null) {
            window.open(tempRedirect, '_blank')
        }
        var tempUrl = window.location.href.split('pstatus')[0];
        window.location.replace(tempUrl);
    }

    acceptTerms() {
        var url = devconfig.v3 + "/product/terms-acceptance";

        var data = JSON.stringify({
            "product_id": Number(this.props.details.id),
            "plan_id": Number(this.props.planData.id),

        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                $("#e-confirm-purchase").trigger("click")
                $("#e-close").trigger("click")
                this.setState({
                    agreed: false
                })
                if (this.props.home) {
                    localStorage.setItem("agreed", true)
                } else {
                    this.props.redirect()
                }
            } else {

            }
        }.bind(this))
    }



    render() {

        return (

            <div className="modal fade e-product-modal" id="e-confirm-purchase" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ marginTop: "15%" }}>
                <div className="modal-dialog" role="document">

                    <div className="modal-content pb-0">
                        {
                            this.props.home ?
                                <React.Fragment>
                                    <div className="modal-header pb-0">
                                        <h5 className="modal-title" id="exampleModalLabel">Terms and conditions</h5>
                                    </div>
                                    <div className='modal-body'>
                                        <p className='e-confirm-text'>
                                            Please read and agree to our <a href="https://alicebleue-tradestore-terms.netlify.app/" target="_blank">terms and conditions</a> before using our services.
                                        </p>
                                        <div className='e-check-box' >
                                            <input type="checkbox" id="check"
                                                checked={this.state.agreed}
                                                onChange={(e) => {
                                                    this.setState({
                                                        agreed: e.target.checked
                                                    })
                                                }} />
                                            <label for="check"> By clicking this I am accepting <a href="https://alicebleue-tradestore-terms.netlify.app/" target="_blank">terms and condition</a></label>
                                        </div>
                                    </div>
                                    <div className='row e-footer-btns'>

                                        <div className='col-md-12 col-12 '>
                                            <PrimaryblueButton name="Accept"
                                                className="w-100 "
                                                disabled={
                                                    !this.state.agreed
                                                }
                                                handleClick={() => {
                                                    $("#e-confirm-purchase").trigger("click")
                                                    $("#e-close").trigger("click")
                                                    this.setState({
                                                        agreed: false
                                                    })

                                                }} />
                                        </div>
                                    </div>



                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="modal-header pb-0">
                                        <h5 className="modal-title" id="exampleModalLabel">User Agreement</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="e-close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <p className='e-confirm-text'>
                                            I authorize to share my client code, email id, mobile number to the product vendor to get access
                                            of this product.
                                        </p>
                                        <div className='e-check-box' >
                                            <input type="checkbox" id="check"
                                                checked={this.state.agreed}
                                                onChange={(e) => {
                                                    this.setState({
                                                        agreed: e.target.checked
                                                    })
                                                }} />
                                            <label for="check"> By clicking this I am accepting <a href="https://alicebleue-tradestore-terms.netlify.app/" target="_blank" >terms and condition</a></label>
                                        </div>
                                    </div>
                                    <div className='row e-footer-btns'>
                                        <div className='col-md-6 col-12 '>

                                            <PrimaryOutlineButton name="Cancel"
                                                className="w-100 e-cancel-btn"
                                                cancel="modal"
                                                handleClick={() => {
                                                    $("#e-confirm-purchase").trigger("click")
                                                    $("#e-close").trigger("click")
                                                    this.setState({
                                                        agreed: false
                                                    })
                                                }} />
                                        </div>
                                        <div className='col-md-6 col-12 pl-0'>
                                            <PrimaryblueButton name="Continue"
                                                className="w-100 "
                                                disabled={
                                                    !this.state.agreed
                                                }
                                                handleClick={() => {
                                                    this.acceptTerms()
                                                }} />
                                        </div>
                                    </div>



                                </React.Fragment>
                        }


                    </div>
                </div>
            </div>
        );
    }
}

