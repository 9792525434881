import React from 'react';
import $ from 'jquery';

// import service
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';

// import components
import BankDetailsForm from '../../components/popup/BankDetailsForm';
import Button from '../../components/buttons/primaryBlueButton';
import  Nodata from '../../components/globel/nothingFound';
import ApiLoader from '../../components/loader/apiLoader';
// import assets
import Banklogo from '../../assets/images/partnerProfile/banklogo.svg';

export default class PartnerBankDetails  extends React.Component{
	constructor(props){
		super(props);
		this.state={
		}
	}
	componentDidMount(){
		
		this.getBankInfo();
	}


getBankInfo=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.partnerV1+"/auth/get-bankinfo";
  
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

 showSignUp() {
	var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
	$('.e-bnk-dtls-popup').addClass('e-login-fly'); 
}
	render(){
		return(
			<React.Fragment>
	
			
			
		

			{
				this.state.data && this.state.data.account_added && !this.state.dataLoading ?
			
				<div className="col-lg-6 card border-0 p-3 mt-4 e-bankdtls-card" >	
						<div className="row">
							<div className="col-lg-1 mr-2 col-md-1 col-sm-1 col-1">
								<img className=" e-reviewer" src={Banklogo} alt="user image"/>
	  					</div>
		  				<div className="col-lg-10 col-md-10 col-sm-10 col-10">
	  						<h5 className="e-reviewer-name">{this.state.data.bank}</h5>
	  						
	  							<div>
	  								<p className="e-bank-usrname">{this.state.data.branch}</p>
	  							</div>
	  							
	   						 </div>
	   					</div>
						
						<div className="d-flex justify-content-between ml-5 ">
							<div class="ml-1">
	    					<p className="e-bank-no ">{this.state.data.account_no}</p>
	    				</div>
	    				<div className="text-right">
	    				
	  							<button className="e-bankdtls-edit" onClick={this.showSignUp}>Edit</button>
	  					
	  					</div>
						</div>
						
					</div>
				
				:
				this.state.data && !this.state.data.account_added && !this.state.dataLoading ?
				<div className="col-lg-12 text-center p-5">
					<Nodata text="No Bank Details Found!!"/>
					<Button name="Create Bank Details" className="mt-4" handleClick={this.showSignUp}/>
				</div>
				:
				<div className="col-lg-12 text-center p-5">
					<ApiLoader/>
				</div>
			}

			{
				this.state.data?
				<BankDetailsForm data={this.state.data} reload={()=>{this.getBankInfo()}}/>
				:
				null
			}

			</React.Fragment>
		)
	}
}