import React, {components} from 'react';
import { BrowserRouter,Switch ,Route } from 'react-router-dom';
import $ from 'jquery';
// import components
import Links from '../../components/navigation/Links';
import Select from '../../components/formComponents/selectBox';
// import assstes
import HeaderImg from '../../assets/images/header/headerimage.svg';
import menubar from '../../assets/images/header/menubar.png';
import close from '../../assets/images/header/close.png';
import downArrow from '../../assets/images/selectArrow/selectdownarrow.svg';

$(function() {
  $('#ChangeToggle').click(function() {
    $('#navbar-open').toggleClass('hidden');
    $('#navbar-close').toggleClass('hidden');

  });
});
function addOverlay(){
	var overlayDiv = '<div class="e-overlay" id="overlay" onClick=removeOverlay()></div>';
   $("body").append(overlayDiv);
	 $('.e-nav-item').addClass('e-navbar-collapse');
	 $('.navbar').addClass('e-fixed');
}
function removeOverlay(){
	$('#overlay').remove();
	$('.navbar').removeClass('e-fixed');
}
$(document).on('click', '.e-overlay', function () {
	$('#overlay').remove();
	closeMenu();
});
function closeMenu(){
	$("button").attr("aria-expanded","false");
	$('.e-nav-item').removeClass('show');
	$('#navbar-open').toggleClass('hidden');
  $('#navbar-close').toggleClass('hidden');
  $('.navbar').removeClass('e-fixed');
	removeOverlay();
}

export default class userHeader extends React.Component {
	constructor(props){
		super(props);
		this.state={
			loc: "",
		}
	}

	render(){
		var options =[]

		if(localStorage.getItem("userData")){
			var data = JSON.parse(localStorage.getItem("userData"));
			var name = data.name;
			var matches = name.match(/\b(\w)/g);
			if(matches.length > 1 && matches.length == 2){
				var res = matches[0]+matches[1];
			}
			else if(matches.length > 1 && matches.length == 3){
				var res = matches[0]+matches[2];
			}
			else{
				var res = matches[0]
			}

			options= [
				{ label : <div className="row"><div className=" p-3 col-lg-1"><span className="rounded-circle e-user-span">{res}</span></div><div className=" ml-3 col-lg-5"> <span className="e-usr-menu-usrname">{data.name}</span> <p className="e-usr-menu-mail e-usr-menu-id">ID:{data.alice_blue_id}</p><p className="e-usr-menu-mail">{data.email}</p></div></div>} ,
				{value: 'profile', label : <Links className="e-usr-nav-link" name="Profile" url="profile?type=1" type={2}/> },
				{value: 'purchaseHist', label : <Links className="e-usr-nav-link" name="Purchased Products" url="profile?type=1" type={2}/> },
				{value: 'paymentHist', label :<Links className="e-usr-nav-link" name="Payment History" url="profile?type=2" type={2}/> },
				{value: 'fav', label : <Links className="e-usr-nav-link" name="Favourites" url="profile?type=3" type={2}/> },
				{value: 'history', label : <Links className="e-usr-nav-link" name="Wallet History" url="profile?type=4" type={2}/> },
				{value: 'logout', label : <Links className="e-usr-nav-link" name="Logout" url="logout" type={3}/>} ,
			];
		}


		return(


				<div className="text-left pb-2 e-header e-user-head">
					<nav className="navbar navbar-expand-lg navbar-light ">
						<div className="container pr-lg-3">
							<div className="row w-100 no-gutters">
								<div className="col-lg-3 d-flex justify-content-between">
									<Links className="e-head-logo-link"  name= {<img src={HeaderImg} alt="header log" className=" ml-0 mr-5 mt-4 e-header-logo"/>} url="home"/>
									<button id="ChangeToggle" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								  	<div className="mt-4" id="navbar-open">
					          		<span className="e-menu-bar" onClick={addOverlay}><img src={menubar} alt="menu icon" className="e-toggle"/></span>
						        </div>
						        <div id="navbar-close" className="e-close hidden">
			          				<span className="e-close-bar" onClick={removeOverlay}><img src={close} alt="close icon" className="e-toggle"/></span>
			        			</div>
					  			</button>
					  		</div>

					  		<div className="col-lg-9">
					  			<div className=" collapse navbar-collapse e-nav-item" id="navbarSupportedContent" onClick={closeMenu}>
					  			<div className="container-md text-left">
										<ul className="navbar-nav ml-lg-auto mr-lg-auto"  >
										 	<li className="nav-item mt-4">
								        <Links className="mr-lg-5  e-header-link" activeClassName="e-header-link-active" name="Marketplace" url="marketplace"/>
								      </li>
								      {/* {<li className="nav-item mt-4">
								      	<Links className="mr-lg-5 e-header-link" activeClassName="e-header-link-active" name="Resources" url="resources"/>
								      </li>} */}
								      <li className="nav-item mt-4">
								        <Links className="mr-lg-5 e-header-link" name="Contact" activeClassName="e-header-link-active" url="contact"/>
								      </li>
								      {/* <li className="nav-item mt-4">
								        <Links className=" e-header-link" name="Blog" activeClassName="e-header-link-active" url="blogs?type=blog"/>
								      </li> */}
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Profile" activeClassName="e-header-link-active" url="profile?type=1" type={2}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Purchased Products" activeClassName="e-header-link-active" url="profile?type=1" type={2}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Payment History" activeClassName="e-header-link-active" url="profile?type=2" type={2}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Favourites" activeClassName="e-header-link-active" url="profile?type=3" type={2}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Wallet History" activeClassName="e-header-link-active" url="profile?type=4" type={2}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Logout" activeClassName="e-header-link-active" url="logout" type={3}/>
								      </li>
								      {/*<li className="nav-item active mt-4 ml-lg-5">
								       <Links className=" e-header-link" activeClassName="e-header-link-active" name="Partner with us" url="empty"/>
								      </li>*/}
								   </ul>
								   </div>
								   <div className="mt-4 ">
										<Select className="e-usr-select text-left e-nav-dropdown"  options={options}  selectchange={(tempVal) => this.setState({loc: tempVal})} displayOpt={false}  placeholder={<span className="rounded-circle e-user-placeholder">{res}</span>}/>
								  </div>
					  			</div>


					  	</div>
						</div>

					  </div>
					</nav>
				</div>
		)
	}
	}
