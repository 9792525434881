import React from 'react';


// import assets
import rating from '../../assets/images/rating/rating.svg';

export default class AvgReview extends React.Component{
	constructor(props){
	super(props);
		this.state={
			rating: '',
		}
	}

	componentDidMount(){
		var tempdec = this.props.rating.toString().split(".");
		this.setState({ rating: tempdec});
	}

	render(){
		return(
				<span className={this.props.type ? "e-card-star" : null}>
					{[...Array(5)].map((star , i,)=>{

						if(i+1 <= parseInt(this.state.rating[0]) && this.state.rating[0] != 0) {
							return(
								<span className="e-full-star"></span>
							)
						}
						else if(this.state.rating.length > 1 && this.state.rating[0] != 0 && i+1 == (parseInt(this.state.rating[0]) + 1)){
							return(
								<span className="e-half-star"></span>
							)
						}
						else{
							return(
								<span className={this.props.type ? "e-black-star e-white-star" : "e-black-star" }></span>
							)
						}
						 
					} )}  
				</span>
		)
	}
}
