import React from 'react';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';
import GetURLVar from '../../services/getParams/get-url';


// import components
import ApiLoader from '../../components/loader/apiLoader';
import NothingFound from '../../components/globel/nothingFound';


export default class walletHist extends React.Component{
	constructor(props){
		super(props);
		this.state={

		}
	}

	componentDidMount(){
		// this.getBalance();
		this.getHistory();
	}
	// getBalance=()=>{
	// 	this.setState({dataLoading: true})
	// 	var url = devconfig.v3+"/auth/wallet-balance";

 //    APIGet(url).then(function(response){
 //      if(response.status === "ok"){
 //        this.setState({data:response.data, dataLoading: false});

	// 		}
 //      else{
 //        this.setState({ dataLoading: false});
 //      }
 //    }.bind(this))
	// }

	getHistory=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.v3+"/auth/wallet-history";

    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({history:response.data, dataLoading: false});

			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	render(){
		if(this.state.dataLoading){
			return(
				<div className="e-min-height-loader pt-5">
					<ApiLoader/>
				</div>
			);
		}
		return(
		<React.Fragment>
		{
			this.state.history?
			<React.Fragment>
				<div className="container-fluid e-wallethist-head py-5 ">
				<div className="container offset-3">
					<div className="col-lg-8">
						<h4>Wallet Balance</h4>
					</div>
					<div className="col-lg-8">
						<h1>₹ {this.state.history.wallet.balance}</h1>
					</div>
				</div>
			</div>
			<div className="container offset-3">
				
				<div className="col-lg-8 mt-4 e-hist-table-wrapp">
					<span className="mb-4 e-wallethist-table-head">All Transactions</span>
					{
						this.state.history && !this.state.history.history.length?
						<NothingFound text="No transaction history found"/>
						:
						<table className="table mt-4 e-wallethist-table">
						<tbody>
						{
							this.state.history && this.state.history.history.map((item , key)=>{
								return(
									<tr key={key}>
								<td className="e-date">{item.date}</td>
								<td className="e-info">{item.info}</td>
								<td className="e-amount">{item.amount}</td>
							</tr>
								)
							})
						}
							
						</tbody>
					</table>
					}
					
				</div>
			</div>
			</React.Fragment>
			:
			null
		}
			

		</React.Fragment>
		)
	}
}