import React from 'react';
import Radio from '@material-ui/core/Radio';
import $ from 'jquery';
// import component
import InputSlider from '../../components/rangeSlider/InputSlider';
import Input from '../../components/formComponents/input';

// import assets
import fourstar from '../../assets/images/rating/four.svg';
import threestar from '../../assets/images/rating/three.svg';
import twostar from '../../assets/images/rating/two.svg';
import onestar from '../../assets/images/rating/one.svg';
import closebtn from '../../assets/images/header/close.png';

export default class MarketPlaceFilter extends React.Component{
	constructor(props){
		super(props);
		this.state={
			pricetype: 0,
			rating: 0,
			type: 0,
			segment: 0,
			keyword: ""
		}
	}

	handleSegment=(e) =>{
		this.setState({segment: e.target.value});
		this.props.change(1, e.target.value);
	}
	handleRating=(e) =>{
		this.setState({rating: e.target.value});
		this.props.change(2, e.target.value);
	}
	handleType=(e) =>{
		this.setState({type: e.target.value});
		this.props.change(3, e.target.value);
	}
	handlePricetype=(e) =>{
		this.setState({pricetype: e.target.value});
		this.props.change(4, e.target.value);
	}

	hideFilter =() =>{
		$('.e-market-fil').removeClass('e-filter-fly');
		$('#e-log-overlay').remove();
	}

	priceRangeChange(tempRange){
		this.props.change(5, tempRange);
	}

	investRangeChange(tempRange){
		this.props.change(6, tempRange);
	}

	searchKeyDown(e){
		if(e.keyCode == 13 && this.state.keyword.length){
    	this.props.search(this.state.keyword);
    }
	}

	clearSearch(){
		this.setState({keyword: ""});
		this.props.change(1, this.state.segment);
	}

	render(){
	return(
		<div className="text-left e-market-fil pr-5 border-right">
			<div className="col-lg-2 e-mrktplc-close">
				<button className="e-login-closebtn mb-5"><img src={closebtn} alt="close button" className="e-toggle" onClick={this.hideFilter}/></button>
			</div>
			<div className="col-lg-12 position-relative p-0 mb-4 e-marketplace-search-wrap">
				<input className="e-marketplace-search" type="text" onKeyDown={this.searchKeyDown.bind(this)} value={this.state.keyword} onChange={(e) => this.setState({keyword: e.target.value})} placeholder="Search for products" />
				{
	        this.state.keyword.length ?
	        	<span className="e-close-btn" onClick={this.clearSearch.bind(this)}></span>
	        :
	          null
	      }
      </div>

      <div className="col-lg-12 p-0">
				<div className="e-marketplace-radio-heading mb-3">
					<h6 className="e-radio-title">Segment</h6>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={0} checked={this.state.segment == 0} onChange={this.handleSegment}/> All
					</label>
				</div>
				{
					this.props.filters ?
						<React.Fragment>
							{
				        this.props.filters.segments.map((item, index) => {
				          return(
				            <div key={index}>
											<label className="e-radio-label">
												<Radio value={item.id} checked={this.state.segment == item.id} onChange={this.handleSegment}/> {item.name}
											</label>
										</div>
				          );
				        })
				      }
						</React.Fragment>
					:
						null
				}
			</div>

			{/* <div className="col-lg-12 p-0 mt-2">
				<div className="e-marketplace-radio-heading mb-3">
					<h6 className=" e-radio-title">Rating by</h6>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={0} checked={this.state.rating == 0 } onChange={this.handleRating}/> All
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={4} checked={this.state.rating == 4} onChange={this.handleRating}/>
						<img src={fourstar} alt="rating"/><span className="e-rating-above pl-1">& above</span>
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={3} checked={this.state.rating == 3} onChange={this.handleRating}/>
						<img src={threestar} alt="rating"/><span className="e-rating-above pl-1">& above</span>
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={2} checked={this.state.rating == 2} onChange={this.handleRating}/>
						<img src={twostar} alt="rating"/><span className="e-rating-above pl-1">& above</span>
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={1} checked={this.state.rating == 1} onChange={this.handleRating}/>
						<img src={onestar} alt="rating"/><span className="e-rating-above pl-1">& above</span>
					</label>
				</div>
			</div> */}

			<div className="col-lg-12 p-0 mt-2">
				<div className="e-marketplace-radio-heading mb-3">
					<h6 className=" e-radio-title">Application type</h6>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={0} checked={this.state.type == 0} onChange={this.handleType}/>All
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={1} checked={this.state.type == 1} onChange={this.handleType}/>Web based application
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={2} checked={this.state.type == 2} onChange={this.handleType}/>Mobile application
					</label>
				</div>
			</div>

			<div className="col-lg-12 p-0 mt-2">
				<div className="e-marketplace-radio-heading mb-3">
					<h6 className=" e-radio-title">Price type</h6>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={0} checked={this.state.pricetype == 0} onChange={this.handlePricetype}/>All
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={1} checked={this.state.pricetype == 1} onChange={this.handlePricetype}/>Trial Access
					</label>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value={2} checked={this.state.pricetype == 2} onChange={this.handlePricetype}/>Free
					</label>
				</div>
			</div>

			<div className="col-lg-12 p-0 mt-2">
				<div className="e-marketplace-radio-heading mb-2">
					<h6 className=" e-radio-title">Trade Store Price</h6>
				</div>
				{
					this.props.filters ?
						<div className="mb-3">
							<InputSlider change={this.priceRangeChange.bind(this)} minRange={this.props.filters.min_price_limit} maxRange={this.props.filters.max_price_limit} maxValue={this.props.filters.max_price_limit} minValue={this.props.filters.min_price_limit}/>
						</div>
					:
						null
				}
			</div>

			<div className="col-lg-12 position-relative p-0 mt-2">
				<div className="e-marketplace-radio-heading mb-2">
					<h6 className=" e-radio-title">Mini Investment</h6>
				</div>
				{
					this.props.filters ?
						<div className="mb-3">
							<InputSlider change={this.investRangeChange.bind(this)} minRange={this.props.filters.min_investment_limit} maxRange={this.props.filters.max_investment_limit} maxValue={this.props.filters.max_investment_limit} minValue={this.props.filters.min_investment_limit}/>

						</div>
					:
						null
				}
			</div>

		</div>
	)
}
}
