import React from 'react';
import { NavLink } from 'react-router-dom';

function logout() {
	localStorage.clear();
	window.location.href = '/';
}

function Links(props){
	if(props.type == 3){
		return(
			<a className={props.className} onClick={logout} activeClassName={props.activeClassName}>{props.name} </a>
		);
	}
	else if(props.type == 2){
		return(
			<a className={props.className} href={props.url} activeClassName={props.activeClassName}>{props.name} </a>
		);
	}
	else{
		return(
			<NavLink className={props.className} to={props.url} activeClassName={props.activeClassName}>{props.name} </NavLink>
		)
	}
	
}

export default Links;