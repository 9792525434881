import React, { Component } from 'react';

/*Assets and Components*/
import noResultImg from '../../assets/images/placeholder/empty.png';

class noDataFound extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="col-lg-12 pt-0 text-center mt-5 e-no-result">
        <img className="e-no-result" src={noResultImg} alt="No data found"/>
        <h6>{}</h6>
        <div className="e-noData-txt">{}</div>
      </div>
    );
  }
}

export default noDataFound;