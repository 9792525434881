import React from 'react';
import $ from 'jquery';
import ReactPlayer from 'react-player/lazy';
import { useHistory } from 'react-router-dom';
// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

// import component
import VideoModal from '../../components/modal/VideoModal';
import Links from '../../components/navigation/Links';
// import assets
import videothumb2 from '../../assets/images/resource/videothumb2.svg';
import videothumb from '../../assets/images/resource/videothumb1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import closebtn from '../../assets/images/header/close.png';
import videocam from '../../assets/images/resource/videocam.svg';


function VidoeListing (props){

let history = useHistory();
function RouteChange() {
	let path = `resourceVideo?id=`+ props.data.id +`&product=` +props.data.title.replace(/\s+/g, '-');
	history.push(path);
}

	return(
		<div class="col-lg-6  mt-3">
		{
			props.data ?
      <div class="card border-0 e-videolist-card e-video-card" onClick={RouteChange}>
        <div class="card-image e-video-card-headersec">
       		<img className="e-videolist-thumb"   id="video-cover" src={props.data.thumbnail} alt="Video title"/>
       		<img src={videocam} alt="videocam" className="e-videocard-icon"/>
          <span className="e-vidoe-duration"> {props.data.duration}</span>
      	</div>
      	<div class="card-content p-3">
      		<h5 class="e-video-card-head">{props.data.title}</h5>
      		{
            props.data.products && props.data.products.map((item, index) =>{
              return(
                <span className="e-vidoe-list-span">{item.name}</span> 
              );
            })
          }
      	</div>
      </div>
            :
            null
          }
    </div>
  )	

}

export default VidoeListing;

