import React from 'react';
import $ from 'jquery';

// import assets
import joinAlicebluebg from '../../assets/images/joinAliceblueBanner/joinalicebluebg.svg';
import bgimagecell from '../../assets/images/joinAliceblueBanner/bgimagecell.svg';
import bgimagepercentage from '../../assets/images/joinAliceblueBanner/bgimagepercentage.svg';
import bgimagesignal from '../../assets/images/joinAliceblueBanner/bgimagesignal.svg';
import bgimagewired from '../../assets/images/joinAliceblueBanner/bgimagewired.svg';

export default class joinAliceblue extends React.Component{

	accountOpen(){
		$(".e-signup-btn").trigger("click");
	}
	render(){
	return(
		<div className="row e-joinaliceblue-banner pt-5">
			<div className="col-lg-12 text-center">
				<h2 className="mt-5 mb-3">Join the Aliceblue family today!</h2>
				{/* <div className="col-lg-5 col-md-8 e-joinaliceblue-content px-5">
					<p className="mt-3">Take advantage of the various Algo-based products to build your own customized trading strategies.</p>
				</div> */}
				<button className="e-joinaliceblue-btn" onClick={this.accountOpen.bind(this)}>Open new account</button>
				<img src={bgimagesignal} alt="bg image" className="img-fluid e-bgimagesignal"/>
				<img src={bgimagewired} alt="bg image" className="img-fluid e-bgimagewired"/>
				<img src={bgimagepercentage} alt="bg image" className="img-fluid e-bgimagepercentage"/>
				<img src={bgimagecell} alt="bg image" className="img-fluid e-bgimagecell"/>
			</div>
		</div>
	)
}
}