import React, {components} from 'react';
import { BrowserRouter,Switch ,Route } from 'react-router-dom';
import $ from 'jquery'; 
// import components
import Links from '../../components/navigation/Links';
import Select from '../../components/formComponents/selectBox';
// import assstes
import HeaderImg from '../../assets/images/header/headerimage.svg';
import menubar from '../../assets/images/header/menubar.png';
import close from '../../assets/images/header/close.png';
import downArrow from '../../assets/images/selectArrow/selectdownarrow.svg';

$(function() {
  $('#ChangeToggle').click(function() {
    $('#pnavbar-open').toggleClass('hidden');
    $('#pnavbar-close').toggleClass('hidden');  
 	
  });
});
function addOverlay(){
	var overlayDiv = '<div class="e-overlay" id="overlay" onClick=removeOverlay()></div>';
   $("body").append(overlayDiv);
	 $('.e-nav-item').addClass('e-navbar-collapse');   
	 $('.navbar').addClass('e-fixed');  
}
function removeOverlay(){
	$('#overlay').remove();
	$('.navbar').removeClass('e-fixed');
}
$(document).on('click', '.e-overlay', function () {
	$('#overlay').remove();
	closeMenu();
});
function closeMenu(){
	$("button").attr("aria-expanded","false");
	$('.e-nav-item').removeClass('show');
	$('#navbar-open').toggleClass('hidden');
  $('#navbar-close').toggleClass('hidden');
  $('.navbar').removeClass('e-fixed');  
	removeOverlay();
}

export default class partnerHeader extends React.Component {
	constructor(props){
		super(props);
		this.state={
			loc: "",
			btnClick: false,

		}
	}

	render(){
		var options =[]

		if(localStorage.getItem("partner_id")){
			var name = localStorage.getItem("partner_name");
			var mail = localStorage.getItem("partner_mail");
			var matches = name.match(/\b(\w)/g); 
			if(matches.length > 1 && matches.length == 2){
				var res = matches[0]+matches[1];
			}
			else if(matches.length > 1 && matches.length == 3){
				var res = matches[0]+matches[2];
			}
			else{
				var res = matches[0]
			}
			
			options= [
				{ label : <div className="row"><div className=" p-3 col-lg-1"><span className="rounded-circle e-partner-span">{res}</span></div><div className=" ml-3 col-lg-5"> <span className="e-usr-menu-usrname">{name}</span> <p className="e-usr-menu-mail e-usr-menu-id">{mail}</p><p className="e-usr-menu-mail ">ID:{localStorage.getItem("alice_blue_id")}</p></div></div>} ,
				{value: 'refferalHist', label : <Links className="e-usr-nav-link" name="Refferal History" url="partnerProfile?type=1" onClick={()=>{window.location.href="/partnerProfile?type=1"}}/> },
				{value: 'paymentHist', label :<Links className="e-usr-nav-link" name="Bank Details" url="partnerProfile?type=2" onClick={()=>{window.location.href="/partnerProfile?type=2"}}/> },
				{value: 'logout', label : <Links className="e-usr-nav-link" name="Logout" url="logout" type={3}/>} ,
			];
		}
		
		
		return(
				 

				<div className="text-left  pb-4  e-header e-user-head">
					<nav className="navbar navbar-expand-lg navbar-light ">
						<div className="container pr-0">
							<div className="row w-100">
								<div className="col-lg-2 d-flex justify-content-between">
									<Links className="e-head-logo-link"  name= {<img src={HeaderImg} alt="header log" className=" ml-0 mr-5 mt-4 e-header-logo"/>} url="home"/>
									<button id="ChangeToggle" className="navbar-toggler" onClick={()=>{this.setState({btnClick: !this.state.btnClick})}} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

								  	<div className="mt-4" id="pnavbar-open">
					          		<span className="e-menu-bar" onClick={addOverlay}><img src={menubar} alt="menu icon" className="e-toggle"/></span>
						        </div>
						        <div id="pnavbar-close" className="e-close hidden">
			          				<span className="e-close-bar" onClick={removeOverlay}><img src={close} alt="close icon" className="e-toggle"/></span>
			        			</div>
					  			</button>
					  		</div>

					  		<div className="col-lg-10">
					  			<div className=" collapse navbar-collapse e-nav-item" id="navbarSupportedContent" onClick={closeMenu}>
					  			<div className="container-md text-left">
										<ul className="navbar-nav ml-lg-auto mr-lg-auto"  >
										 	<li className="nav-item mt-4">
								        <Links className="mr-lg-5  e-header-link" activeClassName="e-header-link-active" name="Marketplace" url="marketplace"/>
								      </li>
								      <li className="nav-item mt-4">
								      	<Links className="mr-lg-5 e-header-link" activeClassName="e-header-link-active" name="Resources" url="resources"/>
								      </li>
								      <li className="nav-item mt-4">
								        <Links className="e-header-link" name="Contact" activeClassName="e-header-link-active" url="contact"/>     
								      </li>
								       <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Refferal History" url="partnerProfile?type=1" onClick={()=>{window.location.href="/partnerProfile?type=1"}}/>
								      </li>
								       <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Bank Details" url="partnerProfile?type=2" onClick={()=>{window.location.href="/partnerProfile?type=2"}}/>
								      </li>
								      <li className="nav-item mt-4 e-usr-dropdown">
								      	<Links className="e-usr-nav-link" name="Logout" url="logout" type={3}/>
								      </li>
								   </ul>
								   </div>
								   <div className="mt-4 ">
										<Select className="e-usr-select text-left e-nav-dropdown" options={options} selectchange={(tempVal) => this.setState({loc: tempVal})} displayOpt={false}  placeholder={<span className="rounded-circle e-partner-placeholder">{res}</span>}/>
								  </div> 
					  			</div>
					  			

					  	</div>
						</div>

					  </div>
					</nav>
				</div>
		)
	}
	}
