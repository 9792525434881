import React from 'react';
import $ from 'jquery';
import Radio from '@material-ui/core/Radio';
import { useHistory } from 'react-router-dom';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
// import components
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
// import assets
import webinarimg from '../../assets/images/resource/webinarimg.svg';
import webinarimg1 from '../../assets/images/resource/webinarimg1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import schedule from '../../assets/images/resource/webinarschedule.svg'
import live from '../../assets/images/resource/live.svg';
import videocam from '../../assets/images/resource/videocam.svg';


function Webinar (props){

	// function readMore(){
	// 	$('.e-webinar-p').toggleClass('e-readmore-p');
	// 	var replace = $('.e-webinar-p').hasClass("e-readmore-p") ? "Read Less" : "Read More";
	// 	$('.e-read-more').text(replace);
	// }
	let history = useHistory();
	function RouteChange() {
		let path = `webinarDetails?id=`+ props.data.id +`&product=` +props.data.title.replace(/\s+/g, '-');
   	history.push(path);
	}
		return(

				<div class="col-lg-6  mt-3">
				{
					props.data ?
			    <div class="card border-0 e-videolist-card e-webinar e-video-card" onClick={RouteChange}>
			      <div class="card-image e-webinar-card-headersec">
		     		 	<img className="e-videolist-thumb"   id="video-cover" src={props.data.image} alt="Video title"/>
	     		 		{
	     		 			props.data.type == 1 ?
	     		 			<React.Fragment>
	     		 				{
	     		 					props.data.live ?
	     		 						<React.Fragment>
	     		 							<img src={live} alt="live sticker" className="e-webinar-live-stic" />
	     		 							<img src={videocam} alt="video cam" className="e-webinar-videocam"/>
	     		 						</React.Fragment>
	     		 					:
	     		 						<React.Fragment>
	     		 							<span className="e-webinar-schedule">{props.data.scheduled_on}</span>
	     		 							<img src={schedule} alt="schedule image" className="e-webinar-stick"/>
	     		 						</React.Fragment>
	     		 				}
	     		 			</React.Fragment>
	     		 			:
	     		 			<React.Fragment>
	     		 				
	     		 				<img src={videocam} alt="video cam" className="e-webinar-videocam e-webinar-replay-icon"/>
	     		 			</React.Fragment>
	     		 		}
			     	</div>
			      <div class="card-content p-3">
			        <h5 class="e-video-card-head">{props.data.title}</h5> 
			        <p className="e-webinar-p">{props.data.description}</p>
			        {
						props.data.products && props.data.products.map((item, index) =>{
							return(
								<span className="e-vidoe-list-span">{item.name}</span> 
							);
						})
					}
			        <div className="row pt-4 pl-3 d-flex justify-content-between">
			      		<span className="e-webinar-span mt-4">{props.data.duration}</span> 
			     	  	<PrimaryBlueButton name="View More Details"/>   
			        </div>                
			      </div>
			    </div>
			    :
			    null
			  }
			  </div>
		)
	
}

export default Webinar;