import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Helmet } from 'react-helmet';
// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js'; 

// import Components
import Links from '../../components/navigation/Links';
import JoinAliceblue from '../../components/banners/joinAliceblue';
import VideoModal from '../../components/modal/VideoModal';

import ApiLoader from '../../components/loader/apiLoader';

// import assets
import linkarrow from '../../assets/images/selectArrow/linkarrow.svg';
import VideoCover from '../../assets/images/resource/videocover.svg';
import facebook from '../../assets/images/footerIcons/facebook.svg';
import twitter from '../../assets/images/footerIcons/twitter.svg';
import linkedin from '../../assets/images/footerIcons/linkdin.svg';
import whatsapp from '../../assets/images/footerIcons/whatapp.svg';
import copy from '../../assets/images/footerIcons/icon.svg';
import schedule from '../../assets/images/resource/webinarschedule.svg';
import closebtn from '../../assets/images/header/close.png';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import calendar from '../../assets/images/resource/webinarschedule.svg';
import redirect from '../../assets/images/resource/webinar-redirect.svg';

export default class WebinarDetails extends React.Component{
	constructor(props){
		super(props);
		this.state={
			play: true,
			isModalOpen: false,
			data: null,
			linkcopy: false,
			dataLoading: true
		}
	}
	 pauseVideo=()=>{
	this.setState({play: false});
}
componentDidMount(){
		window.scrollTo({top: 0, behavior: 'smooth'});
		var tempUrl = (window.location.href).replace(/\s+/g, '-');
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id, url: tempUrl});
		}.bind(this));
}

getData(tempId){
		var url = devconfig.v3+"/resources/webinar-detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	render(){
		if(this.state.dataLoading){
			return(
				<div className="e-min-height-loader pt-5">
					<ApiLoader/>
				</div>
			);
		}
		return(
			<React.Fragment>
			{
				!this.state.data ?
				<Helmet>
          <title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
          <meta name="description" content="Trade store is India's first web & app based trading products marketplace for traders, investors and strategists. Get access to multiple trading tools to help you trade more efficiently." />
        </Helmet>
        :
        <Helmet>
					<title>Trade Store | Alice Blue - Lowest brokerage Online Trading account in India</title>
					<meta property="og:title" content={this.state.data.title}/>
					<meta property="og:image" content={this.state.data.image}/>

				</Helmet>
			}
			{
				this.state.data ?
			<React.Fragment>
				<div className="container e-webinar-dtls">
					<div className="row">
						<div className="col-lg-12"> 
						<a className="e-marketDetails-link"  href="home">Home</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<a className="e-marketDetails-link"  href="resources?type=videos">Resources</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<a className="e-marketDetails-link"  href="resources?type=webinar">Webinars</a>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
							<span className="e-resource-dtls-head">{this.state.data.title}</span>
						</div>
						{/*<div className="text-center offset-lg-1 mt-4 col-lg-10 col-md-12 col-sm-12 col-12 p-0 e-webinar-wrap">
							<img src={this.state.data.image} alt="videocover" className="e-resource-video-cover"/>
							{
								this.state.data.type === 2?
								<button className="e-vidoelist-play-btn" data-toggle="modal" data-target="#videodeatils" onClick={() => this.setState({isModalOpen: true})}>
	            		<img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
	      	 			</button>
	      	 			:
	      	 			<button className="e-vidoelist-play-btn" data-toggle="modal" data-target="#" >
	            		<img className="e-vidoelist-play" src={schedule} alt="playbtn"/>
	      	 			</button>
							}
      	 			<span className="e-webinar-time">{this.state.data.scheduled_on}</span>
      	 			<span className="e-overlay"></span>
						</div>*/}

						<div className="text-center offset-lg-1 mt-4 col-lg-10 col-md-12 col-sm-12 col-12 e-webinar-wrap">
							{
								this.state.videoPlay ?	
									<ReactPlayer className= "e-resource-video"  id="player" url={this.state.data.link} controls={true} width="100%" />
								:
									<div className="text-center e-video-detail-play position-relative">
										{
											this.state.data.type ==  1 ?
												<React.Fragment>
													<img src={this.state.data.image} alt="videocover" className= "e-resource-video-cover" />
													<button className="e-vidoelist-play-btn e-webinar-calander">
							    					<img className="e-vidoelist-play" src={calendar} alt="playbtn"/>
								 					</button>
								 					<div className="e-webinar-time">{this.state.data.scheduled_on}</div>
								 					{
								 						this.state.data.know_more_url ?
								 						<a href={this.state.data.know_more_url} target="_blank" className="e-prdtld-ext e-extrnl-link">Know More</a>
								 						:
								 						null
								 					}
								 					
								 					
												</React.Fragment>
											:
												<React.Fragment>
													<img src={this.state.data.image} alt="videocover" className= "e-resource-video-cover" />
													{
														this.state.data.source_type == 2 ?
															<a href={this.state.data.link} target="_blank" className="e-vidoelist-play-btn">
																<img className="e-vidoelist-play" src={redirect} alt="playbtn"/>
															</a>
														:
															<button className="e-vidoelist-play-btn" onClick={()=> this.setState({videoPlay: true})}>
									    					<img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
										 					</button>
													}
													
								 					<span className="e-resouce-video-durtn">{this.state.data.duration}</span>
								 					
												</React.Fragment>
										}
										
						 			</div>
							}
						</div>


					</div>
					
					<section className="e-video-details-main-sec">
						<div className="card border-0  e-videodetails-card">

							<h2 className="e-playstorebanner-head">{this.state.data.title}</h2>
							<div class="row">
									<div className="col-lg-12">
										{
											this.state.data.products && this.state.data.products.map((item, index) =>{
												return(
													<span className="e-vidoe-list-span">{item.name}</span> 
												);
											})
										}
									</div>
									{/*<span className="mt-3 e-vidoe-list-span mx-3">Smallcase</span> <span className="mt-3 e-vidoe-list-span">Aliceblue</span>   */}
								</div>
							<p className="mt-3">{this.state.data.description}</p>
							<div className="row mt-4">
								<div className="col-lg-6 col-md-6 col-sm-6 col-12">
									<h6 className="e-video-desc">Shared via</h6>
									<p className="">
										<a className="mr-3 e-ftricon-link" href={"https://www.facebook.com/sharer/sharer.php?u="+this.state.url} target="blank"><img src={facebook} alt="icon" className="e-footer-icon"/></a>  
										<a className="mr-3 e-ftricon-link" href={"https://twitter.com/intent/tweet?url="+this.state.url+"&text="+this.state.data.title} target="blank"><img src={twitter} alt="icon" className="e-footer-icon"/></a>
										<a className="mr-3 e-ftricon-link" href={"http://www.linkedin.com/shareArticle?mini=true&url="+this.state.url+"&title="+this.state.data.title} target="blank"><img src={linkedin} alt="icon" className="e-footer-icon"/></a>
										
										<a className="mr-3 e-ftricon-link" onClick={() => {
												this.setState({ linkcopy: true});
												navigator.clipboard.writeText(this.state.url);
												setTimeout(
											    function() {
											      this.setState({ linkcopy: false });
											    }
											    .bind(this),
											    1500
												);
											}
										}><img src={copy} alt="icon" className="e-footer-icon"/></a> 

										{
											this.state.linkcopy ?
												<span className="e-link-copied">Link copied</span>
											:
												null
										}
									</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6 col-12 text-lg-right text-md-right text-sm-right">
									<h6 className="e-video-desc">Added on</h6>
									<p className="e-videodate">{this.state.data.added_on}</p>
								</div>
							</div>
						</div>
					</section>
					<VideoModal modalId="videodeatils">
	          <button className="e-videomodal-close"data-dismiss="modal" aria-hidden="true" onClick={this.pauseVideo}><img className="e-toggle" src={closebtn} alt="button"/></button>
      			<ReactPlayer id="player" url={this.state.data.link} playing={this.state.play && this.state.isModalOpen }  width="465px" />
	      	</VideoModal>
				</div>
				<div className="container-fluid">
					<JoinAliceblue/>
				</div>
			</React.Fragment>
			:
			null
		}
			</React.Fragment>
		)
	}

}
