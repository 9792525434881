import React from 'react';
import $ from 'jquery';
/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';

/*Import custom components and assets*/
import Input from '../../components/formComponents/input';
import Select from '../../components/formComponents/selectBox';
import TextArea from '../../components/formComponents/TextArea';
import Button from '../../components/buttons/primaryBlueButton';
import ApiLoader from '../../components/loader/apiLoader';
import Loader from '../../assets/images/loader/loader.gif';

import Success from '../../assets/images/contact/auth-succes.svg';
import loader from '../../assets/images/loader/loader.gif';

export default class Form extends React.Component {
	constructor(props){
	super(props);
		this.state= {
			name: "",
			email: "",
			mobile: "",
			nameError: "",
			mailError: "",
			numberError: "",
			acError: "",
			loc: "",
			bname: this.props.data? this.props.data.bank : null,
			acnumber: this.props.data? this.props.data.account_no : null,
			reacnumber: "",
			branch: this.props.data? this.props.data.branch : null,
			ifsc: this.props.data ? this.props.data.ifsc_code : null,
			stateOptions: [],
			isAvailable: false,
			type: this.props.type,
			error: "",
			apiLoading: false,
			success: false,
			reload: false
		};
	}

	componentWillMount(){
    this.getState();

  }

	getState(){
		var tempCountry = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"]

    var tempArray = [];
    tempCountry.map(function(key, index){
      var temp = { value: index, label: key };
      tempArray.push(temp);
    });

    var s_a = new Array();
    s_a[0] = "";
    s_a[1] = "Hyderabad";
    s_a[2] = "Itanagar";
    s_a[3] = "Dispur";
    s_a[4] = "Patna";
    s_a[5] = "Raipur";
    s_a[6] = "Panaji";
    s_a[7] = "Ahmedabad";
    s_a[8] = "Chandigarh";
    s_a[9] = "Shimla";
    s_a[10] = "Srinagar";
    s_a[11] = "Ranchi";
    s_a[12] = "Bengaluru";
    s_a[13] = "Thiruvananthapuram";
    s_a[14] = "Bhopal";
    s_a[15] = "Mumbai|Pune|Nagpur|Nashik";
    s_a[16] = "Imphal";
    s_a[17] = "Shillong";
    s_a[18] = "Aizawl";
    s_a[19] = "Kohima";
    s_a[20] = "Bhubaneswar";
    s_a[21] = "Chandigarh";
    s_a[22] = "Jaipur";
    s_a[23] = "Gangtok";
    s_a[24] = "Chennai|Madurai|Erode|Karur|Coimbatore";
    s_a[25] = "Hyderabad";
    s_a[26] = "Agartala";
    s_a[27] = "Lucknow";
    s_a[28] = "Dehradun";
    s_a[29] = "Kolkata";

    this.setState({stateOptions: tempArray, citiesOptions: s_a,});
  }

	async validate(){
		let nameError= "";
		let mailError= "";
		let numberError= "";
		let acError= "";

		if(this.state.name.length == 0 && this.state.type !=4){
			nameError = 'Invalid name, please check and retry.';
		}

		await EmailValidation(this.state.email).then(function(response){
      if(!response){
        mailError = 'Invalid email address, please check and retry.';
      }
    }.bind(this))

		await PhoneValidation(this.state.mobile).then(function(response){
      if(!response){
        numberError = 'Invalid phone number, please check and retry.';
      }
    }.bind(this))
		if(this.state.acnumber != this.state.reacnumber){
			acError = 'Account number must be same in two fields';
		}
		if(acError){
			this.setState({acError});
			return false;
		}
		if(mailError || nameError || numberError){
			this.setState({mailError , nameError , numberError});
			return false;
		}
		else{
			return true;
		}

	};

	clearForm(){
	  this.setState({
	    name: '',
			email: '',
			moblie: '',
			nameError: '',
			mailError: '',
			numberError: '',
	  });
	}

	submit = event => {
		event.preventDefault();
		this.setState({apiLoading: true});

		const isValid = this.validate();
		if (isValid){
			var url = devconfig.v1base+"/general/digilink-api";
			var data = JSON.stringify({
				"Name": this.state.name,
				"EmailID": this.state.email,
				"MobileNo": this.state.mobile,
				"State": this.state.loc.label,
				"City": this.state.citiesOptions[this.state.loc.value],
				"Source": "TSTORE"
	    })
	    APIPost(url, data).then(function(response){
	      if(response.status === "ok"){
	       	this.setState({success: "Thank you we will contact you shortly", apiLoading: false, reload: true});
	       	setTimeout(
				    function() {
			        this.setState({ reload: false });
				    }
				    .bind(this), 200
					);

					setTimeout(
				    function() {
			        this.setState({ success: "" });
				    }
				    .bind(this), 3000
					);

	        if(this.state.type == 5){
	        	var tempUrl = sessionStorage.getItem("ts_url");
	        	localStorage.setItem("tradestore_lead_success", true);
	        	window.location.href = tempUrl;
	        }
	      }
	      else{
	      	this.setState({error: response.message, apiLoading: false});
	      }
	    }.bind(this))
		}
	}

	editPtrBank=()=>{
		// console.log(this.state.ifsc);
    var url = devconfig.partnerV1+"/auth/update-bank-info";
    var data = JSON.stringify({
      "account_no": parseInt(this.state.acnumber),
      "ifsc_code" : this.state.ifsc,
      "branch" : this.state.branch,
      "bank" : this.state.bname
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        setTimeout( function (argument) {
        	this.hideLogin()
        	this.props.refresh();
        }.bind(this), 2000)
      }
    }.bind(this))

  }
  createBank=()=>{
  	alert('here');
  	}


   hideLogin() {

	$('.e-bnk-dtls-popup').removeClass('e-login-fly');
	$('#e-log-overlay').remove();
}


	render(){
		const renderTextarea = ()=>{
  		if(this.state.type == 2){
    		return <TextArea className="mt-3 e-form-textarea" placeholder="Message" valueChange={(tempVal) => this.setState({message: tempVal})}/>
  		}
  	}

  	const renderButtton = ()=>{

  		switch(this.state.type) {
		    case "1":{
		      return	<button className="mt-4 e-form-btn" disabled={!this.state.name || !this.state.email || !this.state.mobile || !this.state.loc }>Open new account {this.state.apiLoading? <img src={Loader}/>: null }</button>
		    };
		    case "2":{
		      return	<button className="mt-4 e-form-btn" disabled={!this.state.name || !this.state.email || !this.state.mobile || !this.state.loc}>Send Message</button>
		    };
	      case "3":{
		      return	<button className="mt-4 e-signup-btn" disabled={!this.state.name || !this.state.email || !this.state.mobile || !this.state.loc}>Create Account</button>
	      };
	      case "4":{
	      	return(
	      			this.props.data.account_added?
	      		 <button className="mt-4 e-form-btn" disabled={!this.state.bname || !this.state.acnumber || !this.state.reacnumber || !this.state.ifsc} onClick={this.editPtrBank}>Update Bank Details</button>
	      		 :
	      		  <button className="mt-4 e-form-btn" disabled={!this.state.bname || !this.state.acnumber || !this.state.reacnumber || !this.state.ifsc} onClick={this.editPtrBank}>Save Bank Details</button>

	      	);
	      }
	      case "5":{
	      	return	<button className="mt-4 e-form-btn" disabled={!this.state.name || !this.state.email || !this.state.mobile || !this.state.loc }>Submit {this.state.apiLoading? <img src={Loader}/>: null }</button>
	      }
		  }
  	}

  	const renderHeading = ()=>{
  		switch(this.state.type) {
		    case "1":{
		      return	<h5 className="e-form-head">Pick your product & trade now.</h5>
		    };
		    case "2":{
		      return	<h1 className="e-contactform-head">Get in touch with us.</h1>
		    };
	      case "3":{
		      return	<h5 className="e-signup-head">Sign up</h5>
	      };
	      case "4":{
	      	return <h5 className="e-signup-head">Enter your Bank Details</h5>
	      };
	      case "5":{
	      	return <h5 className="e-signup-head">Enter your Details for View More</h5>
	      };
		  }
  	}

  	const renderP = ()=>{
  		switch(this.state.type) {
		    case "1":{
		      return	<p className="e-form-paragraph pt-2 mt-1">Open an account with Aliceblue to get access to Trade Store products & exciting offers.</p>
		    };
		    case "2":{
		      return	<p className="e-contactform-paragraph">If you have any further questions or queries please do not hesitate to get in touch.</p>
		    };
	      case "3":{
		      return	<p className="e-singup-p mt-3 mb-4">Create a new account with Aliceblue.</p>
	      };
	      case "4":{
	      	return  <p className="e-singup-p mt-3 mb-4">Ensure you are entering the correct bank information.</p>
	      }
	      case "5":{
	      	return  <p className="e-singup-p mt-3 mb-4">Fill up the below details for view details.</p>
	      }
		  }
  	}

  	const renderRule = ()=>{
  		if(this.state.type == 1){
    		return <hr className="e-form-rule"/>
  		}
  	}

  	const renderDeclaimer = ()=>{
  		switch(this.state.type) {
		    case "2":{
		      return	<span className="mt-2 e-contact-nb">Note : I authorize Aliceblue to call or send text through the Short Messaging Service (SMS) in relation to any of their products.</span>
		    };
	      case "3":{
		      return	<span className="mt-2 e-signup-nb">By creating an account you agree to our <b><a href="privacy-policy">Terms & Conditions</a></b>.</span>
	      };
		  }
  	}

  	if(this.state.reload){
  		return(
  			<div></div>
  		);
  	}


  	if(this.state.success && this.state.type != 1 && this.state.type != 2){
  		return(
  			<div className="e-success-wrap">
  				<h5 className="e-form-head">Thank You!</h5>
  				<p className="e-singup-p mt-3 mb-4">We have received your data. Our marketing team will contact you and help to open a trading account with us</p>
  				<div className="text-center">
  					<img src={Success}/>
  				</div>
  			</div>
  		);
  	}
  	else{
  		return(
				<form className={this.props.className} type={this.props.type} onSubmit={this.submit}>
					{renderRule()}
					{renderHeading()}
					{renderP()}
					{
						this.state.type == 4  ?
						<React.Fragment>
							<input type="text" className="e-text" placeholder="Bank Name"  value={this.state.bname} onChange={(e) => this.setState({bname: e.target.value})}/>

							<input type="number" className="e-text mt-3" placeholder="Account Number"  value={this.state.acnumber} onChange={(e) => this.setState({acnumber: e.target.value})}/>

							<input type="number" className={this.state.acError ? "mt-3 e-text e-error-input" :"mt-3 e-text"} value={this.state.reacnumber}   placeholder="Re-enter Account Number"  onChange={(e) => this.setState({reacnumber: e.target.value})}/>
							<div className="text-left" style={{color: "red"}}>{this.state.acError}</div>

							<input type="text" className="e-text mt-3" placeholder="Branch"  value={this.state.branch} onChange={(e) => this.setState({branch: e.target.value})}/>
							<div className="text-left" style={{color: "red"}}>{this.state.nameError}</div>

							<input type="text" className="e-text mt-3" placeholder="IFSC Code"  value={this.state.ifsc} onChange={(e) => this.setState({ifsc: e.target.value})}/>
							<div className="text-left" style={{color: "red"}}>{this.state.nameError}</div>
						</React.Fragment>
						:
						<React.Fragment>
							<Input className="e-text" placeholder="Name" valueChange={(tempVal) => this.setState({name: tempVal})}/>
							<div className="text-left" style={{color: "red"}}>{this.state.nameError}</div>

							<Input className= {this.state.mailError ? "mt-3 e-text e-error-input" :"mt-3 e-text"} placeholder="Email Id" valueChange={(tempVal) => this.setState({email: tempVal, mailError: ""})}/>
							<div className="text-left e-error-msg-s" style={{color: "red"}}>{this.state.mailError}</div>

							<Input className={this.state.numberError ? "mt-3 e-text e-error-input" : "mt-3 e-text"} placeholder="Mobile No." type="number" valueChange={(tempVal) => this.setState({mobile: tempVal, numberError: ""})}/>
							<div className="text-left e-error-msg-s" style={{color: "red"}}>{this.state.numberError}</div>

							<div className="mt-3">
								<Select className="e-form-select text-left" options={this.state.stateOptions} placeholder="State" selectchange={(tempVal) => this.setState({loc: tempVal})}/>
							</div>
					</React.Fragment>
					}
					{renderTextarea()}

					{
						this.state.success.length ?
							<div className="mt-3 e-success-msg">
								{ this.state.success }
							</div>
						:
							null
					}

					{
						this.state.error.length ?
							<div className="mt-3 e-error-msg">
								{ this.state.error }
							</div>
						:
							null
					}


					{renderButtton()}
					 {/*{this.state.apiLoading ? <span className="ml-3"><img className="e-page-loader" src={loader} alt="Loader"/></span> : null}*/}
					{renderDeclaimer()}

				</form>
			)
  	}
	}
}
