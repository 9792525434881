import React from 'react';

import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';

export default class RefferalHistory extends React.Component{
	constructor(props){
		super(props);
		this.state={

		}
	}

	componentDidMount(){
		this.getRefferals()
	}

	getRefferals=()=>{
		this.setState({dataLoading: true})
		var url = devconfig.partnerV1+"/referral/history";
  
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	render(){
		return(
			
				<table className="table table-striped table-borderless  mt-4">
					<thead className="table-active">
						<tr>
							<th >Product Name</th>
				      <th >Joined Leads</th>
				      <th >Commission</th>
				      <th >Received</th>
				      <th >Last Received Date</th>
				      <th >Pending</th>
						</tr>
					</thead>
					<tbody>
					{
						this.state.data ?
						this.state.data.map((item, key)=>{
							return(
							<tr key={key}>
				      	<td >{item.product_name}</td>
				      	<td>{item.joined_leads}</td>
				     <td> &#8377;{item.earned_commission}</td>
				      <td>&#8377;{item.received}</td>
				      <td>{item.last_paid}</td>
				      <td>&#8377;{item.pending_amount}</td>
				    </tr>
							);
						})
						:
						null
					}
						
				    
					</tbody>
				</table>
			
		)
	}
}