import React from 'react';
import Radio from '@material-ui/core/Radio';
import $ from 'jquery';

// import assets
import closebtn from '../../assets/images/header/close.png';


export default class ResourceFilter extends React.Component{
	constructor(props){
		super(props);
		this.state={
			product: 0,
		}
	}
	handleProduct=(e) =>{
		this.setState({product: e.target.value});
		this.props.change( e.target.value);
	}
	hideFilter =() =>{
		$('.e-market-fil').removeClass('e-filter-fly'); 
		$('#e-log-overlay').remove();
	}
	render(){
		return(
			<div className="text-left e-market-fil pr-5 border-right">
				<div className="col-lg-2 e-mrktplc-close">
					<button className="e-login-closebtn mb-5"><img src={closebtn} alt="close button" className="e-toggle" onClick={this.hideFilter}/></button>
				</div>
				<div className="e-marketplace-radio-heading mb-3">
					<h6 className="e-radio-title">Products</h6>
				</div>
				<div>
					<label className="e-radio-label">
						<Radio value= {0}  checked={this.state.product== 0 } onChange={this.handleProduct}/> All
					</label>
				</div>
				{
					this.props.filters ?
						<React.Fragment>
							{
								this.props.tabIndex === 'videos' ?
									
								<React.Fragment>
								{
				        this.props.filters.video_products.map((item, index) => {
				          return(
				            <div key={index}>
											<label className="e-radio-label">
												<Radio value={item.id} checked={this.state.product == item.id} onChange={this.handleProduct}/> {item.name}
											</label>
										</div>
				          );
				        })
				      }
				     </React.Fragment>
				      :
				      this.props.tabIndex === 'webinar' ?
				      	<React.Fragment>

								{
				        this.props.filters.webinar_products.map((item, index) => {
				          return(
				            <div key={index}>
											<label className="e-radio-label">
												<Radio value={item.id} checked={this.state.product == item.id} onChange={this.handleProduct}/> {item.name}
											</label>
										</div>
				          );
				        })

				      }
				      </React.Fragment>
				      :
				      this.props.tabIndex === 'blog' ?
				      	<React.Fragment>

								{
				        this.props.filters.blog_products.map((item, index) => {
				          return(
				            <div key={index}>
											<label className="e-radio-label">
												<Radio value={item.id} checked={this.state.product == item.id} onChange={this.handleProduct}/> {item.name}
											</label>
										</div>
				          );
				        })

				      }
				      </React.Fragment>
				      :
				      this.props.tabIndex === 'report' ?
				      	<React.Fragment>

								{
				        this.props.filters.report_products.map((item, index) => {
				          return(
				            <div key={index}>
											<label className="e-radio-label">
												<Radio value={item.id} checked={this.state.product == item.id} onChange={this.handleProduct}/> {item.name}
											</label>
										</div>
				          );
				        })

				      }
				      </React.Fragment>
				      :
				      null 
				    }
						</React.Fragment>
					:
						null
				}
			</div>
		)
	}
}
