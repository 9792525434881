import React from 'react';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
// import assets
import downloadpdf from '../../assets/images/marketplaceDetails/pdfdownload.svg';

function ResourceReport(props){

		return(
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
					<div className="card e-report-card border-0">
					<div className="row  d-flex justify-content-between  p-4">
						<h5 className="text-left mb-0 e-report-head">{props.data.file_name}</h5>
						<a href={props.data.file_url} target="_blank" className=" e-pdf-download">PDF <img src={downloadpdf} alt="image"/></a>
					</div>
					</div>
				</div>
			)
	}

	export default ResourceReport;